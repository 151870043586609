import { BaseCircleCustomization } from '../base-circle-customization.class';
import { GenericFieldSetup } from '@shared/models/generic-field-setup';
import { Type } from '@angular/core';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';
import { FormArray, FormGroup } from '@angular/forms';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { CircleValidators } from '../circle-validators.class';
import { AngularUtils } from '@shared/angular-utils.class';
import { CircleBackendService } from '@services/circle-backend.service';
import { BtaFirstMyclimateComponent } from './bta-first-myclimate.component';
import { BtaFirstFixAmountComponent } from './bta-first-fixamount.component';
import { BtaFirstPercentageComponent } from './bta-first-percentage.component';
import { BtaFirstMyclimateContext } from './bta-first-myclimate-context.class';

export class BtaFirstCustomization extends BaseCircleCustomization {

  private btaFirstMyclimateComponent?: Type<BaseGenericValueWithSetupComponent>;
  private btaFirstFixAmountComponent?: Type<BaseGenericValueWithSetupComponent>;
  private btaFirstPercentageComponent?: Type<BaseGenericValueWithSetupComponent>;

  constructor(private circleBackendService: CircleBackendService) {
    super();
    BtaFirstMyclimateContext.init();
    this.btaFirstMyclimateComponent = BtaFirstMyclimateComponent;
    this.btaFirstFixAmountComponent = BtaFirstFixAmountComponent;
    this.btaFirstPercentageComponent = BtaFirstPercentageComponent;
  }

  override customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    if (!traveller?.uuid) {
      AngularUtils.runOnNextTick(() => {
        formGroup.get('data.generalData.nationality')?.setValue('');
      });
    }

    super.addValidators(formGroup.get('data.generalData.businessPhone'), CircleValidators.internationalPhone);
    super.addValidators(formGroup.get('data.generalData.privatePhone'), CircleValidators.internationalPhone);
    super.addValidators(formGroup.get('data.generalData.mobilePhone'), CircleValidators.internationalPhone);


    const company$ = formGroup.get('data.company')?.valueChanges
      .subscribe(val => {
        if (val && typeof val === 'object' && val.uuid) {
          this.circleBackendService.getBtaFirstCompanyAddress(val.uuid)
            .subscribe(adr => {
              this.getGenericFormControl(formGroup.get('data.genericFieldValues') as FormArray, 'ZIP')?.setValue(adr.zip);
              this.getGenericFormControl(formGroup.get('data.genericFieldValues') as FormArray, 'CITY')?.setValue(adr.city);
              this.getGenericFormControl(formGroup.get('data.genericFieldValues') as FormArray, 'COUNTRY')?.setValue(adr.country);
              this.getGenericFormControl(formGroup.get('data.genericFieldValues') as FormArray, 'STREET2')?.setValue(adr.street);
            });
        }
      });
      super.registerSubscription(company$);
  }

  override getGenericValueWithSetupComponent(genericFieldSetup?: GenericFieldSetup): Type<BaseGenericValueWithSetupComponent> | undefined {
    if (genericFieldSetup?.name === 'MyClimate') {
      return this.btaFirstMyclimateComponent;
    }
    if (genericFieldSetup?.name === 'FixAmount') {
      return this.btaFirstFixAmountComponent;
    }
    if (genericFieldSetup?.name === 'Percentage') {
      return this.btaFirstPercentageComponent;
    }
    return super.getGenericValueWithSetupComponent(genericFieldSetup);
  }


}
