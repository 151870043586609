import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';
import { BtaFirstMyclimateContext } from './bta-first-myclimate-context.class';
import { Subscription } from 'rxjs';

@Component({
  template: `
  <div class="uf-btafirst-myclimate-fixedamount" [formGroup]="valueWithSetupFormGroup">
    <input type="text" class="form-control" [attr.id]="controlId" formControlName="value"
      [placeholder]="valueWithSetup.label" (change)="markFieldDirty()"/>
  </div>
`,
styles: [`
  :host {
    width: 100%;
  }
  div.uf-btafirst-myclimate {
    width: 100%;
  }
`]
})
export class BtaFirstFixAmountComponent extends BaseGenericValueWithSetupComponent implements OnInit, OnDestroy {

  private context: BtaFirstMyclimateContext;
  private valueOption$: Subscription;
  private valueChange$: Subscription | undefined;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.context = BtaFirstMyclimateContext.instance;
    this.valueOption$ = this.context.fixedAmountEnabled()
      .subscribe(newState => this.valueOptionChanged(newState));
     this.valueChange$ = this.valueWithSetupFormGroup.get('value')?.valueChanges
       .subscribe(val => this.context.valueOptionChanged('fixed', val));
 }

  ngOnDestroy(): void {
    this.valueOption$?.unsubscribe();
    this.valueChange$?.unsubscribe();
  }

  markFieldDirty() {
    this.valueWithSetupFormGroup.get('field')?.markAsDirty();
  }

  private valueOptionChanged(newState: boolean): void {
    if (newState && this.valueWithSetupFormGroup.disabled) {
      this.valueWithSetupFormGroup.enable();
    } else if (!newState && this.valueWithSetupFormGroup.enabled) {
      this.valueWithSetupFormGroup.get('value')?.setValue(undefined, {emitEvent: false});
      this.markFieldDirty();
      this.valueWithSetupFormGroup.disable();
    }
  }

}
