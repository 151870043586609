import {Injectable} from '@angular/core';

import {LogService} from './log-service.interface';

@Injectable()
export class LogServiceProduction extends LogService {
  constructor() {
    super();
  }

  debug(): void {
    // no-op
  }

  info(): void {
    // no-op
  }

  warn(): void {
    // no-op
  }
}
