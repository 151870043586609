import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrzOrganisationalUnit, CircleBackendService } from '@services/circle-backend.service';

class OeConfigItem {
  dn: string;
  includeSubsidiaries: boolean;
  excludedSubsidiaries?: string[];
}

@Component({
  selector: 'app-brz-ldap-dialog',
  templateUrl: './brz-ldap-dialog.component.html',
  styleUrls: ['./brz-ldap-dialog.component.scss']
})
export class BrzLdapDialogComponent implements OnInit {

  @Input() initialItems: OeConfigItem[];
  form: FormGroup;
  oeFormArray: FormArray;

  get ldapLookupControl(): AbstractControl | null {
    return this.form.get('newEntry.dn');
  }

  private oeCache: { [key: string]: string } = {};

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private circleBackendService: CircleBackendService) { }

  ngOnInit(): void {
    this.oeFormArray = this.fb.array([]);
    this.form = this.fb.group({
      newEntry: this.createOeFormGroup(),
      oeConfigItems: this.oeFormArray
    });
    if (this.initialItems) {
      this.loadOeNames(...this.initialItems);
      this.initialItems
        .map(oe => this.createOeFormGroup(oe))
        .forEach(oeGrp => this.oeFormArray.push(oeGrp));
    }
  }

  deleteOeItem(idx: number): void {
    this.oeFormArray.removeAt(idx);
  }

  deleteExcludeDn(oeGroup: AbstractControl, excludeIndex: number): void {
    this.getExcludeDnsFormArray(oeGroup).removeAt(excludeIndex);
  }

  addOeItem(): void {
    const newEntryIncludeSubsidiariesControl = this.form.get('newEntry.includeSubsidiaries');
    const typeAheadValue = this.ldapLookupControl?.value;
    if (typeAheadValue) {
      this.addDisplayValueFromTypeahead(typeAheadValue);
      const oeConfigItem = {
        dn : typeAheadValue?.id,
        includeSubsidiaries : newEntryIncludeSubsidiariesControl?.value ?? false,
        excludedSubsidiaries: [],
        hasExcludedDns: false
      };
      this.oeFormArray.push(this.createOeFormGroup(oeConfigItem));
    }
    this.ldapLookupControl?.setValue(null);
    newEntryIncludeSubsidiariesControl?.setValue(false);
  }

  addExcludedDn(oeGroup: AbstractControl): void {
    const typeAheadControl = oeGroup.get('excludedDnLookup');
    if (typeAheadControl?.value) {
      const typeAheadValue = typeAheadControl.value;
      this.addDisplayValueFromTypeahead(typeAheadValue);
      this.getExcludeDnsFormArray(oeGroup).push(new FormControl(typeAheadValue.id));
    }
    typeAheadControl?.setValue(null);
  }

  submitNewFieldValue() {
    this.activeModal.close(this.oeFormArray.value);
  }

  isIncludingSubsidiaries(oeGrp: AbstractControl): boolean {
    return oeGrp.get('includeSubsidiaries')?.value ?? false;
  }

  getDisplayValue(control: AbstractControl, path?: string): string {
    const dn = path ? control.get(path)?.value : control.value;
    const orgName = dn ? (this.oeCache[dn] || 'Laden ...') : 'Laden ...';

    return `${orgName} (${dn})`;
  }

  getExcludeDnsFormArray(oeGrp: AbstractControl): FormArray {
    return oeGrp.get('excludedSubsidiaries') as FormArray;
  }

  isExcludingDns(oeGrp: AbstractControl): boolean {
    return oeGrp.get('hasExcludedDns')?.value ?? false;
  }

  private loadOeNames(...oeConfigItems: OeConfigItem[]): void {
    const distinguishedNames: string[] = [];
    oeConfigItems.forEach(oe => {
      distinguishedNames.push(oe.dn);
      if (oe.includeSubsidiaries && oe.excludedSubsidiaries) {
        Array.prototype.push.apply(distinguishedNames, oe.excludedSubsidiaries);
      }
    });
    this.circleBackendService.getBrzOrganisationEntity(distinguishedNames)
      .subscribe(resp => this.loadDisplayValues(resp));
  }

  private loadDisplayValues(oes: BrzOrganisationalUnit[]): void {
    const additionalValues: { [key: string]: string } = {};
    oes.forEach(oe => additionalValues[oe.id] = oe.name);
    this.oeCache = {...this.oeCache, ...additionalValues};
  }

  private addDisplayValueFromTypeahead(typeaheadValue: any) {
    const additionalValues = { [typeaheadValue.id]: typeaheadValue.name };
    this.oeCache = {...this.oeCache, ...additionalValues};
  }

  private createOeFormGroup(oeValue?: OeConfigItem): FormGroup {
    const excludedDnsFormArray = this.fb.array([]);
    oeValue?.excludedSubsidiaries?.forEach(s => excludedDnsFormArray.push(new FormControl(s)));
    return this.fb.group({
      dn: [oeValue?.dn ?? ''],
      includeSubsidiaries: [oeValue?.includeSubsidiaries ?? false],
      hasExcludedDns: [excludedDnsFormArray.length > 0],
      excludedSubsidiaries: excludedDnsFormArray,
      excludedDnLookup: ['']
    });
  }
}
