import { BaseCircleCustomization, ProfileCollectionNames } from '../base-circle-customization.class';
import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum } from '@models/types.enum';
import { StandardfieldDefinition } from '@models/standardfield-definition';
import { ObjectUtils } from '@shared/object-utils.class';
import { TravellerDetailResponse } from '@models/traveller-detail-response';

export class HolcarCustomization extends BaseCircleCustomization {

  private static primaryPassportValidation: ValidatorFn = formGroup => {
    const isPrimary = formGroup.get('primary')?.value === true;
    ['country', 'number', 'issueCountry', 'issueDate', 'expiration'].forEach(fld => {
      const control = formGroup.get(fld);
      if (control) {
        const populated = !!control.value;
        control.setErrors(isPrimary && !populated ? { required: true } : null);
      }
    });

    return null;
  };

  override getTransientStandardfieldDefinition(controlPath: string[], profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if (profileType === ProfileTypeConstant.CORPORATE && ObjectUtils.equalsAny(controlName, 'externalNr', 'street', 'zipCode', 'place', 'countryCode')) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.CORPORATE,
        affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
        fieldDisplayType: FieldDisplayTypeConstant.OPTIONAL,
        label: ''
      };
    }

    return;
  }

  override customizeTravellerFormGroup(_traveller: TravellerDetailResponse | undefined, formGroup: FormGroup) {
    (formGroup.get('data.papers.passports') as FormArray)?.controls?.forEach(passport => this.addPrimaryPassportValidation(passport as FormGroup));
  }

  override addedCollectionItem(_profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: FormGroup) {
    if (collectionName === 'passports') {
      this.addPrimaryPassportValidation(formGroup);
    }
  }

  override removedCollectionItem(_profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: AbstractControl<any, any>) {
    if (collectionName === 'passports') {
      formGroup.removeValidators(HolcarCustomization.primaryPassportValidation);
    }
  }

  private addPrimaryPassportValidation(passport: FormGroup) {
    this.addValidators(passport, HolcarCustomization.primaryPassportValidation);
  }

}
