export interface FacesSpaEnvironment {
  production: boolean,
  contextPerLanguage: boolean,
  typeaheadDebounce: number,
  updateDebounce: number,
  navbarHeight: number,
  genericSetupFixedHeight: number,
  ngbAlertMargin: number,
  serverUiBaseUrl?: string, // Server-Side UI base URL *if different from apiBaseUrl*
  apiBaseUrl: string,
  spaClientId: string,
  spaClientSecret: string,
}

export const environmentDefaults = {
  production: false,
  contextPerLanguage: true,
  typeaheadDebounce: 200,
  updateDebounce: 100,
  navbarHeight: 96,
  genericSetupFixedHeight: 300,
  ngbAlertMargin: 16
}
