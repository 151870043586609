import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrzLdapButtonComponent } from "./brz/brz-ldap-button.component";
import { BrzLdapDialogComponent } from './brz/brz-ldap-dialog/brz-ldap-dialog.component';
import { CommonModule } from "@angular/common";
import { BrzLdapnameTypeaheadComponent } from './brz/brz-ldapname-typeahead/brz-ldapname-typeahead.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AbbKontierungComponent } from './abb/abb-kontierung.component';
import { BtaFirstMyclimateComponent } from './btafirst/bta-first-myclimate.component';
import { BtaFirstFixAmountComponent } from './btafirst/bta-first-fixamount.component';
import { BtaFirstPercentageComponent } from './btafirst/bta-first-percentage.component';
import { CtmNoramUserGroupConfigComponent } from './ctm_noram/ctm-noram-user-group-config.component';
import { CtmNoramUserConfigDialogComponent } from './ctm_noram/ctm-noram-user-config-dialog/ctm-noram-user-config-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  declarations: [
    BrzLdapButtonComponent,
    BrzLdapDialogComponent,
    BrzLdapnameTypeaheadComponent,
    AbbKontierungComponent,
    BtaFirstMyclimateComponent,
    BtaFirstFixAmountComponent,
    BtaFirstPercentageComponent,
    CtmNoramUserGroupConfigComponent,
    CtmNoramUserConfigDialogComponent
  ]
})
export class CirclesModule {
}
