import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { TravellerProfileSearchResponse } from '@shared/models/traveller-profile-search-response';
import { TravellerCreateUpdateRequest } from '@shared/models/traveller-create-update-request';
import { ProfilePublishState } from '@shared/models/profile-publish-state';
import { TravellerAdditionalData } from '@shared/models/traveller-additional-data';
import { ContactTypeEnum } from '@models/types.enum';
import { FormUtils } from '@shared/form-utils.class';
import { Visa } from '@models/visa';
import { TravellerContactSection } from '@shared/models/traveller-contact-section';
import { ReferencedTravellerContact } from '@shared/models/referenced-traveller-contact';
import { Store } from '@ngrx/store';
import { FacesState } from '../../store-root/faces-state';
import { User } from '@models/user.class';
import { selectPrincipal } from '../../store-root/selectors';
import { AccessService } from '@services/access.service';
import { SabrePnrDirectResponse, TransferHostCommandsRequest, TransferHostCommandsResponse } from '@shared/models/copy-to-pnr';
import { ReferencedContact } from '@models/referenced-contact';

export enum TravellerSection {
  AGENCY_INFO = 'AGENCY_INFO',
  COMPANY_INFO = 'COMPANY_INFO',
  COMPANY_CONTACT_DATA = 'COMPANY_CONTACT_DATA',
  GENERAL_DATA = 'GENERAL_DATA',
  GENERIC_FIELD_VALUES = 'GENERIC_FIELD_VALUES',
  PREFERENCES = 'PREFERENCES',
  MEMBERSHIPS = 'MEMBERSHIPS',
  PASSPORT = 'PASSPORT',
  VISA = 'VISA',
  ID_CARD = 'ID_CARD',
  EMERGENCY_CONTACT = 'EMERGENCY_CONTACT',
  ARRANGER_CONTACTS = 'ARRANGER_CONTACTS',
  APPROVER_CONTACTS = 'APPROVER_CONTACTS',
  ROLES = 'ROLES',
  CREDIT_CARDS = 'CREDIT_CARDS',
  RAIL_INFORMATION = 'RAIL_INFORMATION',
  RESIDENT_INFORMATION = 'RESIDENT_INFORMATION',
  TRAVEL_GROUP_ASSIGNMENTS = 'TRAVEL_GROUP_ASSIGNMENTS',
  COMMENT = 'COMMENT',
  HISTORY = 'HISTORY',
  PUBLISH_STATES = 'PUBLISH_STATES',
  UNUSED_TICKETS = 'UNUSED_TICKETS'
}

@Injectable({
  providedIn: 'root'
})
export class TravellerService {

  private currentUser?: User;

  constructor(store: Store<FacesState>, private http: HttpClient) {
    store.select(selectPrincipal).subscribe(user => this.currentUser = user);
  }

  search(params: {
    q: string;
    companyUuid?: string;
    type?: ContactTypeEnum;
    page?: number;
    pageSize?: number;
    detailedSections?: TravellerSection[]
  }): Observable<TravellerProfileSearchResponse> {
    let httpParams = new HttpParams()
      .set('q', params.q)
      .set('pageSize', params.pageSize || 25)
      .set('includeDetails', true)
    if (params.companyUuid) {
      httpParams = httpParams.set('c', params.companyUuid);
    }
    if (params.type) {
      httpParams = httpParams.set('type', params.type);
    }
    if (params.page !== undefined) {
      httpParams = httpParams.set('page', params.page);
    }
    if (params.detailedSections !== undefined) {
      httpParams = httpParams.set('detailSections', params.detailedSections.join(','))
    }
    return this.http.get<TravellerProfileSearchResponse>(`${environment.apiBaseUrl}/api/v1/profiles/travellers`, { params: httpParams });
  }

  read(uuid: string, ...sections: TravellerSection[]): Observable<TravellerDetailResponse> {
    let httpParams = new HttpParams()
    if (sections && sections.length > 0) {
      httpParams = httpParams.set('sections', sections.join(','));
    }
    return this.http.get<TravellerDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/traveller/${uuid}`, { params: httpParams });
  }

  readContext(uuid: string): Observable<TravellerAdditionalData> {
    return this.http.get<TravellerAdditionalData>(`${environment.apiBaseUrl}/api/v1/traveller/${uuid}/context`);
  }

  getNewTraveller(): Observable<TravellerDetailResponse> {
    return this.http.get<TravellerDetailResponse>(`${environment.apiBaseUrl}/api/v1/traveller/new`);
  }

  save(uuid: string | undefined, request: TravellerCreateUpdateRequest): Observable<TravellerDetailResponse> {
    this.prepareRequestForApi(request);
    const httpParams = new HttpParams().set('returnAddSections', Object.values(TravellerSection).join(','));
    if (uuid) {
      return this.http.patch<TravellerDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/traveller/${uuid}`, request, { params: httpParams });
    }

    let httpHeaders = new HttpHeaders();
    if (AccessService.hasAnyRole(this.currentUser, 'ROLE_SELFREG_USER') && this.currentUser?.refreshToken) {
      httpHeaders = httpHeaders.set('X-Linked-Token', this.currentUser.refreshToken);
    }

    return this.http.post<TravellerDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/traveller`, request, {
      params: httpParams,
      headers: httpHeaders
    });
  }

  delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/profiles/traveller/${uuid}`);
  }

  getPublishStates(uuid: string): Observable<ProfilePublishState[]> {
    const httpParams = new HttpParams().set('sections', TravellerSection.PUBLISH_STATES);
    return this.http.get<TravellerDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/traveller/${uuid}`, { params: httpParams })
      .pipe(map(profile => profile.data?.publishStates || []));
  }

  deletePublishState(travellerUuid: string, interfaceUuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/traveller/${travellerUuid}/publishstate/${interfaceUuid}.json`);
  }

  renameLocator(travellerUuid: string, systemInterfaceUuid: string, targetLocator: any) {
    const httpParams = new HttpParams()
      .set('interfaceUuid', systemInterfaceUuid)
      .set('locator', targetLocator);
    return this.http.post<ProfilePublishState>(`${environment.apiBaseUrl}/api/v1/traveller/${travellerUuid}/publishstate/rename`, httpParams);
  }

  getSabrePnrElements(travellerUuid: string): Observable<SabrePnrDirectResponse> {
    return this.http.get<SabrePnrDirectResponse>(`${environment.apiBaseUrl}/api/v1/sabrered/${travellerUuid}/elements.json`);
  }

  sendSabreCommands(token: string, commands: string[]): Observable<TransferHostCommandsResponse> {
    const request: TransferHostCommandsRequest = {
      token: token,
      commands: commands
    };
    return this.http.post<TransferHostCommandsResponse>(`${environment.apiBaseUrl}/api/v1/sabrered/sendtopnr.json`, request);
  }

  getArrangerContacts(travellerUuid: string): Observable<ReferencedContact[]> {
    return this.http.get<ReferencedContact[]>(`${environment.apiBaseUrl}/api/v1/traveller/${travellerUuid}/arranger-contacts`);
  }

  getApproverContacts(travellerUuid: string): Observable<ReferencedContact[]> {
    return this.http.get<ReferencedContact[]>(`${environment.apiBaseUrl}/api/v1/traveller/${travellerUuid}/approver-contacts`);
  }

  private prepareRequestForApi(request: TravellerCreateUpdateRequest): void {
    FormUtils.prepareCreditCardsFormForUpdate(request.data?.creditCardInfo);
    this.markEmptyContactsAsRemoved(request.data?.contacts);
    this.handleVisaTypeOther(request.data?.papers?.visas);
  }

  private markEmptyContactsAsRemoved(contactSection?: TravellerContactSection): void {
    if (contactSection) {
      contactSection.approvers = this.handleEmptyContacts(contactSection.approvers);
      contactSection.arrangers = this.handleEmptyContacts(contactSection.arrangers);
    }
  }

  private handleEmptyContacts(contacts?: ReferencedTravellerContact[]): ReferencedTravellerContact[] | undefined {
    return contacts?.map(c => {
      if (c.uuid && c.contactUuid !== undefined && !c.contactUuid) {
        c._operation = 'remove';
        delete c.contactUuid;
      }
      return c;
    })
      // an empty, new entry is invalid, so just remove it
      .filter(c => c.contactUuid || c.uuid);
  }

  private handleVisaTypeOther(visas?: Visa[]): void {
    if (visas) {
      visas.forEach(visa => {
        const updatingEntryTypeToOther = visa.entryType !== undefined && visa.entryType === 'OTHER';
        const updatingOtherEntryType = visa.entryTypeOther !== undefined;
        if (updatingEntryTypeToOther || updatingOtherEntryType) {
          visa.entryType = visa.entryTypeOther || '';
        }

        delete visa.entryTypeOther;
      });
    }
  }

}
