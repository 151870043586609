import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { CacheStatisticsResponse } from '@shared/models/cache-statistics';
import { QueueStatistic } from '@shared/models/queue-statistics';
import { WebclientStatistics } from '@shared/models/webclient-statistics';
import { ApplicationSettings } from '@shared/models/application-settings';
import { ScheduledJobState } from '@shared/models/scheduled-jobs';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(private http: HttpClient) {
  }

  getServerVersion(): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}/public/version`);
  }

  getQueueStatistics(): Observable<QueueStatistic[]> {
    return this.http.get<QueueStatistic[]>(`${environment.apiBaseUrl}/api/v1/info/queuestatistics`);
  }

  getWebclientStatistics(): Observable<WebclientStatistics> {
    return this.http.get<WebclientStatistics>(`${environment.apiBaseUrl}/api/v1/info/webclientstatistics`);
  }

  getCacheStatistics(): Observable<CacheStatisticsResponse> {
    return this.http.get<CacheStatisticsResponse>(`${environment.apiBaseUrl}/api/v1/info/cachestatistics`);
  }

  resetCache(): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/api/v1/info/resetcache`, {});
  }

  getApplicationSettings(): Observable<ApplicationSettings> {
    return this.http.get<ApplicationSettings>(`${environment.apiBaseUrl}/api/v1/info/applicationsettings`);
  }

  setLogLevel(level: string, appender: string, filter: string): Observable<string> {
    const httpParams = new HttpParams()//
      .set('level', level)
      .set('appender', appender)
      .set('filter', filter);
    return this.http.post<string>(`${environment.apiBaseUrl}/api/v1/info/setloglevel`, httpParams);
  }

  setConnected(connected: boolean): Observable<string> {
    const httpParams = new HttpParams()//
      .set('connect', connected);
    return this.http.post<string>(`${environment.apiBaseUrl}/api/v1/info/setconnected`, httpParams);
  }

  setDumpWsXml(trace: boolean): Observable<string> {
    const httpParams = new HttpParams()//
      .set('trace', trace);
    return this.http.post<string>(`${environment.apiBaseUrl}/api/v1/info/settraceflag`, httpParams);
  }

  setDumpApiJson(trace: boolean): Observable<string> {
    const httpParams = new HttpParams()//
      .set('trace', trace);
    return this.http.post<string>(`${environment.apiBaseUrl}/api/v1/info/setapijsontraceflag`, httpParams);
  }

  getScheduledJobs(): Observable<{ [key: string]: ScheduledJobState[] }> {
    return this.http.get<{ [key: string]: ScheduledJobState[] }>(`${environment.apiBaseUrl}/api/v1/info/scheduledjobs`);
  }

  runScheduledJob(group: string, subGroup: string | undefined | null, name: string): Observable<string> {
    let httpParams = new HttpParams()//
      .set('group', group)
      .set('name', name);

    if (subGroup) {
      httpParams = httpParams.set('subGroup', subGroup);
    }

    return this.http.post<string>(`${environment.apiBaseUrl}/api/v1/info/runScheduledJob`, httpParams);
  }

  getFeatureToggles(): Observable<{ [key: string]: boolean }> {
    return this.http.get<{ [key: string]: boolean }>(`${environment.apiBaseUrl}/api/v1/info/featuretoggles`);
  }

  updateFeatureToggle(feature: string, enabled: boolean): Observable<string> {
    const httpParams = new HttpParams()//
      .set('feature', feature)
      .set('enabled', enabled);
    return this.http.post<string>(`${environment.apiBaseUrl}/api/v1/info/togglefeature`, httpParams);
  }

}
