import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessService } from '@services/access.service';
import { InfoService } from '@services/info.service';
import { MediaPreferenceService } from '@services/media-preference.service';
import { UserLoginService } from '@services/user-login.service';
import { Subscription } from 'rxjs';
import { FacesState } from './store-root/faces-state';
import { selectPrincipal, selectUserInterfaceStyle } from './store-root/selectors';
import { User } from '@models/user.class';
import { LogService } from '@services/log-service.interface';
import { FormUtils } from '@shared/form-utils.class';
import { StyleUtils } from '@shared/style-utils.class';
import { IntegrationService } from '@services/integration.service';
import { MruService } from '@services/mru.service';
import { MruEntry, MruEntryType, MruEntryTypes } from '@shared/models/mru-entry';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog/session-timeout-dialog.component';
import { UserInterfaceStyleDto } from '@shared/models/user-interface-style-dto';
import { AccessGuard } from '@guards/access.guard';
import { URLUtils } from '@shared/url-utils.class';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  principal?: User;
  serverVersion = '';
  logoImgUrl = '';

  get documentationLink(): string {
    if (this.accessGuard.documentationSection) {
      return `https://tornado.umbrellanet.ch/confluence/display/KBUF/${this.accessGuard.documentationSection}`;
    }
    return 'https://tornado.umbrellanet.ch/confluence/display/KBUF/';
  }

  get isAuthenticated(): boolean {
    return !!(this.principal && this.principal.accessToken);
  }

  get hasNavigationAccess(): boolean {
    return !this.accessService.hasAnyRole('EMBED_SABRERED');
  }

  get hasRoleAndProfileCenterAccess(): boolean {
    return this.accessService.hasAnyRole('ROLE_AGENCY_MANAGER', 'ROLE_ADMIN') &&
      !this.isInSmartpoint;
  }

  get hasInfoTabAccess(): boolean {
    return this.accessService.hasAnyRole('ROLE_AGENCY_MANAGER', 'ROLE_ADMIN') &&
      !this.isInSmartpoint;
  }

  get hasAdministratorAccess(): boolean {
    return this.accessService.hasAnyRole('ROLE_COMPANY_MANAGER', 'ROLE_AGENCY_MANAGER', 'ROLE_ADMIN') &&
      !this.isInSmartpoint;
  }

  get isSysAdmin(): boolean {
    return this.accessService.hasAnyRole('ROLE_ADMIN');
  }

  get hasAgencyAccess(): boolean {
    return !this.isInSmartpoint;
  }

  get isTraveller(): boolean {
    return this.accessService.hasAnyRole('ROLE_TRAVELLER') &&
      !this.accessService.hasAnyRole('SUBROLE_TRAVEL_MANAGER');
  }

  get isCompanyAdministrator(): boolean {
    return this.accessService.hasAnyRole('ROLE_COMPANY_MANAGER');
  }

  get isMultiAgencyAdmin(): boolean {
    return this.accessService.hasAnyRole('ROLE_ADMIN', 'SUBROLE_CIRCLE_MANAGER') ||
      (this.accessService.hasAnyRole('ROLE_AGENCY_MANAGER') && (this.principal?.travelagencyUuids?.length || 0) > 0);
  }

  get isInSmartpoint(): boolean {
    return this.accessService.hasAnyRole('EMBED_SMARTPOINT');
  }

  get isDarkMode(): boolean {
    return this.mediaPreferenceService.currentColorMode === 'dark';
  }

  get isImpersonating(): boolean {
    return this.isAuthenticated && AccessService.hasAnyRole(this.principal, 'ROLE_PREVIOUS_ADMINISTRATOR');
  }

  get impersonatingUserLabel(): string {
    return `${this.principal?.displayName} (${this.principal?.travelagencyKey})`;
  }

  get mruTravellerList(): MruEntry[] {
    return this.mruList['TRAVELLER'] || [];
  }

  get mruCompanyList(): MruEntry[] {
    return this.mruList['COMPANY'] || [];
  }

  get mruAgencyList(): MruEntry[] {
    return this.mruList['AGENCY'] || [];
  }

  get mruAdministratorList(): MruEntry[] {
    return this.mruList['ADMINISTRATOR'] || [];
  }

  get mruRoleList(): MruEntry[] {
    return this.mruList['ROLE'] || [];
  }

  private readonly defaultApplicationName = $localize`:@@application.title:Umbrella Faces`;
  private principal$: Subscription;
  private userInterfaceStyle$: Subscription;
  private sessionTimeoutHandler?: number;

  private mruList: {[key in MruEntryType]?: MruEntry[]} = {};
  private mruList$: {[key in MruEntryType]?: Subscription} = {};

  constructor(private userLoginService: UserLoginService,
              private accessService: AccessService,
              private accessGuard: AccessGuard,
              private infoService: InfoService,
              private titleService: Title,
              private router: Router,
              private mediaPreferenceService: MediaPreferenceService,
              private integrationService: IntegrationService,
              private mruService: MruService,
              private store: Store<FacesState>,
              private modalService: NgbModal,
              private zone: NgZone,
              private log: LogService) {

    this.resetCustomizables();
    this.recalculateContentHeight(0);
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.defaultApplicationName);
    this.principal$ = this.store.select(selectPrincipal).subscribe(u => this.updatePrincipal(u));
    this.infoService.getServerVersion().subscribe(v => this.serverVersion = v);
    this.userLoginService.ping().subscribe(() => {
      if (window.location.href.match(/\/public\//)) {
        // Look closer, maybe we are logging in from a server-side event
        if (window.location.pathname.endsWith('public/login') && new URLSearchParams(window.location.search).get('code')) {
          this.log.debug('Not redirecting away from login page due to a code being present', 'AppComponent#ngOnInit');
        } else {
          this.router.navigate(['/profiles/traveller']);
        }
      }
    });
    this.userInterfaceStyle$ = this.store.select(selectUserInterfaceStyle)
      .subscribe(style => {
        this.resetCustomizables();
        if (style) {
          this.customize(style);
        }
      });
    this.initMru();
  }

  ngOnDestroy() {
    this.principal$?.unsubscribe();
    this.userInterfaceStyle$?.unsubscribe();
    MruEntryTypes.forEach(type => {
      this.mruList$[type]?.unsubscribe();
    });
  }

  showPrivacyPolicy(): void {
    // Just reuse the server-side page for now instead of building our own dialog
    FormUtils.openFacesServer(`privacypolicy`, this.userLoginService);
  }

  toggleLightDarkMode(targetMode: 'light' | 'dark'): void {
    this.log.debug(`switching to ${targetMode}`)
    this.mediaPreferenceService.setOverrideColorMode(targetMode);
  }

  logout(evt?: Event): void {
    evt?.preventDefault();
    if (this.isInSmartpoint) {
      this.integrationService.smartpoint.closeWindow();
    } else {
      this.userLoginService.forgetToken();
    }
  }

  recalculateContentHeight(errorMessageHeight: number): void {
    StyleUtils.adjustDynamicHeights(errorMessageHeight);
  }

  private initMru(): void {
    MruEntryTypes.forEach(type => {
      this.mruList$[type] = this.mruService.getAll(type)
        .subscribe(mruList => this.mruList[type] = mruList);
    });

  }
  private updatePrincipal(principal?: User): void {
    this.principal = principal;

    if (this.sessionTimeoutHandler) {
      clearTimeout(this.sessionTimeoutHandler);
      this.sessionTimeoutHandler = undefined;
    }

    if (principal && !principal.originatingFrom) {
      const now = Date.now();
      const fiveMinutesToExpiration = principal.refreshTokenExpirationTimestamp - 300_000;
      const dialogTimeout = now < fiveMinutesToExpiration ? fiveMinutesToExpiration - now : 1;

      this.zone.runOutsideAngular(() => this.sessionTimeoutHandler = setTimeout(() => this.zone.runGuarded(() => this.showSessionTimeoutDialog()), dialogTimeout));
    }
  }

  private showSessionTimeoutDialog(): void {
    this.log.info(`The refresh token will expire on ${new Date(this.principal?.refreshTokenExpirationTimestamp || 0).toUTCString()}`);
    const modal = this.modalService.open(SessionTimeoutDialogComponent, {
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.logoutClicked.subscribe(() => this.logout());
  }

  private resetCustomizables(): void {
    this.logoImgUrl = 'assets/img/faces-logo-navbar.png';
    this.titleService.setTitle(this.defaultApplicationName);
  }

  private customize(style: UserInterfaceStyleDto): void {
    if (style.logoMime) {
      const suffix = style.logoMime.replace(/image\//,'');
      this.logoImgUrl = URLUtils.buildAbsoluteUrl(`${environment.serverUiBaseUrl ?? environment.apiBaseUrl}/public/logo/${style.uuid}/logo.${suffix}?_cache=${style.lastModified.getTime()}`).href;
    }
    if (style.applicationName) {
      this.titleService.setTitle(style.applicationName);
    }
  }

}
