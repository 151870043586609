import { BehaviorSubject, Observable } from 'rxjs';

export class BtaFirstMyclimateContext {

  static get instance(): BtaFirstMyclimateContext {
    return BtaFirstMyclimateContext._instance;
  }

  static init(): void {
    BtaFirstMyclimateContext._instance = new BtaFirstMyclimateContext();
  }

  private static _instance: BtaFirstMyclimateContext;

  private fixedAmountEnabled$ = new BehaviorSubject<boolean>(true);
  private percentageEnabled$ = new BehaviorSubject<boolean>(true);
  private myclimateOptionValue: boolean | undefined;
  private valueMatrix = {
    fixed: '',
    percentage: ''
  }

  fixedAmountEnabled(): Observable<boolean> {
    return this.fixedAmountEnabled$.asObservable();
  }

  percentageEnabled(): Observable<boolean> {
    return this.percentageEnabled$.asObservable();
  }

  myclimateOptionChanged(newValue: boolean | undefined): void {
    this.myclimateOptionValue = newValue;
    this.fixedAmountEnabled$.next(newValue === true);
    this.percentageEnabled$.next(newValue === true);
  }

  valueOptionChanged(valueType: 'fixed' | 'percentage', newValue: any): void {
    this.valueMatrix[valueType] = newValue;
    if (this.valueMatrix.fixed && this.valueMatrix.percentage) {
      // do nothing, both controls must currently be enabled
    } else if (this.valueMatrix.fixed) {
      this.percentageEnabled$.next(false);
    } else if (this.valueMatrix.percentage) {
      this.fixedAmountEnabled$.next(false);
    } else {
      this.fixedAmountEnabled$.next(this.myclimateOptionValue === true);
      this.percentageEnabled$.next(this.myclimateOptionValue === true);
    }

  }

}
