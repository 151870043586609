import { LogService } from '@services/log-service.interface';
import { Subscriber } from 'rxjs';
import { environment } from '@environments/environment';
import {
  FieldAndStyleConfiguration,
  TokenizationParameters,
  TokenizationProvider,
  TokenizationTransactionParameters
} from '@shared/pci/tokenization-provider.class';
import { TokenizerUtils } from '@shared/pci/tokenizer-utils.class';

export class PlaintextStoringTokenizer extends TokenizationProvider {

  private readonly inputField: HTMLInputElement;

  constructor(log: LogService, subscriber: Subscriber<TokenizationTransactionParameters>, merchantId: string, params: TokenizationParameters) {
    super(log, subscriber, merchantId, params);

    if (environment.production) {
      throw new Error('Plaintext CC-Entry cannot be allowed on production environments');
    }

    this.inputField = TokenizerUtils.createPlainNumberInputField(event => this.onChange(event));
  }

  protected override initTokenize(fields: FieldAndStyleConfiguration) {
    TokenizerUtils.addAndFocusPlainNumberInput(fields, this.inputField);
  }

  protected override submitTokenizerFrame() {
    const number = this.inputField.value.trim();

    if (number.length === 0 || this.validateCardNumber(number)) {
      this.onSuccess(number);
    } else {
      this.onValidate({
        hasErrors: true,
        fields: {
          cardNumber: {
            length: number.length
          }
        }
      });
    }
  }

  protected override cleanUpTokenizerDom() {
    this.inputField.remove();
  }

  /**
   * Custom luhn check - can't use "Payment" JS library since it does not recognize AirPlus as valid when luhn-checking
   */
  private validateCardNumber(number: string): boolean {
    number = number.replace(/\s/g, '');
    if (!number.match(/^\d+$/)) {
      return false;
    }

    let sum = 0;
    let even = false;
    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number[i], 10) | 0;

      if (even) {
        digit = digit * 2;

        if (digit > 9) {
          digit = digit - 9;
        }
      }

      sum += digit;
      even = !even;
    }

    return sum % 10 === 0;
  }
}
