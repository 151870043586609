import { createAction, props } from '@ngrx/store';

export const setSectionPopulated = createAction(
  '[section] set section populated state',
  props<{ name: string, populated: boolean }>()
);

export const setSectionHidden = createAction(
  '[section] set section hidden state',
  props<{ name: string, hidden: boolean }>()
);

export const setVirtualSection = createAction(
  '[section] update virtual section registration',
  props<{ name: string, present: boolean }>()
)
