import { Administrator } from "./administrator";
import { Travelagency } from "./agency";
import { CompanyDetailResponse } from "./company-detail-response";
import { Role } from "./role";
import { TravellerDetailResponse } from "./traveller-detail-response";

export const MruEntryTypes = ['TRAVELLER', 'COMPANY', 'AGENCY', 'ADMINISTRATOR', 'ROLE'] as const;
export type MruEntryType = typeof MruEntryTypes[number];

export class MruEntry {

  static forTraveller(traveler: TravellerDetailResponse): MruEntry {
    return new MruEntry('TRAVELLER', `profiles/traveller/${traveler.uuid}`, `${traveler.firstname} ${traveler.name}`);
  }

  static forCompany(company: CompanyDetailResponse): MruEntry {
    return new MruEntry('COMPANY', `profiles/company/${company.uuid}`, `${company.name}`);
  }

  static forAgency(agency: Travelagency): MruEntry {
    return new MruEntry('AGENCY', `agency/general/${agency.uuid}`, `${agency.name}`);
  }

  static forSupporter(supporter: Administrator): MruEntry {
    return new MruEntry('ADMINISTRATOR', `admin/administrator/${supporter.uuid}`, `${supporter.fullName}`);
  }

  static forRole(role: Role): MruEntry {
    return new MruEntry('ROLE', `admin/role/${role.uuid}`, `${role.name}`);
  }

  constructor(public entryType: MruEntryType, public resourcePath : string, public displayText: string) {}
}
