import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FormUtils } from '@shared/form-utils.class';

export enum DialogConfirmActionUseCase {
  DELETE = 'DELETE',
  GENERAL = 'GENERAL',
  DISCONNECT_INTERFACE = 'DISCONNECT',
  SHOW_CVV_DATATRANS = 'SHOW_CVV_DATATRANS',
  SHOW_CVV_MIDOCO = 'SHOW_CVV_MIDOCO',
  PAGE_RELOAD = 'RELOAD'
}

@Component({
  selector: 'app-dialog-confirm-action',
  templateUrl: './dialog-confirm-action.component.html',
  styleUrls: ['./dialog-confirm-action.component.scss']
})
export class DialogConfirmActionComponent implements OnInit {

  @Input() useCase = DialogConfirmActionUseCase.GENERAL;

  get isDismissible(): boolean {
    return this.useCase !== DialogConfirmActionUseCase.PAGE_RELOAD;
  }

  constructor(public activeModal: NgbActiveModal, private store: Store) {
  }

  ngOnInit(): void {
    FormUtils.resetError(this.store);
  }

}
