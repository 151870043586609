import { BaseCircleCustomization, ProfileCollectionNames } from '../base-circle-customization.class';
import {
  ProfileTypeConstant,
  ProfileTypeEnum,
  ReferencedCreditCardDropdown,
  TravellerFopOptionConstant,
  TravellerFopOptionEnum
} from '@shared/models/types.enum';
import { MaskedCreditCardDetails } from '@shared/models/masked-credit-card-details';
import { FormGroup } from '@angular/forms';
import { ObjectUtils } from '@shared/object-utils.class';

export class BcdSpainCustomization extends BaseCircleCustomization {

  override addedCollectionItem(profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: FormGroup): void {
    if ((profileType === ProfileTypeConstant.TRAVELLER) && (collectionName === 'creditCards')) {
      const creditcardType$ = formGroup.get('type')?.valueChanges
        .subscribe(() => {
          // this also sets publishAsFop=true on GUARANTEEONLY, but on that setting publishAsFop should be irrelevant
          formGroup.get('publishAsFop')?.setValue(true);
        });
    super.registerSubscription(creditcardType$);
    }
  }

  override customizeCreditCardNoCardOption(type: ReferencedCreditCardDropdown, creditCards: MaskedCreditCardDetails[], travellerFopOption?: TravellerFopOptionEnum): boolean {
    if (ObjectUtils.equalsAny(type, ReferencedCreditCardDropdown.WEB_CARD, ReferencedCreditCardDropdown.HOTEL_GUARANTEE, ReferencedCreditCardDropdown.CAR_GUARANTEE)) {
      return (travellerFopOption === TravellerFopOptionConstant.FPANDGUARANTEE || travellerFopOption === TravellerFopOptionConstant.GUARANTEEONLY) &&
        creditCards.length === 0;
    }

    // Show no card option (if available)
    return true;
  }


}
