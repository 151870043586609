import { Injectable, OnDestroy, Type } from '@angular/core';
import { BaseCircleCustomization, ProfileCollectionNames } from '../../circles/base-circle-customization.class';
import { Option, TypedOption } from '@shared/models/option';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectPrincipal } from 'src/app/store-root/selectors';
import { User } from '@shared/models/user.class';
import { CtmCustomization } from '../../circles/ctm/ctm-customization.class';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { ProfileTypeEnum, ReferencedCreditCardDropdown, TravellerFopOptionEnum } from '@shared/models/types.enum';
import { ClubTravelCustomization } from '../../circles/club-travel/club-travel-customization.class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessService } from './access.service';
import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { GenericFieldSetup } from '@shared/models/generic-field-setup';
import { BrzCustomization } from '../../circles/brz/brz-customization.class';
import { BaseGenericValueWithSetupComponent } from '../../circles/base-generic-value-with-setup-component';
import { FbtCustomization } from 'src/app/circles/fbt/fbt-customization.class';
import { FinassCustomization } from 'src/app/circles/finass/finass-customization.class';
import { UniglobeHollandCustomization } from 'src/app/circles/uniglobe-holland/uniglobe-holland-customization.class';
import { VckCustomization } from 'src/app/circles/vck/vck-customization.class';
import { AtgCustomization } from 'src/app/circles/atg/atg-customization.class';
import { BfpCustomization } from 'src/app/circles/bfp/bfp-customization.class';
import { BtuCustomization } from 'src/app/circles/btu/btu-customization.class';
import { MunckhofCustomization } from 'src/app/circles/munckof/munckof-customization.class';
import { AbbCustomization } from 'src/app/circles/abb/abb-customization.class';
import { BtaFirstCustomization } from 'src/app/circles/btafirst/bta-first-customization.class';
import { CircleBackendService } from './circle-backend.service';
import { BigTravelCustomization } from 'src/app/circles/big_travel/big-travel-customization.class';
import { RailCardTypeReferenceDefinition } from '@shared/models/coded-reference-definition';
import { MaskedCreditCardDetails } from '@shared/models/masked-credit-card-details';
import { BcdSpainCustomization } from 'src/app/circles/bcd_spain/bcd-spain-customization.class';
import { AtlasTravelCustomization } from 'src/app/circles/atlas_travel/atlas-travel-customization.class';
import { CtmNoramCustomization } from 'src/app/circles/ctm_noram/ctm-noram-customization.class';
import { VrTravelCustomization } from '../../circles/vr-travel/vr-travel-customization';
import { PapendickCustomization } from '../../circles/papendick/papendick-customization';
import { UniglobeNoramCustomization } from '../../circles/uniglobe-noram/uniglobe-noram-customization';
import { SchrodersCustomization } from '../../circles/schroders/schroders-customization.class';
import { UniglobeCustomization } from '../../circles/uniglobe/uniglobe-customization';
import { ResiaCustomization } from 'src/app/circles/resia/resia-customization.class';
import { HolcarCustomization } from '../../circles/holcar/holcar-customization';
import { GlobalstarCustomization } from '../../circles/globalstar/globalstar-customization';
import { OevbCustomization } from '../../circles/oevb/oevb-customization.class';


@Injectable({
  providedIn: 'root'
})
export class CircleCustomizationService implements OnDestroy {

  private delegate: BaseCircleCustomization = new BaseCircleCustomization();
  private principal$: Subscription;

  constructor(private store: Store<FacesState>,
    private accessService: AccessService,
    private circleBackendService: CircleBackendService,
    private modalService: NgbModal) {

    this.principal$ = this.store.select(selectPrincipal).subscribe(user => {
      this.updateDelegate(user);
    });
  }

  ngOnDestroy(): void {
    this.principal$?.unsubscribe();
    this.delegate.destroy();
  }

  customizeSexOptions(optionList: Option[]): void {
    this.delegate.customizeSexOptions(optionList);
  }

  customizeAirlineListForTravellers(optionList: Option[]): void {
    this.delegate.customizeAirlineListForTravellers(optionList);
  }

  customizeAirlineListForCompanies(optionList: Option[]): void {
    this.delegate.customizeAirlineListForCompanies(optionList);
  }

  customizeAirlinePublishtypes(optionList: Option[]): void {
    this.delegate.customizeAirlinePublishtypes(optionList);
  }

  customizeHotelList(optionList: Option[]): void {
    this.delegate.customizeHotelList(optionList);
  }

  customizeRentalCarList(optionList: Option[]): void {
    this.delegate.customizeRentalCarList(optionList);
  }

  customizeRailCardTypes(railCardList: TypedOption<RailCardTypeReferenceDefinition>[]): void {
    this.delegate.customizeRailCardTypes(railCardList);
  }

  customizeRailWagonTypes(railWagonTypes: Option[]): void {
    this.delegate.customizeRailWagonTypes(railWagonTypes);
  }

  customizeCreditCardTypes(optionList: Option[]): void {
    this.delegate.customizeCreditCardTypes(optionList);
  }

  customizeCreditCardNoCardOption(type: ReferencedCreditCardDropdown, creditCards: MaskedCreditCardDetails[], travellerFopOption?: TravellerFopOptionEnum): boolean {
    return this.delegate.customizeCreditCardNoCardOption(type, creditCards, travellerFopOption);
  }

  customizeEmailDeliveries(optionList: Option[]): void {
    this.delegate.customizeEmailDeliveries(optionList);
  }

  customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    this.delegate.customizeTravellerFormGroup(traveller, formGroup);
  }

  customizeCompanyFormGroup(company: CompanyDetailResponse | undefined, formGroup: FormGroup): void {
    this.delegate.customizeCompanyFormGroup(company, formGroup);
  }

  addedCollectionItem(profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: FormGroup): void {
    this.delegate.addedCollectionItem(profileType, collectionName, formGroup);
  }

  removedCollectionItem(profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: AbstractControl<any, any>): void {
    this.delegate.removedCollectionItem(profileType, collectionName, formGroup);
  }

  /**
   *
   * @param controlPath Array of property path, from global to local. Typical value is ['data','creditCardInfo','webCard']
   * @param profileType
   * @returns
   */
  getTransientStandardfieldDefinition(controlPath: string[], profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    return this.delegate.getTransientStandardfieldDefinition(controlPath, profileType);
  }

  getCustomizedGenericFieldSetup(genericFieldSetup?: GenericFieldSetup): GenericFieldSetup | undefined {
    return this.delegate.getCustomizedGenericFieldSetup(genericFieldSetup);
  }

  getGenericValueWithSetupComponent(genericFieldSetup?: GenericFieldSetup): Type<BaseGenericValueWithSetupComponent> | undefined {
    return this.delegate.getGenericValueWithSetupComponent(genericFieldSetup);
  }

  onTravellerSave(formGroup: FormGroup, callback: () => void): void {
    this.delegate.onTravellerSave(formGroup, callback);
  }

  onCompanySave(formGroup: FormGroup, callback: () => void): void {
    this.delegate.onCompanySave(formGroup, callback);
  }

  private updateDelegate(user: User | undefined): void {
    this.delegate.destroy();
    switch (user?.circle) {
      case 'ABB':
        this.delegate = new AbbCustomization();
        break;
      case 'ATG':
        this.delegate = new AtgCustomization(this.store);
        break;
      case 'ATLAS_TRAVEL':
        this.delegate = new AtlasTravelCustomization(this.accessService, this.modalService);
        break;
      case 'BCD_SPAIN':
        this.delegate = new BcdSpainCustomization();
        break;
      case 'BFP':
        this.delegate = new BfpCustomization();
        break;
      case 'BIG_TRAVEL':
        this.delegate = new BigTravelCustomization(this.accessService);
        break;
      case 'BTAFIRST':
        this.delegate = new BtaFirstCustomization(this.circleBackendService);
        break;
      case 'BTU':
        this.delegate = new BtuCustomization();
        break;
      case 'BRZ':
        this.delegate = new BrzCustomization();
        break;
      case 'CLUB_TRAVEL':
        this.delegate = new ClubTravelCustomization(this.accessService, this.modalService);
        break;
      case 'CTM':
        this.delegate = new CtmCustomization();
        break;
      case 'CTM_NORAM':
        this.delegate = new CtmNoramCustomization(this.store, this.circleBackendService, this.accessService);
        break;
      case 'FBT':
        this.delegate = new FbtCustomization();
        break;
      case 'FINASS':
        this.delegate = new FinassCustomization();
        break;
      case 'GLOBAL_STAR':
        this.delegate = new GlobalstarCustomization();
        break;
      case 'HOLCAR':
        this.delegate = new HolcarCustomization();
        break;
      case 'MUNCKHOF':
        this.delegate = new MunckhofCustomization();
        break;
      case 'OEVB':
        this.delegate = new OevbCustomization();
        break;
      case 'PAPENDICK':
        this.delegate = new PapendickCustomization();
        break;
      case 'RESIA':
        this.delegate = new ResiaCustomization(this.accessService, this.modalService);
        break;
      case 'SCHRODERS':
        this.delegate = new SchrodersCustomization();
        break;
      case 'UNIGLOBE':
        this.delegate = new UniglobeCustomization(this.store, this.circleBackendService);
        break;
      case 'UNIGLOBE_HOLLAND':
        this.delegate = new UniglobeHollandCustomization();
        break;
      case 'UNIGLOBE_NORAM':
        this.delegate = new UniglobeNoramCustomization();
        break;
      case 'VCK':
        this.delegate = new VckCustomization();
        break;
      case 'VRTRAVEL':
        this.delegate = new VrTravelCustomization();
        break;
      default:
        this.delegate = new BaseCircleCustomization();
        break;
    }
  }

}
