<div class="app-container">
  <div class="nav-banner shadow-sm" *ngIf="hasNavigationAccess">
    <nav class="navbar navbar-expand-xxl" [ngClass]="isDarkMode ? 'navbar-dark' : 'navbar-light'" *ngIf="isAuthenticated">
      <div class="container-fluid">
        <a class="navbar-brand" routerLink="/">
          <img [src]="logoImgUrl" height="45" alt="Navigate to home">
        </a>
        <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarContent">
          <ul class="navbar-nav">
            <li class="nav-item app-nav-traveller">
              <a class="nav-link" routerLinkActive="nav-active" routerLink="/profiles/traveller">
                <span *ngIf="isTraveller" i18n="@@main.myprofile">My profile</span>
                <span *ngIf="!isTraveller" i18n="@@main.travellers">Travellers</span>
              </a>
            </li>
            <li *ngIf="!isTraveller && mruTravellerList && mruTravellerList.length" class="nav-item app-nav-traveller-mru dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarMruTravellerDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false"></a>
              <ul class="dropdown-menu" aria-labelledby="navbarMruTravellerDropdown">
                <li *ngFor="let mruEntry of mruTravellerList">
                  <a class="nav-item nav-link" [routerLink]="mruEntry.resourcePath">
                    {{mruEntry.displayText}}
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item app-nav-company">
              <a class="nav-link" routerLinkActive="nav-active" routerLink="/profiles/company">
                <span *ngIf="isTraveller" i18n="@@main.company">Company</span>
                <span *ngIf="!isTraveller" i18n="@@main.companies">Companies</span>
              </a>
            </li>
            <li *ngIf="!isTraveller && mruCompanyList && mruCompanyList.length" class="nav-item dropdown app-nav-company-mru">
              <a class="nav-link dropdown-toggle" href="#" id="navbarMruCompanyDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false"></a>
              <ul class="dropdown-menu" aria-labelledby="navbarMruCompanyDropdown">
                <li *ngFor="let mruEntry of mruCompanyList">
                  <a class="nav-item nav-link" [routerLink]="mruEntry.resourcePath">
                    {{mruEntry.displayText}}
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item app-nav-agency" *ngIf="hasAgencyAccess">
              <a class="nav-link" routerLinkActive="nav-active" routerLink="/agency/general">
                <span *ngIf="isMultiAgencyAdmin" i18n="@@main.agencies">Agencies</span>
                <span *ngIf="!isMultiAgencyAdmin" i18n="@@main.myagency">Agency</span>
              </a>
            </li>
            <li *ngIf="hasAgencyAccess && isMultiAgencyAdmin && mruAgencyList && mruAgencyList.length" class="nav-item  app-nav-agency-mru dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarMruAgencyDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false"></a>
              <ul class="dropdown-menu" aria-labelledby="navbarMruAgencyDropdown">
                <li *ngFor="let mruEntry of mruAgencyList">
                  <a class="nav-item nav-link" [routerLink]="mruEntry.resourcePath">
                    {{mruEntry.displayText}}
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item app-nav-administrator" *ngIf="hasAdministratorAccess">
              <a class="nav-link" routerLinkActive="nav-active" routerLink="/admin/administrator">
                <span *ngIf="isCompanyAdministrator" i18n="@@main.myprofile">My profile</span>
                <span *ngIf="!isCompanyAdministrator" i18n="@@main.supporters">Administrators</span>
              </a>
            </li>
            <li *ngIf="hasAdministratorAccess && !isCompanyAdministrator && mruAdministratorList && mruAdministratorList.length" class="nav-item app-nav-administrator-mru dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarMruAdministratorDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false"></a>
              <ul class="dropdown-menu" aria-labelledby="navbarMruAdministratorDropdown">
                <li *ngFor="let mruEntry of mruAdministratorList">
                  <a class="nav-item nav-link" [routerLink]="mruEntry.resourcePath">
                    {{mruEntry.displayText}}
                  </a>
                </li>
              </ul>
            </li>
            <ng-container *ngIf="hasRoleAndProfileCenterAccess">
              <li class="nav-item app-nav-roles">
                <a class="nav-link" i18n="@@main.roles" routerLinkActive="nav-active"
                   routerLink="/admin/role">Roles</a>
              </li>
              <li *ngIf="mruRoleList && mruRoleList.length" class="nav-item app-nav-roles-mru dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarMruRoleDropdown" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false"></a>
                <ul class="dropdown-menu" aria-labelledby="navbarMruRoleDropdown">
                  <li *ngFor="let mruEntry of mruRoleList">
                    <a class="nav-item nav-link" [routerLink]="mruEntry.resourcePath">
                      {{mruEntry.displayText}}
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item app-nav-profilecenter">
                <a class="nav-link" i18n="@@main.profilecenter" routerLinkActive="nav-active"
                   routerLink="/profilecenter/home">Profile Center</a>
              </li>
            </ng-container>
            <li class="nav-item app-nav-styles" *ngIf="isSysAdmin">
              <a class="nav-link" routerLinkActive="nav-active" routerLink="/admin/style">
                  <span i18n="@@main.styles">Styles</span>
              </a>
            </li>
            <li class="nav-item app-nav-info" *ngIf="hasInfoTabAccess">
              <a class="nav-link" i18n="@@main.info" routerLinkActive="nav-active"
                 routerLink="/info/home">Info</a>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item app-nav-privacypolicy" *ngIf="!isInSmartpoint">
              <span class="nav-link pseudo-link">
                <i class="bi bi-shield-check" (click)="showPrivacyPolicy()"
                   title="Privacy Policy" i18n-title="@@login.privacypolicy"></i>
              </span>
            </li>
            <li class="nav-item app-nav-documentation" *ngIf="!isInSmartpoint">
              <a class="nav-link" [href]="documentationLink" target="_blank">
                <i class="bi bi-question-circle" title="Documentation" i18n-title="@@main.documentation"></i>
              </a>
            </li>
            <li class="nav-item app-nav-modetoggle" *ngIf="!isInSmartpoint">
              <span class="nav-link pseudo-link" [ngSwitch]="isDarkMode">
                <i *ngSwitchCase="true" (click)="toggleLightDarkMode('light')" class="bi bi-moon-fill"
                   title="Dark mode" i18n-title="@@myaccount.colorscheme.dark"></i>
                <i *ngSwitchDefault (click)="toggleLightDarkMode('dark')" class="bi bi-brightness-high"
                   title="Light mode" i18n-title="@@myaccount.colorscheme.light"></i>
              </span>
            </li>
            <li class="nav-item app-nav-logout">
              <a class="nav-link uf-logout-link" href="logout" [title]="principal?.displayName" (click)="logout($event)">
                <i class="bi bi-box-arrow-right" *ngIf="!isImpersonating; else impersonatingIndicator"></i>
                <ng-template #impersonatingIndicator>
                  <i class="bi bi-person-bounding-box" [title]="impersonatingUserLabel"></i>
                </ng-template>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <div class="workarea-width">
    <app-error [hideIfModalPresent]="true" (heightChanged)="recalculateContentHeight($event)"></app-error>
    <div id="data-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="footer">
    <div id="serverVersion">{{serverVersion}}</div>
  </div>
</div>
