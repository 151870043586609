import { BaseCircleCustomization } from '../base-circle-customization.class';
import { MaskedCreditCardDetails } from '@models/masked-credit-card-details';
import { ReferencedCreditCardDropdown, TravellerFopOptionConstant, TravellerFopOptionEnum } from '@models/types.enum';
import { ObjectUtils } from '@shared/object-utils.class';

export class UniglobeNoramCustomization extends BaseCircleCustomization {

  override customizeCreditCardNoCardOption(type: ReferencedCreditCardDropdown, creditCards: MaskedCreditCardDetails[], travellerFopOption?: TravellerFopOptionEnum): boolean {
    if (type === ReferencedCreditCardDropdown.HOTEL_GUARANTEE) {
      return ObjectUtils.equalsAny(travellerFopOption, TravellerFopOptionConstant.FPANDGUARANTEE, TravellerFopOptionConstant.GUARANTEEONLY) &&
        creditCards.length === 0;
    }

    // Show no card option (if available)
    return true;
  }

}
