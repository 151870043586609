import { BaseCircleCustomization } from '../base-circle-customization.class';
import { FormGroup } from '@angular/forms';
import { AngularUtils } from '@shared/angular-utils.class';
import { CompanyDetailResponse } from '@models/company-detail-response';
import { EmailDeliveryConstant } from '@models/types.enum';

export class PapendickCustomization extends BaseCircleCustomization {

  override customizeCompanyFormGroup(company: CompanyDetailResponse | undefined, formGroup: FormGroup) {
    if (!company?.uuid) {
      AngularUtils.runOnNextTick(() => {
        formGroup.get('data.generalSettings.emailDelivery')?.setValue(EmailDeliveryConstant.EMAILPLUS);
      });
    }
  }
}
