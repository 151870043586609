import { ObjectUtils } from '@shared/object-utils.class';

export class User {
  uuid: string;
  username: string;
  displayName: string;
  travelagencyUuid: string;
  travelagencyKey: string;
  companyUuid: string;
  associatedTravellerUuid: string;
  circle: string;
  acceptedPrivacyPolicy: boolean;
  twoFactorState: string;
  rootCompanyUuids: string[];
  authorities: Authority[];
  attributes: string;
  enabled: boolean;
  credentialsNonExpired: boolean;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  userBacked: boolean;
  selfRegistrationKey?: string;
  languageLocale: string;
  travelagencyUuids: string[];
  accessToken: string;
  accessTokenExpiration?: Date;
  refreshToken: string;
  refreshTokenExpirationTimestamp: number;

  /**
   * This property allows for creating a user chain (switch user). Managed client-side.
   */
  originatingFrom?: User;

  static readonly ROLE_SELFREG_USER = 'ROLE_SELFREG_USER';

  static getRole(authorities: Authority[] | undefined): string {
    if (authorities) {
      return authorities
        .filter(auth => auth.authority.startsWith('ROLE_'))
        .filter(auth => !ObjectUtils.equalsAny(auth.authority, 'ROLE_PREVIOUS_ADMINISTRATOR', User.ROLE_SELFREG_USER))
        .map(auth => auth.authority)[0] ?? '';
    }
    return '';
  }

  static isSelfRegistrationUser(principal?: User): boolean {
    return principal?.authorities?.map(auth => auth.authority).includes(User.ROLE_SELFREG_USER) ?? false;
  }

}

interface Authority {
  authority: string;
}
