import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccessService } from '@services/access.service';
import { User } from '@shared/models/user.class';
import { Observable } from 'rxjs';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectPrincipal } from 'src/app/store-root/selectors';

@Injectable({providedIn: 'root'})
export class AccessGuard  {

  documentationSection?: string;

  private principal: User | undefined;

  constructor(
    private router: Router,
    private store: Store<FacesState>
  ) {
    this.store.select(selectPrincipal)
      .subscribe(p => this.principal = p);
  }

  // access for loadChildren() access (i.e. modules)
  // @Override
  canLoad(_route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.guardAccess([], segments.map(s => s.path).join('/'));
  }

  // access to individual pages
  // @Override
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.documentationSection = route.data?.['documentationSection'];
    const requiredRole: string[] = route.data?.['anyRole'] || [];
    return this.guardAccess(requiredRole, state.url);
  }

  private guardAccess(requiredRole: string[], returnUrl = '/'): boolean {
    if (this.principal) {
      if (requiredRole && requiredRole.length > 0) {
        return AccessService.hasAnyRole(this.principal, ...requiredRole);
      }
      return true;
    }

    this.router.navigate(['/public/login'], {queryParams: {returnUrl: returnUrl}});
    return false;
  }
}
