import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BaseCircleCustomization } from "../base-circle-customization.class";
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum, PublishTargetSystemConstant, TravelGroupAllocationRoleConstant, TravelGroupAllocationRoleEnum } from '@shared/models/types.enum';
import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { Store } from '@ngrx/store';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectCompanyContextForTraveller } from 'src/app/feature-profiles/store-feature/profiles-selectors';
import { CompanyReferenceData } from '@shared/models/company-referencedata';
import { CollectionOperationConstant } from '@shared/models/collection-operation.enum';
import { GenericFieldSetup } from '@shared/models/generic-field-setup';

export class AtgCustomization extends BaseCircleCustomization {

  private companyReferenceData?: CompanyReferenceData;

  constructor(private store: Store<FacesState>) {
    super();
    super.registerSubscription(this.store.select(selectCompanyContextForTraveller).subscribe(ctx => {
       this.companyReferenceData = ctx?.referenceData;
     }));
  }

  override getTransientStandardfieldDefinition(controlPath: string[], _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if ('email' === controlName) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.TRAVELLER,
        affectedUserGroup: AffectedUserGroupConstant.COMPANY_ADMIN_AND_TRAVELLER,
        fieldDisplayType: FieldDisplayTypeConstant.READONLY,
        label: ''
      };
    }
    return;
  }

  override getCustomizedGenericFieldSetup(genericFieldSetup?: GenericFieldSetup): GenericFieldSetup | undefined {
    if (genericFieldSetup?.name === 'EventManager') {
      return {
        ...genericFieldSetup,
        ...{
          editable: false
        }
      };
    }
    return genericFieldSetup;
  }

  override customizeTravellerFormGroup(_traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    this.registerSubscription(formGroup.get('data.roles.arranger')?.valueChanges.subscribe(val => this.updateTravelGroups(formGroup, val)));
  }

  private updateTravelGroups(formGroup: FormGroup, newArrangerValue: boolean): void {
    if (newArrangerValue && this.companyReferenceData?.enabledTargetSystems.includes(PublishTargetSystemConstant.CYTRIC)) {
      let defaultGroup = this.companyReferenceData?.travelgroups.find(item => item.name.indexOf('Default') >= 0);
      if (!defaultGroup) {
        defaultGroup = this.companyReferenceData?.travelgroups[0];
      }
      let arrangerTargetGroup: FormGroup;
      if (formGroup.get('data.roles.traveller')?.value === true) {
        this.setGroupAttributes(formGroup.get('data.travelGroups.0') as FormGroup, defaultGroup?.code, TravelGroupAllocationRoleConstant.TRAVELER);
        arrangerTargetGroup = this.getOrCreateArrangerTargetGroup(formGroup);
      } else {
        arrangerTargetGroup = formGroup.get('data.travelGroups.0') as FormGroup;
      }
      this.setGroupAttributes(arrangerTargetGroup, defaultGroup?.code, TravelGroupAllocationRoleConstant.TRAVELARRANGERRESTRICTEDPM);
    }
  }

  private setGroupAttributes(travelGroupFormGroup: FormGroup, groupId?: string, role?: TravelGroupAllocationRoleEnum): void {
    if (travelGroupFormGroup) {
      travelGroupFormGroup.get('groupId')?.setValue(groupId);
      travelGroupFormGroup.get('role')?.setValue(role);
      travelGroupFormGroup.get('operation')?.setValue(CollectionOperationConstant.SaveUpdate);
    }
  }

  private getOrCreateArrangerTargetGroup(formGroup: FormGroup): FormGroup {
    const travelGroups = formGroup.get('data.travelGroups') as FormArray;
    if (travelGroups.length === 2) {
      return travelGroups.at(1) as FormGroup;
    }
    const additionalGroup = new FormGroup({
      uuid: new FormControl(''),
      groupId: new FormControl(''),
      role: new FormControl(''),
      operation: new FormControl('')
    });
    travelGroups.push(additionalGroup);
    return additionalGroup;
  }

}
