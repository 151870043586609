import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, of, switchMap, take, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import { UserLoginService } from '@services/user-login.service';
import { Router } from '@angular/router';
import { OauthTokenService } from '@services/oauth-token.service';
import { LogService } from '@services/log-service.interface';

export class RequestDroppedError implements Error {
  readonly name = 'RequestDroppedError';
  readonly message = 'Request was dropped due to unmet preconditions';
}

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  private get accessToken(): string | undefined {
    return this.oauthTokenService.accessToken;
  }
  private get refreshToken(): string | undefined {
    return this.oauthTokenService.refreshToken;
  }
  private get impersonated(): boolean {
    return this.oauthTokenService.impersonated;
  }

  constructor(private userLoginService: UserLoginService,
              private oauthTokenService: OauthTokenService,
              private log: LogService,
              private injector: Injector) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.doInternalIntercept(request, next, true);
  }

  private doInternalIntercept(request: HttpRequest<any>, next: HttpHandler, allowRefresh: boolean): Observable<HttpEvent<any>> {
    const accessToken = this.accessToken;
    const isApiUrl = request.url.startsWith(environment.apiBaseUrl) && request.url.indexOf('/public/') < 0;
    const isOauthTokenUrl = this.isOauthTokenCall(request.url);
    if (isApiUrl && !isOauthTokenUrl) {

      if (accessToken) {
        if (this.oauthTokenService.accessTokenExpired && allowRefresh) {
          this.log.debug('Expecting expired access, pre-emptive refreshing');
          return this.handleUnauthorizedError(request, next);
        }
        const authorizedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        return next.handle(authorizedRequest)
          .pipe(catchError(error => this.handleHttpError(error, authorizedRequest, next, allowRefresh)));
      }
      // API call without an access token, rather pointless
      throw new RequestDroppedError();
    }

    return next.handle(request);
  }

  private handleHttpError(error: any, authorizedRequest: HttpRequest<any>, next: HttpHandler, allowRefresh: boolean): Observable<HttpEvent<any>> {
    if (error.status === 401) {
      return this.handleHttpError401(error, authorizedRequest, next, allowRefresh);
    }
    if (error.status === 503) {
      return this.handleHttpError503();
    }
    return throwError(() => error);
  }

  private handleHttpError401(error: any, authorizedRequest: HttpRequest<any>, next: HttpHandler, allowRefresh: boolean): Observable<HttpEvent<any>> {
    // Allow refresh if we have a 401- response and are not currently attempting to logout
    const accessDeniedError = error instanceof HttpErrorResponse && !this.isOauthLogoutCall(authorizedRequest.url);
    if (allowRefresh && accessDeniedError) {
      if (this.refreshToken || this.impersonated) {
        return this.handleUnauthorizedError(authorizedRequest, next);
      }
      // here we'd like to refresh, but can't, so logout principal
      this.userLoginService.forgetToken();
      return of(<HttpEvent<any>>{});
    }
    return throwError(() => error);
  }

  private handleHttpError503(): Observable<HttpEvent<any>> {
    // GlobalErrorHandlerService.getApplicationError() needs a 'message' on the error
    return throwError(() => new Error('We\'re upgrading our server, please try again in a few seconds'));
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const router: Router = this.injector.get(Router);
    if (this.isRefreshingToken && this.isOauthTokenCall(request.url)) {
      this.isRefreshingToken = false;
      router.navigate(['public/logout']);
      return of(<HttpEvent<any>>{});
    }
    if (!this.isRefreshingToken && this.accessToken) {
      this.log.debug(`Running token refresh, then executing ${request.url}`);
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(undefined);

      return this.userLoginService.refreshAccessToken().pipe(
        switchMap(result => {
          this.isRefreshingToken = false;
          if (result) {
            // refresh worked, login service has dispatched a new principal (or user needs to change password)
            this.tokenSubject.next(result.accessToken);
            return this.doInternalIntercept(request, next, false);
          }
          this.userLoginService.forgetToken();
          return of(<HttpEvent<any>>{});
        }),
        catchError(() => {
          this.isRefreshingToken = false;
          this.userLoginService.forgetToken(false);
          return of(<HttpEvent<any>>{});
        }));
    }

    if (this.isRefreshingToken) {
      this.log.debug(`Delaying request ${request.url}`);
      // this must be a call to a secured resource, but another call has already
      // triggered a token refresh. Thus, wait for the tokenSubject to update:
      return this.tokenSubject
        .pipe(filter(token => !!token),
          take(1),
          switchMap(() => {
            return this.doInternalIntercept(request, next, false);
          })
        );
    }
    // give up. user is not authenticated, but was trying to access a secured resource
    return of(<HttpEvent<any>>{});
  }

  private isOauthTokenCall(url: string): boolean {
    return url.indexOf('/oauth/token') >= 0;
  }

  private isOauthLogoutCall(url: string): boolean {
    return url.indexOf('/oauth/logout') >= 0;
  }

}
