import { TravelAgencyService } from '@services/travel-agency.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { throwIfAlreadyLoaded } from '@guards/module-import.guard';
import { UserLoginService } from '@services/user-login.service';
import { AuthenticationInterceptor } from './interceptors/auth.interceptor'
import { GlobalErrorHandlerService } from '@services/global-error-handler.service';
import { TravellerService } from '@services/traveller.service';
import { CompanyService } from '@services/company.service';
import { AccessService } from '@services/access.service';
import { SystemDataService } from '@services/system-data.service';
import { PayloadInterceptor } from './interceptors/payload.interceptor';
import { StandardfieldConfigurationService } from '@services/standardfield-configuration.service';
import { environment } from '@environments/environment';
import { LogService } from '@services/log-service.interface';
import { LogServiceDevelopment } from '@services/log-service-dev.service';
import { LogServiceProduction } from '@services/log-service-prod.service';
import { SelectionService } from '@services/selection.service';
import { RoleService } from '@services/role.service';
import { AdministratorService } from '@services/administrator.service';
import { WebSocketClientService } from '@services/websocket-client.service';
import { InfoService } from '@services/info.service';
import { MediaPreferenceService } from '@services/media-preference.service';
import { CreditCardTokenizationService } from '@services/credit-card-tokenization.service';
import { PrincipalLanguageService } from '@services/principal-language.service';
import { IntegrationService } from '@services/integration.service';
import { MruService } from '@services/mru.service';
import { CircleCustomizationService } from '@services/circle-customization.service';
import { CircleBackendService } from '@services/circle-backend.service';
import { OauthTokenService } from '@services/oauth-token.service';

const interceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: PayloadInterceptor, multi: true}
];

@NgModule({ declarations: [ /* should stay empty! */], imports: [], providers: [
        interceptorProviders,
        { provide: LogService, useClass: environment.production ? LogServiceProduction : LogServiceDevelopment },
        UserLoginService, OauthTokenService, GlobalErrorHandlerService, AccessService, SystemDataService, SelectionService,
        StandardfieldConfigurationService, TravellerService, CompanyService, RoleService, AdministratorService,
        TravelAgencyService, WebSocketClientService, InfoService, MediaPreferenceService, CreditCardTokenizationService,
        PrincipalLanguageService, IntegrationService, MruService, CircleCustomizationService, CircleBackendService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
