import { ApplicationError } from "@services/global-error-handler.service";
import { UserInterfaceStyleDto } from "@shared/models/user-interface-style-dto";
import { User } from "@shared/models/user.class";

export interface FacesState {
  principal: User | undefined;
  lastError: ApplicationError | undefined;
  userInterfaceStyle: UserInterfaceStyleDto | undefined;
}

export const initialFacesState: FacesState = {
  principal: undefined,
  lastError: undefined,
  userInterfaceStyle: undefined
}
