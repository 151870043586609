import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MruEntry, MruEntryType, MruEntryTypes } from "@shared/models/mru-entry";
import { FacesState } from "src/app/store-root/faces-state";
import { selectPrincipal } from "src/app/store-root/selectors";
import { User } from "@shared/models/user.class";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MruService {

  private static MRU_SIZE = 10;
  private mruListBaseKey?: string;
  private mruSubjectMap: {[key in MruEntryType]: BehaviorSubject<MruEntry[]>};

  constructor(private store: Store<FacesState>) {
    this.mruSubjectMap = {
      TRAVELLER: new BehaviorSubject<MruEntry[]>([]),
      COMPANY: new BehaviorSubject<MruEntry[]>([]),
      AGENCY: new BehaviorSubject<MruEntry[]>([]),
      ADMINISTRATOR: new BehaviorSubject<MruEntry[]>([]),
      ROLE: new BehaviorSubject<MruEntry[]>([])
    };
    this.store.select(selectPrincipal)
      .subscribe(user => this.updateMruListKey(user));
  }

  add(mruEntry: MruEntry) {
    const mruListKey = this.getMruStorageKey(mruEntry.entryType);
    if (mruListKey) {
      const mruListSubject = this.mruSubjectMap[mruEntry.entryType];
      const currentValue = mruListSubject.value
        .filter(item => item.resourcePath !== mruEntry.resourcePath);
      const updatedValue = [mruEntry, ...currentValue];
      while (updatedValue.length > MruService.MRU_SIZE) {
        updatedValue.pop();
      }
      localStorage.setItem(mruListKey, JSON.stringify(updatedValue));
      mruListSubject.next(updatedValue);
    }
  }

  getAll(mruEntryType: MruEntryType): Observable<MruEntry[]> {
    return this.mruSubjectMap[mruEntryType].asObservable();
  }

  private readFromStorage(mruEntryType: MruEntryType): MruEntry[] {
    const mruListKey = this.getMruStorageKey(mruEntryType);
    if (mruListKey) {
      const storedMru = localStorage.getItem(mruListKey);
      if (storedMru) {
        return JSON.parse(storedMru);
      }
    }
    return [];
  }

  private updateMruListKey(principal?: User): void {
    if (principal) {
      this.mruListBaseKey = `faces.mru.${principal.uuid}`;
    } else {
      this.mruListBaseKey = undefined;
    }
    MruEntryTypes.forEach(mruEntryType => {
      this.mruSubjectMap[mruEntryType].next(this.readFromStorage(mruEntryType));
    });

  }

  private getMruStorageKey(mruEntryType: MruEntryType): string | undefined {
    if (this.mruListBaseKey) {
      return `${this.mruListBaseKey}.${mruEntryType}`;
    }
    return undefined;
  }

}
