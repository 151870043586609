import { NgOption } from "./option";

export interface GenericFieldOrderGroupOptions {
  travellerOptions: NgOption[];
  companyOptions: NgOption[];
}

export type GenericFieldOrderOptions = {[group: string]: GenericFieldOrderGroupOptions};

export const allGenericFieldOrderOptions = {
  'general.header_general': {
    travellerOptions: [],
    companyOptions: []
  },
  'general.header_prefs': {
    travellerOptions: [],
    companyOptions: []
  },
  'company.header_agency': {
    travellerOptions: [],
    companyOptions: []
  },
  'traveller.papersheader': {
    travellerOptions: [],
    companyOptions: [] // not integrated
  },
  'traveller.adminheader': {
    travellerOptions: [],
    companyOptions: [] // not integrated
  },
} as GenericFieldOrderOptions;

[
  {order: 60, fieldname: $localize`:@@traveller.sex:Gender`},
  {order: 65, fieldname: $localize`:@@traveller.commonTitle:Greeting`},
  {order: 70, fieldname: $localize`:@@traveller.title:Title`},
  {order: 80, fieldname: $localize`:@@traveller.firstname:First name`},
  {order: 110, fieldname: $localize`:@@traveller.birthdate:Date of birth`},
  {order: 120, fieldname: $localize`:@@traveller.nationality:Citizenship`},
  {order: 150, fieldname: $localize`:@@traveller.phone_private:Phone private`},
  {order: 180, fieldname: $localize`:@@traveller.receivedocs:Reiseplan / E-Ticket`}
].forEach(entry => {
  const options = allGenericFieldOrderOptions['general.header_general'].travellerOptions;
  options.push(new NgOption(entry.order - 1, $localize`:@@generic.fieldorder.general.before:Before` + ' ' + entry.fieldname));
  options.push(new NgOption(entry.order + 1, $localize`:@@generic.fieldorder.general.after:After` + ' ' + entry.fieldname));
});

[
  {order: 10, fieldname: $localize`:@@traveller.preferredseat:Prefered seat`},
  {order: 20, fieldname: $localize`:@@traveller.preferredfood:Prefered food`},
  {order: 30, fieldname: $localize`:@@traveller.smoker:Smoker`}
].forEach(entry => {
  const options = allGenericFieldOrderOptions['general.header_prefs'].travellerOptions;
  options.push(new NgOption(entry.order - 1, $localize`:@@generic.fieldorder.general.before:Before` + ' ' + entry.fieldname));
  options.push(new NgOption(entry.order + 1, $localize`:@@generic.fieldorder.general.after:After` + ' ' + entry.fieldname));
});

[
  {order: 30, fieldname: $localize`:@@company.externalnr:Customer no.`},
  {order: 120, fieldname: $localize`:@@company.fax:Fax`}
].forEach(entry => {
  const options = allGenericFieldOrderOptions['general.header_general'].companyOptions;
  options.push(new NgOption(entry.order - 1, $localize`:@@generic.fieldorder.general.before:Before` + ' ' + entry.fieldname));
  options.push(new NgOption(entry.order + 1, $localize`:@@generic.fieldorder.general.after:After` + ' ' + entry.fieldname));
});
