import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Administrator, AdministratorContext } from '@shared/models/administrator';
import { AdministratorSearchResponse } from '@shared/models/administrator-search-result';
import { FormUtils } from '@shared/form-utils.class';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

  constructor(private http: HttpClient) {
  }

  search(params: {
    q: string;
    page?: number;
    pageSize?: number
  }): Observable<AdministratorSearchResponse> {
    let httpParams = new HttpParams()//
      .set('q', params.q)
      .set('pageSize', params.pageSize || 25);
    if (params.page !== undefined) {
      httpParams = httpParams.set('page', params.page);
    }
    return this.http.get<AdministratorSearchResponse>(`${environment.apiBaseUrl}/api/v1/supporters`, {params: httpParams});
  }

  read(uuid: string): Observable<Administrator> {
    return this.http.get<Administrator>(`${environment.apiBaseUrl}/api/v1/supporter/${uuid}`);
  }

  readContext(uuid: string): Observable<AdministratorContext> {
    return this.http.get<AdministratorContext>(`${environment.apiBaseUrl}/api/v1/supporter/${uuid}/context`);
  }

  getNew(): Observable<Administrator> {
    return this.http.get<Administrator>(`${environment.apiBaseUrl}/api/v1/supporter/new`);
  }

  save(uuid: string | undefined, request: Administrator): Observable<Administrator> {
    this.prepareRequestForApi(request);

    if (uuid) {
      return this.http.patch<Administrator>(`${environment.apiBaseUrl}/api/v1/supporter/${uuid}`, request);
    }
    return this.http.post<Administrator>(`${environment.apiBaseUrl}/api/v1/supporter`, request);
  }

  delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/supporter/${uuid}`);
  }

  private prepareRequestForApi(request: Administrator): void {
    // This API does NOT use partial update for now, hence do some cleanup as needed
    request.managedCompanies = FormUtils.removeIf(request.managedCompanies, comp => !comp.uuid);
    request.additionalManagedAgencies = FormUtils.removeIf(request.additionalManagedAgencies, agency => !agency.uuid);
  }
}
