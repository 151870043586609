import { environment } from '@environments/environment';

export class URLUtils {

  static buildAbsoluteUrl(url: string): URL {
    if (/^https?:\/\//i.test(url)) {
      // Already seems to be an absolute URL
      return new URL(url);
    }

    let base = document.baseURI;
    if (environment.contextPerLanguage) {
      // Expect baseURI to be something like https://ui.umbrella.ch/some/path/en-GB
      if (base.endsWith('/')) {
        base = base.substring(0, base.length - 1);
      }

      base = base.substring(0, base.lastIndexOf('/') + 1);
    }

    if (url.startsWith('/')) {
      url = url.substring(1);
    }

    return new URL(url, base);
  }

}
