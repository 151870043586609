import { AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CircleBackendService, UserGroupConfigurations } from '@services/circle-backend.service';
import { CompanyContextForTraveller } from '@shared/models/company-context-for-traveller';
import { TravellerFopOptionConstant, TravellerFopOptionEnum } from '@shared/models/types.enum';
import { ObjectUtils } from '@shared/object-utils.class';
import { Subscription } from 'rxjs';
import { setCompanyContextForTraveller } from 'src/app/feature-profiles/store-feature/profiles-actions';
import { selectCompanyContextForTraveller } from 'src/app/feature-profiles/store-feature/profiles-selectors';
import { FacesState } from 'src/app/store-root/faces-state';

export class CtmNoramTravellerUserGroupInterceptor {

  private companyContextForTraveller?: CompanyContextForTraveller;
  private companyContextForTraveller$: Subscription;
  private userGroupConfigurations: UserGroupConfigurations;
  private originalTravellerFopOption?: TravellerFopOptionEnum;

  private userGroupControlChanges$: Subscription;
  private previousUserGroup: string;

  constructor(private store: Store<FacesState>, private circleBackendService: CircleBackendService, userGroupControl: AbstractControl) {
    this.companyContextForTraveller$ = this.store.select(selectCompanyContextForTraveller)
      .subscribe(ctx => {
        if (this.companyContextForTraveller?.uuid !== ctx?.uuid) {
          this.originalTravellerFopOption = ctx?.referenceData?.travellerFopOption;
          this.companyContextForTraveller = ctx;
          this.fetchUserGroupConfig();
        }
      });

    this.userGroupControlChanges$ = userGroupControl.valueChanges
      .subscribe(val => this.userGroupChanged(val));
  }

  destroy(): void {
    this.companyContextForTraveller$.unsubscribe();
    this.userGroupControlChanges$.unsubscribe();
  }

  private fetchUserGroupConfig() {
    if (this.companyContextForTraveller?.uuid) {
      this.circleBackendService.getCtmNoramValidationRules(this.companyContextForTraveller?.uuid)
        .subscribe(cfg => this.userGroupConfigurations = cfg);
    } else {
      this.userGroupConfigurations = {
        userGroupConfiguration: []
      };
    }
  }

  private userGroupChanged(newValue: string) {
    // valueChanges are also triggered on reload, e.g. because of a change in CompanyContextForTraveller
    // so prevent endless loop here
    if (newValue !== this.previousUserGroup) {
      this.previousUserGroup = newValue;
      const preventPrivateCard = this.isPreventPrivateCard(newValue);
      if (preventPrivateCard === true) {
        if (this.companyContextForTraveller?.referenceData?.travellerFopOption !== TravellerFopOptionConstant.NONE) {
          this.updateCompanyContext(TravellerFopOptionConstant.NONE);
        } // else: context already prevents private cards, nothing to do
      } else if (this.originalTravellerFopOption) {
        // need to reset
        this.updateCompanyContext(this.originalTravellerFopOption);
      }
    }
  }

  private isPreventPrivateCard(groupKey: string): boolean | undefined {
    return this.userGroupConfigurations
      ?.userGroupConfiguration
      ?.find(cfg => cfg.groupId === groupKey)
      ?.prohibitPrivateCard;
  }

  private updateCompanyContext(fopOption: TravellerFopOptionEnum): void {
    const newCtx = ObjectUtils.deepClone(this.companyContextForTraveller);
    if (newCtx?.referenceData) {
      newCtx.referenceData.travellerFopOption = fopOption;
      this.store.dispatch(setCompanyContextForTraveller({ context: newCtx }));
    }

  }

}
