// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { environmentDefaults, FacesSpaEnvironment } from './environment-defaults';

// Allow for Selenium (AngularAdapter) to figure out which MacroTasks are still pending when a test aborts due to timeout
import 'zone.js/plugins/task-tracking';

export const environment: FacesSpaEnvironment = {
  ...environmentDefaults,
  serverUiBaseUrl: '',
  apiBaseUrl: '/uf-test',
  spaClientId: 'ec0c33e8-ae6c-11ec-b7dc-9b57410df649',
  spaClientSecret: '6df147f1-05b6-452b-bf0d-62cb459e30f0'
};

