import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { BaseCircleCustomization } from '../base-circle-customization.class';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum } from '@models/types.enum';

export class OevbCustomization extends BaseCircleCustomization {

  override getTransientStandardfieldDefinition(controlPath: string[], _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if ('email' == controlName) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.TRAVELLER,
        affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
        fieldDisplayType: FieldDisplayTypeConstant.OPTIONAL,
        label: ''
      };
    }
    return;
  }
}
