import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApplicationErrorLevel } from '@services/global-error-handler.service';
import { Subscription } from 'rxjs';
import { setError } from 'src/app/store-root/actions';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectLastError } from 'src/app/store-root/selectors';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  @Input() hideIfModalPresent = false;
  @Output() heightChanged: EventEmitter<number> = new EventEmitter<number>();

  errorLevel = ApplicationErrorLevel.None;
  errorMessage: string;
  errorDetails: string;

  get alertType(): string {
    if (this.errorLevel === ApplicationErrorLevel.Error) {
      return 'danger';
    }
    if (this.errorLevel === ApplicationErrorLevel.Warning) {
      return 'warning';
    }
    if (this.errorLevel === ApplicationErrorLevel.Info) {
      return 'success';
    }
    return '';
  }

  @ViewChild('facesAlert', { static: false }) facesAlert: NgbAlert;
  @ViewChild('errorContainer', { static: true }) errorContainer: ElementRef;

  private errorSubscription$: Subscription;
  private alertCloseTimeout: any | undefined;
  private resizeObserver?: ResizeObserver;

  constructor(private store: Store<FacesState>,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone) {
  }

  ngOnInit(): void {
    this.errorSubscription$ = this.store.select(selectLastError).subscribe(err => {
      if (this.alertCloseTimeout) {
        clearTimeout(this.alertCloseTimeout);
        this.alertCloseTimeout = undefined;
      }
      if (err) {
        this.errorLevel = err.errorLevel;
        this.errorMessage = err.message;
        this.errorDetails = err.technicalDetails || '';
      } else {
        this.errorLevel = ApplicationErrorLevel.None;
        this.errorDetails = '';
        this.errorMessage = '';
      }
      // note: a simple markForCheck() won't do the job here
      this.changeDetectorRef.detectChanges();
      if (this.errorLevel === ApplicationErrorLevel.Info) {
        this.zone.runOutsideAngular(() => {
          // running inside ng zone would trigger a ExpressionChangedAfterItHasBeenCheckedError
          this.alertCloseTimeout = setTimeout(() => this.facesAlert?.close(), 30000);
        });
      }
      if (this.errorLevel !== ApplicationErrorLevel.None) {
        this.errorContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
    if (this.hideIfModalPresent) {
      const el = document.querySelector('.error-container');
      if (el) {
        this.resizeObserver = new ResizeObserver(entries => {
          if (entries.length > 0) {
            const errorHeight = entries[0].contentRect.height;
            this.zone.run(() => {
              this.heightChanged.emit(errorHeight == 0 ? 0 : (errorHeight + environment.ngbAlertMargin));
            });
          }
        })
        this.resizeObserver.observe(el);
      }
    }
  }

  ngOnDestroy(): void {
    this.errorSubscription$.unsubscribe();
    this.resizeObserver?.disconnect();
  }

  alertDismissed(): void {
    this.store.dispatch(setError({
      error: undefined
    }));
  }
}
