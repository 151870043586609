import { Component, OnInit } from '@angular/core';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';
import { BtaFirstMyclimateContext } from './bta-first-myclimate-context.class';

@Component({
  template: `
  <div class="uf-btafirst-myclimate" [formGroup]="valueWithSetupFormGroup">
    <div>
      <select class="form-select" [attr.id]="controlId" formControlName="value" (change)="myclimateChanged()">
        <option value="">-</option>
        <option value="N">NO</option>
        <option value="Y">YES</option>
      </select>
    </div>
  </div>
`,
styles: [`
  :host {
    width: 100%;
  }
  div.uf-btafirst-myclimate {
    width: 100%;
  }
`]
})
export class BtaFirstMyclimateComponent extends BaseGenericValueWithSetupComponent implements OnInit {

  private context: BtaFirstMyclimateContext;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.context = BtaFirstMyclimateContext.instance;
    this.context.myclimateOptionChanged(this.getCurrentValue())
  }

  myclimateChanged(): void {
    this.context.myclimateOptionChanged(this.getCurrentValue())
    this.valueWithSetupFormGroup.get('field')?.markAsDirty();
  }

  private getCurrentValue(): boolean | undefined {
    const formValue = this.valueWithSetupFormGroup.get('value')?.value;
    if (formValue === 'Y') {
      return true;
    }
    if (formValue === 'N') {
      return false;
    }
    return;
  }



}
