import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { EMPTY, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectPrincipal } from 'src/app/store-root/selectors';
import { User } from '@shared/models/user.class';
import { AccessService } from './access.service';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { Router } from '@angular/router';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { SmartpointLocatorResponse } from '@shared/models/integration';

declare let facesRedAppFacade: any;
declare const ServiceCallback: any;
declare global {
  interface Window {
    doSearchFromSabre: any;
    doSearchFromSmartpoint: any;
  }
}

/**
 * This interface exposes methods of the Galileo Smartpoint container.
 */
declare interface SmartpointAdapter {
  ShowProfile(locator: string): void;
  CloseWindow(): void;
  RegisterLogout(): void;
}
export interface SmartpointIntegration {
  init(): void;
  getTravelerLocator(profile?: TravellerDetailResponse): Observable<string>;
  getCompanyLocator(profile?: CompanyDetailResponse): Observable<string>;
  showProfile(locator: string): void;
  closeWindow(): void;
  registerLogout(): void;
  destroy(): void;
}

/**
 * This interface exposes methods of the Sabre Red app container.
 */
export interface SabreRedIntegration {
  init(): void;
  getSecurityToken(): string;
  redisplayPnr(): void;
  destroy(): void;
}

class SmartpointIntegrationImpl implements SmartpointIntegration {

  private get adapter(): SmartpointAdapter {
    return (window.external as unknown) as SmartpointAdapter;
  }

  constructor(private httpclient: HttpClient, private router: Router, private ngZone: NgZone) { }

  init(): void {
    // The Galileo Smartpoint integration expects a global JS function doSearchFromSmartpoint(profileType, query).
    window.doSearchFromSmartpoint = (profileType: string, query: string) => { this.ngZone.run(() => this.executeSearchFromSmartpoint(profileType, query)) };
  }

  executeSearchFromSmartpoint(profileType: string, query: string): any {
    if (profileType === 'C') {
      this.router.navigate(['profiles/company/search'], {queryParams: {q: query}});
    } else {
      this.router.navigate(['profiles/traveller/search'], {queryParams: {q: query}});
    }
  }

  getTravelerLocator(profile?: TravellerDetailResponse): Observable<string> {
    if (profile?.uuid) {
      return this.httpclient.get<SmartpointLocatorResponse>(`${environment.apiBaseUrl}/api/v1/smartpoint/TRAVELLER/${profile?.uuid}/locator.json`)
        .pipe(map(r => r.locator));
    }
    return EMPTY;
  }

  getCompanyLocator(profile?: CompanyDetailResponse): Observable<string> {
    if (profile?.uuid) {
      return this.httpclient.get<SmartpointLocatorResponse>(`${environment.apiBaseUrl}/api/v1/smartpoint/CORPORATE/${profile?.uuid}/locator.json`)
        .pipe(map(r => r.locator));
    }
    return EMPTY;
  }

  showProfile(locator: string): void {
    this.adapter.ShowProfile(locator);
  }

  closeWindow(): void {
    this.adapter.CloseWindow();
  }

  registerLogout(): void {
    this.adapter.RegisterLogout();
  }

  destroy(): void {
    window.doSearchFromSmartpoint = undefined;
  }

}

class SabreRedIntegrationImpl implements SabreRedIntegration {

  constructor(private router: Router, private ngZone: NgZone) { }

  init(): void {
    // The Faces Sabre Red app expects a global JS function doSearchFromSabre(), linked to "UF*" via redapp.xml.
    window.doSearchFromSabre = (sabreServiceContextJson: any) => { this.ngZone.run(() => this.executeSearchFromSabre(sabreServiceContextJson)) };
  }

  private executeSearchFromSabre(sabreServiceContextJson: any) {
    // command will be 'UF*MUSTERMANN'
    const serviceContext = sabreServiceContextJson["com.sabre.edge.cf.model.element.ServiceContext"];
    const command = serviceContext.request.emulatorCommand.command;
    this.router.navigate(['profiles/traveller/search'], {queryParams: {q: command.substring(3)}}).then(()=>{
      // adapt ServiceContext so request is NOT sent to host
      const modifiedCommand: any = {};
      modifiedCommand["@class"] = "com.sabre.edge.cf.emu.data.EmulatorCommand";
      const response: any = {};
      response["@class"] = "com.sabre.edge.cf.emu.data.responses.EmulatorCommandResponse";
      response.emulatorCommand = modifiedCommand;
      serviceContext.response = response;
      ServiceCallback.callback(JSON.stringify(sabreServiceContextJson));
    });
  }

  getSecurityToken(): string {
    if (typeof facesRedAppFacade !== 'undefined') {
      const secToken = facesRedAppFacade.getSecurityToken();
      if (!secToken) {
        throw new Error("No current session in Sabre work area");
      }

      if (secToken.indexOf("ERROR:") === 0) {
          throw new Error(secToken.substring(6));
      }
      return secToken;
    }
    throw new Error("Not running within Sabre Red");
  }

  redisplayPnr(): void {
    try {
      facesRedAppFacade.issueRedisplay();
    } catch (e) {
        // well, we tried
    }
  }

  destroy(): void {
    window.doSearchFromSabre = undefined;
  }

}

@Injectable({
  providedIn: 'root'
})
export class IntegrationService implements OnDestroy {

  get sabreRed(): SabreRedIntegration {
    return this.sabreRedIntegration;
  }

  get smartpoint(): SmartpointIntegration {
    return this.smartpointAdapter;
  }

  private smartpointAdapter: SmartpointIntegration;
  private sabreRedIntegration: SabreRedIntegration;

  constructor(private store: Store<FacesState>,
      httpclient: HttpClient,
      ngZone: NgZone,
      router: Router) {
    this.smartpointAdapter = new SmartpointIntegrationImpl(httpclient, router, ngZone);
    this.sabreRedIntegration = new SabreRedIntegrationImpl(router, ngZone);
    this.store.select(selectPrincipal)
      .subscribe(user => this.updateIntegration(user));
  }

  ngOnDestroy(): void {
    this.smartpointAdapter.destroy();
    this.sabreRedIntegration.destroy();
  }

  private updateIntegration(principal?: User): void {
    if (AccessService.hasAnyRole(principal, 'EMBED_SABRERED')) {
      this.sabreRedIntegration.init();
    }
    if (AccessService.hasAnyRole(principal, 'EMBED_SMARTPOINT')) {
      this.smartpointAdapter.init();
    }
  }



}
