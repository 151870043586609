import { LogService } from '@services/log-service.interface';
import { Subscriber } from 'rxjs';
import { StyleUtils } from '@shared/style-utils.class';
import { CreditCardTypeEnum } from '@models/types.enum';

export interface TokenizationTransactionParameters {
  parameters: { [key: string]: string; }
}

export interface TokenizationParameters {
  expectedCardType?: CreditCardTypeEnum
  containers: {
    cardNumber?: string;
    cvv?: string;
  }
}

export interface FieldAndStyleConfiguration {
  cardNumber?: string;
  cvv?: string;
}

export abstract class TokenizationProvider {

  private inlineObjectSubmitting = false;

  // These are merely used for caching a reference and will be populated during startCreditCardTokenization
  private numberContainer?: Element;
  private cvvContainer?: Element;

  private iFrameFocusHelper = (event: FocusEvent) => {
    if (document.activeElement?.tagName.toLowerCase() !== 'iframe') {
      this.focusIframe(event.currentTarget as Element);
    }
  };

  protected constructor(protected log: LogService, private subscriber: Subscriber<TokenizationTransactionParameters>, protected merchantId: string, private params: TokenizationParameters) {
  }

  startCreditCardTokenization(): void {
    const fields: FieldAndStyleConfiguration = {};
    const styles: FieldAndStyleConfiguration = {};
    let numberConfiguredFields = 0;
    let focus = '';

    if (this.params.containers.cvv) {
      this.cvvContainer = this.getNativeElement('CVV', this.params.containers.cvv);
      this.cvvContainer.parentElement?.addEventListener('focus', this.iFrameFocusHelper);

      numberConfiguredFields++;
      focus = 'cvv';
      fields.cvv = this.params.containers.cvv;
      styles.cvv = `font-size: ${StyleUtils.getFontSize(this.cvvContainer)}`;
    }

    if (this.params.containers.cardNumber) {
      const number = this.getNativeElement('number', this.params.containers.cardNumber);
      if (!number.classList.contains('disabled')) {
        this.numberContainer = number;
        this.numberContainer.parentElement?.addEventListener('focus', this.iFrameFocusHelper);

        numberConfiguredFields++;
        focus = 'cardNumber';

        fields.cardNumber = this.params.containers.cardNumber;
        styles.cardNumber = `font-size: ${StyleUtils.getFontSize(this.numberContainer)}`;
      }
    }

    if (numberConfiguredFields > 0) {
      this.log.debug(`About to init tokenization with fields:${JSON.stringify(fields)}, styles:${JSON.stringify(styles)}, focus=${focus}`, 'TokenizationProvider');
      this.initTokenize(fields, styles, numberConfiguredFields > 1 ? focus : undefined);
    }
  }

  destroy(): void {
    this.log.debug('destroy called, releasing any resources still present', 'TokenizationProvider');
    this.cleanUpTokenizerDom();
  }

  protected abstract initTokenize(fields: FieldAndStyleConfiguration, styles: FieldAndStyleConfiguration, focus?: string): void;

  protected abstract submitTokenizerFrame(): void;

  protected onValidate(event: any): void {
    this.log.debug(`onValidate called with event ${JSON.stringify(event)}`, 'TokenizationProvider');

    if (event.hasErrors) {
      let emitError = true;

      /*
       * We may be tokenizing cardNumber + cvv at the same time or only CVV (alone without card number)
       *
       *  - If both are entered, the cardNumber MUST be correctly entered
       *  - Otherwise event.fields.cardNumber is undefined, so we'll expect to find only event.fields.cvv
       */
      if (event.fields.cardNumber) {
        if (event.fields.cardNumber.length === 0) {
          // Seems like user aborted the entry of a full card number
          emitError = false;
          this.collectCreditCardToken();
        }
      } else if (event.fields.cvv && event.fields.cvv.length === 0) {
        // Seems like user aborted the entry of a CVV (only)
        emitError = false;
        this.collectCreditCardToken();
      }

      if (emitError) {
        this.subscriber.error($localize`:@@error.creditcardtype_invalid:The creditcard number is invalid. Did you mistype a digit?`);
        this.inlineObjectSubmitting = false;
      }
    }
  }

  protected onError(message: string): void {
    this.subscriber.error(`CreditCard entry failed with error: ${message}`);
    this.inlineObjectSubmitting = false;
  }

  protected onChange(event: any): void {
    if (event?.event?.type === 'blur') {
      this.log.debug(`Received inline onChange (blur) event ${JSON.stringify(event)}`, 'TokenizationProvider');

      // Attempt to move focus to CVV frame when exiting number
      const activeElement = this.determineNextActiveElementOnBlur();
      if (activeElement) {
        this.focusIframe(activeElement);
      } else {
        this.submitAndCollectToken();
      }
    }
  }

  protected onReady(): void {
    this.log.info(`Inline tokenizer ${this.constructor.name} is ready`, 'TokenizationProvider');

    // Focus iframe but only if it does not seem to be already focussed
    if (document.activeElement?.tagName.toLowerCase() !== 'iframe') {
      this.focusTokenizerIframe();
    }
  }

  protected onSuccess(transactionId: string, additionalParameters?: { [key: string]: string; }): void {
    this.log.info(`Inline tokenization success, transactionId=${transactionId}`, 'TokenizationProvider');
    this.collectCreditCardToken(transactionId, additionalParameters);
  }

  protected cleanUpTokenizerDom(): void {
    this.numberContainer?.parentElement?.removeEventListener('focus', this.iFrameFocusHelper);
    this.removeOrphanIframe(this.numberContainer);

    this.cvvContainer?.parentElement?.removeEventListener('focus', this.iFrameFocusHelper);
    this.removeOrphanIframe(this.cvvContainer);
  }

  private submitAndCollectToken(): void {
    if (this.inlineObjectSubmitting) {
      return;
    }

    this.inlineObjectSubmitting = true;
    this.submitTokenizerFrame();
  }

  private determineNextActiveElementOnBlur(): Element|null {
    let activeElement = document.activeElement;
    if (activeElement?.tagName?.toLowerCase() === 'iframe') {
      activeElement = activeElement.parentElement;
    }

    if (activeElement && this.params.containers.cvv) {
      const tokenizerFrame = activeElement.querySelector('iframe');
      if (tokenizerFrame?.parentElement) {
        const activeId = tokenizerFrame.parentElement.getAttribute('id');
        this.log.debug(`Focus appears to be on tokenizer container with id '${activeId}'`, 'TokenizationProvider');

        if (this.params.containers.cardNumber === activeId) {
          this.log.debug('CC number field has been left, shifting focus to CVV entry', 'TokenizationProvider');
          return document.getElementById(this.params.containers.cvv);
        } else if (this.params.containers.cvv === activeId) {
          return activeElement;
        }
      }
    }

    return null;
  }

  private getNativeElement(type: string, id: string): Element {
    const targetElement = document.getElementById(id);
    if (!targetElement) {
      throw new Error(`Could not find target element for ${type} entry: ${id}`)
    }

    return targetElement;
  }

  private collectCreditCardToken(transactionId?: string, additionalParameters?: { [key: string]: string; }): void {
    this.inlineObjectSubmitting = false;

    const hasTransactionId = transactionId && transactionId.length;
    if (hasTransactionId || additionalParameters) {
      let params: { [key: string]: string } = {};
      if (hasTransactionId) {
        params['transactionId'] = transactionId;
      }
      if (additionalParameters) {
        params = {...params, ...additionalParameters};
      }

      this.subscriber.next({ parameters: params });
      this.subscriber.complete();
      this.cleanUpTokenizerDom();
    }
  }

  private focusTokenizerIframe(): void {
    if (!this.focusIframe(this.numberContainer)) {
      this.focusIframe(this.cvvContainer);
    }
  }

  private focusIframe(container?: Element): boolean {
    if (container) {
      const iframe = container.querySelector('iframe');
      if (iframe) {
        iframe.focus();
        return true;
      }
    }

    return false;
  }

  private removeOrphanIframe(container?: Element): void {
    container?.querySelector('iframe')?.remove();
  }
}
