import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import {
  AdditionalFormOfPaymentConstant,
  AirSeatConstant,
  CreditCardTypeConstant,
  EmailDeliveryConstant,
  FeatureRailConstant,
  FeatureRailEnum,
  RailMembershipClassConstant,
  RailPreferencesSeatConstant,
  RailPreferencesTicketDeliveryConstant,
  RailPreferencesWagonExtraTypeConstant,
  RailPreferencesWagonTypeConstant,
  ResidentAreaConstant,
  ResidentCardTypeConstant,
  SexTypeConstant,
  SpecialEquipmentConstant,
  UserInterfaceColorTypeConstant,
  VisaExportTypeEnumConstant
} from '@shared/models/types.enum';
import { CodedReferenceDefinition, HotelChainCodedReferenceDefinition, RailCardTypeReferenceDefinition } from '@shared/models/coded-reference-definition';
import { NgOption, Option, OptionWithPublishTargetCompatibility, TypedOption } from '@shared/models/option';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectCompanyContextForTraveller } from 'src/app/feature-profiles/store-feature/profiles-selectors';
import { SupportAccessByFunction } from '@shared/models/support-access';
import { PublishTarget } from '@shared/models/publish-target';
import { FeatureFormConfiguration } from '@shared/models/feature';
import { selectPrincipal } from '../../store-root/selectors';
import { User } from '@models/user.class';
import { AccessService } from '@services/access.service';
import { allGenericFieldOrderOptions, GenericFieldOrderGroupOptions } from '@shared/models/generic-field-order-options';
import { CircleSpecificFieldDefinition } from '@models/agency';
import { CircleCustomizationService } from './circle-customization.service';

/**
 * This service provides values for dropdown lists. If the list is constant for all circles,
 * the array is initialized once and then served. If the list is circle-specific, the array
 * is created on each get.
 */
@Injectable({
  providedIn: 'root'
})
export class SelectionService implements OnDestroy {

  get sexOptions(): Option[] {
    return this._sexOptions;
  }

  get seatPreferences(): Option[] {
    return this._seatPreferences;
  }

  get mealPreferences(): Observable<Option[]> {
    // can only init mealPreference subscription once 'profiles' feature is loaded in store
    this.initMealPreferences();
    return this._mealPreferences.asObservable();
  }

  get airlinesForTravellers(): Option[] {
    return this._airlinesTravellers;
  }

  get airlinesForCompanies(): Option[] {
    return this._airlinesCompanies;
  }

  get hotelChains(): OptionWithPublishTargetCompatibility[] {
    return this._hotelChains;
  }

  get rentalCarCompanies(): Option[] {
    return this._rentalCarCompanies;
  }

  get rentalCarEquipment(): Option[] {
    return this._rentalCarEquipment;
  }

  /**
   * Get all non-BASIC circles. The caller must decide whether to offer a default (null)
   * option or add the BASIC circle.
   */
  get circles(): NgOption[] {
    return this._circles;
  }

  get publishTargets(): PublishTarget[] {
    return this._publishTargets;
  }

  get emailDeliveries(): Option[] {
    return this._emailDeliveries;
  }

  get languages(): Option[] {
    return this._languages;
  }

  get visaEntryTypes(): Option[] {
    return this._visaEntryTypes;
  }

  get creditCardTypes(): Observable<Option[]> {
    return this._creditCardTypes.asObservable();
  }

  get additionalFormOfPaymentTypes(): Observable<Option[]> {
    return this._addFopTypes.asObservable();
  }

  get airlinePublishtypes(): Option[] {
    return this._airlinePublishtypes;
  }

  get railMembershipClasses(): Option[] {
    return this._railMembershipClasses;
  }

  get railTicketDeliveries(): Option[] {
    return this._railTicketDeliveries;
  }

  get railWagonTypes(): Option[] {
    return this._railWagonTypes;
  }

  get railWagonExtraTypes(): Option[] {
    return this._railWagonExtraTypes;
  }

  get railSeatPreferences(): Option[] {
    return this._railSeatPreferences;
  }

  get residenceAreas(): Option[] {
    return this._residenceAreas;
  }

  get residenceAreacodes(): Option[] {
    return this._residenceAreacodes;
  }

  get residenceCardtypes(): Option[] {
    return this._residenceCardtypes;
  }

  get colorTypes(): Option[] {
    return this._colorTypes;
  }

  /**
   * This is a state useful for change detection. This service updates the version everytime a callback returns,
   * so "version" can be used in a template to for a re-evaluation
   */
  get version(): Date {
    return this._version;
  }

  railCardTypes(featureRail: FeatureRailEnum): TypedOption<RailCardTypeReferenceDefinition>[] {
    const railCardTypes = this._railCardTypes.get(featureRail);
    if (railCardTypes) {
      return railCardTypes;
    } else {
      return [];
    }
  }

  private _sexOptions: Option[];
  private _seatPreferences: Option[];
  private _mealPreferences: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  private mealPreferences$: Subscription;
  private _airlinesTravellers: Option[];
  private _airlinesCompanies: Option[];
  private _hotelChains: OptionWithPublishTargetCompatibility[];
  private _rentalCarCompanies: Option[];
  private _rentalCarEquipment: Option[];
  private _emailDeliveries: Option[];
  private _circles: NgOption[];
  private _publishTargets: PublishTarget[] = [];
  private _languages: Option[];
  private _visaEntryTypes: Option[];
  private allCreditCardTypes: Option[];
  private _creditCardTypes: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  private allAddFopTypes: Option[];
  private _addFopTypes: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
  private _airlinePublishtypes: Option[];
  private principal$: Subscription;
  private _railMembershipClasses: Option[];
  private _railCardTypes: Map<FeatureRailEnum, TypedOption<RailCardTypeReferenceDefinition>[]> = new Map;
  private _railTicketDeliveries: Option[];
  private _railWagonTypes: Option[];
  private _railWagonExtraTypes: Option[];
  private _railSeatPreferences: Option[];
  private _residenceAreas: Option[];
  private _residenceAreacodes: Option[];
  private _residenceCardtypes: Option[];
  private _fieldOrderOptions = allGenericFieldOrderOptions;
  private _colorTypes: Option[];
  private _version = new Date();

  constructor(private http: HttpClient,
              private circleCustomizationService: CircleCustomizationService,
              private store: Store<FacesState>) {

    // any method with a call to circleCustomizationService needs to be principal-specific
    this.principal$ = this.store.select(selectPrincipal).subscribe(user => {
      this.initSexOptions();
      this.initAirlines();
      this.initHotelChains();
      this.initRentalCarProviders();
      this.initCreditCardTypes();
      this.initAddFopTypes();
      this.initRailCardTypes();
      this.initRailWagonType();
      this.initAirlinePublishTypes();
      this.initEmailDeliveries();

      // TODO: remove this to circle customizations
      this.updateCreditCardTypes(user);
      this.updateAddFopTypes(user);
    });

    // trigger HTTP calls first
    this.initCircles();
    this.initPublishTargets();
    this.initLanguages();

    // then build remaining lists
    this.initRentalCarEquipment();
    this.initSeatPreferences();
    this.initVisaEntryTypes();

    this.initRailMembershipClasses();
    this.initRailTicketDelivery();
    this.initRailWagonExtraType();
    this.initRailSeatPreference();
    this.initSpanishResidenceOptions();
    this.initColorTypes();
  }

  ngOnDestroy(): void {
    if (this.mealPreferences$) {
      this.mealPreferences$.unsubscribe();
    }
    if (this.principal$) {
      this.principal$.unsubscribe();
    }
  }

  circleChains(circle: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiBaseUrl}/api/v1/reference-data/circle/${circle}/chains`);
  }

  circleSpecificAgencyValues(circle: string): Observable<CircleSpecificFieldDefinition[]> {
    return this.http.get<CircleSpecificFieldDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/circle/${circle}/agency-values`);
  }

  supportAccess(): Observable<SupportAccessByFunction[]> {
    return this.http.get<SupportAccessByFunction[]>(`${environment.apiBaseUrl}/api/v1/reference-data/supportaccess`)
  }

  agencyFeatureToggles(): Observable<FeatureFormConfiguration[]> {
    return this.http.get<FeatureFormConfiguration[]>(`${environment.apiBaseUrl}/api/v1/reference-data/systemfeatures`)
  }

  travelGroupRoles(travellerUuid?: string): Observable<CodedReferenceDefinition[]> {
    if (travellerUuid) {
      return this.http.get<CodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/${travellerUuid}/travelgrouproles`)
    }
    return this.http.get<CodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/travelgrouproles`)
  }

  getFieldOrderOptions(groupKey: string): GenericFieldOrderGroupOptions {
    return this._fieldOrderOptions[groupKey] || {
      travellerOptions: [],
      companyOptions: []
    };
  }

  states(countryCode: string) : Observable<CodedReferenceDefinition[]> {
    return this.http.get<CodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/states/${countryCode}`)
  }

  private initSexOptions(): void {
    this._sexOptions = [];
    this._sexOptions.push(new Option(SexTypeConstant.UNDEF, $localize`:@@general.code.u:Undisclosed`));
    this._sexOptions.push(new Option(SexTypeConstant.FEMALE, $localize`:@@general.code.f:Female`));
    this._sexOptions.push(new Option(SexTypeConstant.MALE, $localize`:@@general.code.m:Male`));
    this._sexOptions.push(new Option(SexTypeConstant.NON_BINARY, $localize`:@@general.code.x:Unspecified/Others`));
    this.circleCustomizationService.customizeSexOptions(this._sexOptions);
  }

  private initSeatPreferences(): void {
    this._seatPreferences = [];
    this._seatPreferences.push(new Option(AirSeatConstant.A, $localize`:@@general.code.aisle:Aisle`));
    this._seatPreferences.push(new Option(AirSeatConstant.W, $localize`:@@general.code.window:Window`));
    this._seatPreferences.sort(this.compareOptionsByLabel);
    this._seatPreferences.splice(0, 0, new Option('', '-'));
  }

  private initMealPreferences(): void {
    if (!this.mealPreferences$) {
      this.mealPreferences$ = this.store.select(selectCompanyContextForTraveller).subscribe(context => {
        let newMealPreferences: Option[];
        if (context?.referenceData?.foodPreferences) {
          newMealPreferences = context.referenceData.foodPreferences.map(opt => new Option(opt.code, opt.name));
          newMealPreferences.splice(0, 0, new Option('', '-'));
        } else {
          newMealPreferences = [new Option('', '-')];
        }
        this._mealPreferences.next(newMealPreferences);
      });
    }
  }

  private initAirlinePublishTypes(): void {
    this._airlinePublishtypes = [];
    this._airlinePublishtypes.push(new Option('FREETEXT', 'other service (OS)'));
    this._airlinePublishtypes.push(new Option('SPECIAL_KEYWORD', 'special keyword (SK)'));
    this._airlinePublishtypes.push(new Option('LOYALTY_ID', 'SRCLID'));
    this.circleCustomizationService.customizeAirlinePublishtypes(this._airlinePublishtypes);
  }

  private initAirlines(): void {
    let httpParams = new HttpParams()
      .set('profileType', 'TRAVELLER')
      .set('useAllianceName', true);
    this.http.get<CodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/air-providers`, {params: httpParams})
      .subscribe(data => {
        this._airlinesTravellers = data
          .map(def => new Option(def.code, def.name));
        this._airlinesTravellers.sort(this.compareOptionsByLabel);
        this._airlinesTravellers.splice(0, 0, new Option('', '-'));
        this.circleCustomizationService.customizeAirlineListForTravellers(this._airlinesTravellers);
        this.incrementVersion();
      });
    httpParams = new HttpParams()
      .set('profileType', 'CORPORATE');
    this.http.get<CodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/air-providers`, {params: httpParams})
      .subscribe(data => {
        this._airlinesCompanies = data
          .map(def => new Option(def.code, def.name));
        this._airlinesCompanies.sort(this.compareOptionsByLabel);
        this._airlinesCompanies.splice(0, 0, new Option('', '-'), new Option('**', $localize`:@@general.label.applytoall:- all -`));
        this.circleCustomizationService.customizeAirlineListForCompanies(this._airlinesCompanies);
        this.incrementVersion();
      });
  }

  private initHotelChains(): void {
    this.http.get<HotelChainCodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/hotel-chains`)
      .subscribe(data => {
        this._hotelChains = data
          .map(def => new OptionWithPublishTargetCompatibility(this.getHotelCsxCode(def.code), def.name, def.compatiblePublishTargets));
        this._hotelChains.splice(0, 0, new OptionWithPublishTargetCompatibility('', '-', []));
        this._hotelChains.splice(1, 0, new OptionWithPublishTargetCompatibility('**', $localize`:@@general.label.applytoall:- all -`, []));
        this.circleCustomizationService.customizeHotelList(this._hotelChains);
        this.incrementVersion();
      });
  }

  private initRentalCarProviders(): void {
    this.http.get<CodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/car-providers`)
      .subscribe(data => {
        this._rentalCarCompanies = data
          .map(def => new Option(def.code, def.name));
        this._rentalCarCompanies.splice(0, 0, new Option('', '-'));
        this._rentalCarCompanies.splice(1, 0, new Option('**', $localize`:@@general.label.applytoall:- all -`));
        this.circleCustomizationService.customizeRentalCarList(this._rentalCarCompanies);
        this.incrementVersion();
      });
  }

  private initRentalCarEquipment(): void {
    this._rentalCarEquipment = [];
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.ANTI_BLOCKING_SYSTEM, $localize`:@@general.code.specialequipment_ABS:ANTI_BLOCKING_SYSTEM`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.AIR_CONDITIONING, $localize`:@@general.code.specialequipment_AIR:AIR_CONDITIONING`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.ANTI_THEFT_DEVICE, $localize`:@@general.code.specialequipment_ATD:ANTI_THEFT_DEVICE`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.BICYCLE_RACK, $localize`:@@general.code.specialequipment_BYC:BICYCLE_RACK`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CAR_ALARM, $localize`:@@general.code.specialequipment_CAL:CAR_ALARM`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CAMCORDER, $localize`:@@general.code.specialequipment_CAM:CAMCORDER`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CITIZENS_BAND_RADIO, $localize`:@@general.code.specialequipment_CBR:CITIZENS_BAND_RADIO`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.COMPUTERIZED_DRIVING_DIRECT, $localize`:@@general.code.specialequipment_CCD:COMPUTERIZED_DRIVING_DIRECT`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CENTRAL_LOCKING_SYSTEM, $localize`:@@general.code.specialequipment_CLS:CENTRAL_LOCKING_SYSTEM`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CHILD_SEAT_FOR_BABY, $localize`:@@general.code.specialequipment_CSB:CHILD_SEAT_FOR_BABY`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CHILD_SEAT_FOR_INFANT, $localize`:@@general.code.specialequipment_CSI:CHILD_SEAT_FOR_INFANT`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CHILD_SEAT_FOR_TODDLER, $localize`:@@general.code.specialequipment_CST:CHILD_SEAT_FOR_TODDLER`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.DRIVER_SIDE_ARIBAG, $localize`:@@general.code.specialequipment_DAB:DRIVER_SIDE_ARIBAG`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.FM_RADIO, $localize`:@@general.code.specialequipment_FMR:FM_RADIO`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.FRONT_WHEEL_DRIVE, $localize`:@@general.code.specialequipment_FRT:FRONT_WHEEL_DRIVE`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.HATCHBACK_CAR, $localize`:@@general.code.specialequipment_HCH:HATCHBACK_CAR`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.HAND_CONTROLS_ON_LEFT, $localize`:@@general.code.specialequipment_HCL:HAND_CONTROLS_ON_LEFT`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.HAND_CONTROLS_ON_RIGHT, $localize`:@@general.code.specialequipment_HCR:HAND_CONTROLS_ON_RIGHT`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.INTEGRATED_CHILD_SAFETY_SEAT, $localize`:@@general.code.specialequipment_ICS:INTEGRATED_CHILD_SAFETY_SEAT`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.LASERDISC_PLAYER, $localize`:@@general.code.specialequipment_LDP:LASERDISC_PLAYER`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.LEFT_FOOT_ACCELERATOR, $localize`:@@general.code.specialequipment_LFA:LEFT_FOOT_ACCELERATOR`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.LEAD_FREE_FUEL, $localize`:@@general.code.specialequipment_LFF:LEAD_FREE_FUEL`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.NON_SMOKER_CAR, $localize`:@@general.code.specialequipment_NSK:NON_SMOKER_CAR`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.NAVIGATIONAL_SYSTEM_PLUS_PHONE, $localize`:@@general.code.specialequipment_NVP:NAVIGATIONAL_SYSTEM_PLUS_PHONE`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.NAVIGATIONAL_SYSTEM, $localize`:@@general.code.specialequipment_NVS:NAVIGATIONAL_SYSTEM`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.PASSENGER_SIDE_AIRBAG, $localize`:@@general.code.specialequipment_PAB:PASSENGER_SIDE_AIRBAG`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.POWER_ASSISTED_STEERING, $localize`:@@general.code.specialequipment_PAS:POWER_ASSISTED_STEERING`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.NUMBER_OF_PASSENGERS, $localize`:@@general.code.specialequipment_PAX:NUMBER_OF_PASSENGERS`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.POWER_DOOR_LOCKS, $localize`:@@general.code.specialequipment_PDL:POWER_DOOR_LOCKS`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.MOBILE_PHONE, $localize`:@@general.code.specialequipment_PHN:MOBILE_PHONE`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.POWER_WINDOWS, $localize`:@@general.code.specialequipment_PWS:POWER_WINDOWS`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.RADIO, $localize`:@@general.code.specialequipment_RAD:RADIO`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.REAR_SEAT_BELTS, $localize`:@@general.code.specialequipment_RSB:REAR_SEAT_BELTS`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.SEAT_BELT_EXTENDERS, $localize`:@@general.code.specialequipment_SBE:SEAT_BELT_EXTENDERS`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.SIX_PASSENGER_CAR, $localize`:@@general.code.specialequipment_SIX:SIX_PASSENGER_CAR`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.SKI_RENTAL, $localize`:@@general.code.specialequipment_SKI:SKI_RENTAL`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.SKI_RACK, $localize`:@@general.code.specialequipment_SKR:SKI_RACK`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.SKI_EQUIPPED_VEHICLE, $localize`:@@general.code.specialequipment_SKV:SKI_EQUIPPED_VEHICLE`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.SNOW_CHAINS, $localize`:@@general.code.specialequipment_SNO:SNOW_CHAINS`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.WINTER_TYRES, $localize`:@@general.code.specialequipment_STR:WINTER_TYRES`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.SUN_ROOF, $localize`:@@general.code.specialequipment_SUR:SUN_ROOF`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CASSETTE_TAPE, $localize`:@@general.code.specialequipment_TAP:CASSETTE_TAPE`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.CAR_TELEPHONE, $localize`:@@general.code.specialequipment_TEL:CAR_TELEPHONE`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.TRAILER_HITCH, $localize`:@@general.code.specialequipment_TRH:TRAILER_HITCH`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.TILT_STEERING_WHEEL, $localize`:@@general.code.specialequipment_TSW:TILT_STEERING_WHEEL`));
    this._rentalCarEquipment.push(new Option(SpecialEquipmentConstant.TELEVISION, $localize`:@@general.code.specialequipment_TVI:TELEVISION`));
    this._rentalCarEquipment.sort(this.compareOptionsByLabel);
    this._rentalCarEquipment.splice(0, 0, new Option('', '-'));
  }

  /**
   * Option keys will have format <default code>[,<target ordinal>=<target code>],
   * whereas the profile is returned with CSX-codes from the API. This method transforms
   * 'EH,9=HL' to 'EH'.
   */
  private getHotelCsxCode(compoundCode: string): string {
    if (!compoundCode || compoundCode.indexOf(',') < 0) {
      return compoundCode;
    }
    const codes = compoundCode.split(/,/);
    return codes.find(c => c.startsWith('0=')) || codes[0];
  }

  private initCircles(): void {
    this.http.get<{ [key: string]: string }>(`${environment.apiBaseUrl}/api/v1/reference-data/circles`, {})
      .subscribe(data => {
        this._circles = Object.keys(data)
          .map(k => new NgOption(k, data[k]));
        this._circles.sort(this.compareOptionsByLabel);
        this.incrementVersion();
      });
  }

  private initLanguages(): void {
    this.http.get<{ [key: string]: string }>(`${environment.apiBaseUrl}/api/v1/reference-data/languages`, {})
      .subscribe(data => {
        this._languages = Object.keys(data)
          .map(k => new Option(k, data[k]));
        this._languages.sort(this.compareOptionsByLabel);
        this.incrementVersion();
      });
  }

  private initCreditCardTypes(): void {
    this.allCreditCardTypes = [];
    this.allCreditCardTypes.push(new Option('', '-'));
    this.allCreditCardTypes.push(new Option(CreditCardTypeConstant.DC, $localize`:@@general.code.cctype_DC:Diners`));
    this.allCreditCardTypes.push(new Option(CreditCardTypeConstant.AX, $localize`:@@general.code.cctype_AX:American Express`));
    this.allCreditCardTypes.push(new Option(CreditCardTypeConstant.VI, $localize`:@@general.code.cctype_VI:Visa`));
    this.allCreditCardTypes.push(new Option(CreditCardTypeConstant.CA, $localize`:@@general.code.cctype_CA:Mastercard / Eurocard`));
    this.allCreditCardTypes.push(new Option(CreditCardTypeConstant.TP, $localize`:@@general.code.cctype_TP:AirPlus`));
    this.allCreditCardTypes.push(new Option(CreditCardTypeConstant.JC, $localize`:@@general.code.cctype_JC:JCB International`));
    this.allCreditCardTypes.push(new Option(CreditCardTypeConstant.DS, $localize`:@@general.code.cctype_DS:Discover`));
    this.allCreditCardTypes.sort((a, b) => a.value.localeCompare(b.value));
    this.circleCustomizationService.customizeCreditCardTypes(this.allCreditCardTypes);

    this._creditCardTypes.next(this.allCreditCardTypes);
  }

  private updateCreditCardTypes(user?: User): void {
    const showJcb = AccessService.hasAnyRole(user, 'ROLE_ADMIN') ||
      ['AMADEUS', 'LCC'].some(c => user?.circle === c);

    if (showJcb) {
      this._creditCardTypes.next(this.allCreditCardTypes);
    } else {
      this._creditCardTypes.next(this.allCreditCardTypes.filter(cc => cc.key !== CreditCardTypeConstant.JC));
    }
  }

  private initAddFopTypes(): void {
    this.allAddFopTypes = [
      new Option('UNDEFINED', '-'),
      new Option('CASH', $localize`:@@general.cash:Cash`),
      new Option('LSV', $localize`:@@general.lsv:Bank direct debit`),
      new Option('INVOICE', $localize`:@@general.invoice:Invoice`)
    ];

    this._addFopTypes.next(this.allAddFopTypes);
  }

  private initSpanishResidenceOptions(): void {
    this.http.get<CodedReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/residence-areacodes`, {})
      .subscribe(data => {
        this._residenceAreacodes = data
          .map(def => new Option(def.code, def.name));
        this._residenceAreacodes.sort((opt1, opt2) => opt1.value.localeCompare(opt2.value));
        this._residenceAreacodes.splice(0, 0, new Option('', '-'));
        this.incrementVersion();
      });


    this._residenceAreas = [];
    this._residenceAreas.push(new Option(ResidentAreaConstant.ANDALUSIA, $localize`:@@residence.options.area.andalusia:Andalusia`));
    this._residenceAreas.push(new Option(ResidentAreaConstant.BALEARIC, $localize`:@@residence.options.area.balearic:Balearic`));
    this._residenceAreas.push(new Option(ResidentAreaConstant.CANARY, $localize`:@@residence.options.area.canary:Canay`));
    this._residenceAreas.push(new Option(ResidentAreaConstant.MELILLA, $localize`:@@residence.options.area.melilla:Melilla`));
    this._residenceAreas.sort((a, b) => a.value.localeCompare(b.value));
    this._residenceAreas.splice(0, 0, new Option('UNDEFINED', '-'));

    this._residenceCardtypes = [];
    this._residenceCardtypes.push(new Option(ResidentCardTypeConstant.MINORRESIDENT, $localize`:@@residence.options.cardtype.minor_resident:Minor resident without National Identity Card`));
    this._residenceCardtypes.push(new Option(ResidentCardTypeConstant.NATIONALID, $localize`:@@residence.options.cardtype.national_id:National Identity Card (DNI)`));
    this._residenceCardtypes.push(new Option(ResidentCardTypeConstant.RESIDENTCARD, $localize`:@@residence.options.cardtype.resident_card:Resident card or Foreigner ID (NIE)`));
    this._residenceCardtypes.push(new Option(ResidentCardTypeConstant.TEMPRESIDENT, $localize`:@@residence.options.cardtype.temp_resident:Temporary Resident`));
    this._residenceCardtypes.sort((a, b) => a.value.localeCompare(b.value));
    this._residenceCardtypes.splice(0, 0, new Option('UNDEFINED', '-'));
  }

  private updateAddFopTypes(user?: User): void {
    const isAdmin = AccessService.hasAnyRole(user, 'ROLE_ADMIN');
    const conditionalFopTypes = new Map<string, boolean>([
      [AdditionalFormOfPaymentConstant.CASH, isAdmin || user?.circle === 'BCD_SPAIN'],
      [AdditionalFormOfPaymentConstant.LSV, isAdmin || user?.circle === 'BTAFIRST']
    ]);

    // Filter if an option is present and set to false, otherwise assume it should be displayed
    this._addFopTypes.next(this.allAddFopTypes.filter(addFop => conditionalFopTypes.get(addFop.key) !== false));
  }

  private initEmailDeliveries(): void {
    this._emailDeliveries = [];
    this._emailDeliveries.push(new Option(EmailDeliveryConstant.EMAIL, $localize`:@@general.code.emaildelivery_0:Amadeus Mail`));
    this._emailDeliveries.push(new Option(EmailDeliveryConstant.EMAILPLUS, $localize`:@@general.code.emaildelivery_1:Amadeus Fax Email Plus`));
    this._emailDeliveries.sort((a, b) => a.value.localeCompare(b.value));
    this.circleCustomizationService.customizeEmailDeliveries(this._emailDeliveries);

  }

  private initPublishTargets(): void {
    this.http.get<PublishTarget[]>(`${environment.apiBaseUrl}/api/v1/reference-data/publishtargets`, {})
      .subscribe(data => {
        this._publishTargets = data;
        this._publishTargets.sort(this.comparePublishTargetsByLabel);
        this.incrementVersion();
      });
  }

  private initVisaEntryTypes(): void {
    this._visaEntryTypes = [];
    this._visaEntryTypes.push(new Option('', '-'));
    this._visaEntryTypes.push(new Option(VisaExportTypeEnumConstant.SINGLE, $localize`:@@general.code.visaentry.single:Single entry`));
    this._visaEntryTypes.push(new Option(VisaExportTypeEnumConstant.DOUBLE, $localize`:@@general.code.visaentry.double:Double entry`));
    this._visaEntryTypes.push(new Option(VisaExportTypeEnumConstant.MULTIPLE, $localize`:@@general.code.visaentry.multiple:Multiple entry`));
  }

  private compareOptionsByLabel(a: Option, b: Option): number {
    return a.value.localeCompare(b.value);
  }

  private comparePublishTargetsByLabel(a: PublishTarget, b: PublishTarget): number {
    return a.applicationName.localeCompare(b.applicationName);
  }

  private initRailMembershipClasses(): void {
    this._railMembershipClasses = [];
    this._railMembershipClasses.push(new Option(RailMembershipClassConstant.UNDEFINED, '-'));
    this._railMembershipClasses.push(new Option(RailMembershipClassConstant.FIRST, $localize`:@@rail.options.class.first:First`));
    this._railMembershipClasses.push(new Option(RailMembershipClassConstant.SECOND, $localize`:@@rail.options.class.second:Second`));
  }

  private initRailCardTypes(): void {
    this.fetchRailCardTypes(FeatureRailConstant.AMADEUS_RAIL);
    this.fetchRailCardTypes(FeatureRailConstant.AMADEUS_GERMANY);
  }

  private fetchRailCardTypes(featureRail: FeatureRailEnum) {
    const httpParams = new HttpParams()
      .set('featureRail', featureRail);
    this.http.get<RailCardTypeReferenceDefinition[]>(`${environment.apiBaseUrl}/api/v1/reference-data/rail-card-types`, { params: httpParams })
      .subscribe(data => {
        const railCardTypes = data
          .map(def => new TypedOption(def, def.name));
        railCardTypes.sort((opt1, opt2) => opt1.value.localeCompare(opt2.value));
        railCardTypes.splice(0, 0, new TypedOption<RailCardTypeReferenceDefinition>(undefined, '-'));
        this.circleCustomizationService.customizeRailCardTypes(railCardTypes);
        this._railCardTypes.set(featureRail, railCardTypes);
        this.incrementVersion();
      });
  }

  private initRailTicketDelivery(): void {
    this._railTicketDeliveries = [];
    this._railTicketDeliveries.push(new Option('UNDEFINED', '-'));
    this._railTicketDeliveries.push(new Option(RailPreferencesTicketDeliveryConstant.ONLINE, $localize`:@@rail.options.delivery.online:Online`));
    this._railTicketDeliveries.push(new Option(RailPreferencesTicketDeliveryConstant.BAHNTIX, $localize`:@@rail.options.delivery.bahntix:BahnTix`));
    this._railTicketDeliveries.push(new Option(RailPreferencesTicketDeliveryConstant.PAPERTICKET, $localize`:@@rail.options.delivery.paperticket:Paper ticket`));
  }

  private initRailWagonType(): void {
    this._railWagonTypes = [];
    this._railWagonTypes.push(new Option('UNDEFINED', '-'));
    this._railWagonTypes.push(new Option(RailPreferencesWagonTypeConstant.SINGLE, $localize`:@@rail.options.wagontype.single:Single`));
    this._railWagonTypes.push(new Option(RailPreferencesWagonTypeConstant.COMPARTMENT, $localize`:@@rail.options.wagontype.compartment:Compartment`));
    this._railWagonTypes.push(new Option(RailPreferencesWagonTypeConstant.TABLE, $localize`:@@rail.options.wagontype.table:Table`));
    this._railWagonTypes.push(new Option(RailPreferencesWagonTypeConstant.PREFERWIDEBODY, $localize`:@@rail.options.wagontype.prefer_widebody:Open, preferred`));
    this._railWagonTypes.push(new Option(RailPreferencesWagonTypeConstant.REQUIREWIDEBODY, $localize`:@@rail.options.wagontype.require_widebody:Open, required`));
    this.circleCustomizationService.customizeRailWagonTypes(this._railWagonTypes);
  }

  private initRailWagonExtraType(): void {
    this._railWagonExtraTypes = [];
    this._railWagonExtraTypes.push(new Option('UNDEFINED', '-'));
    this._railWagonExtraTypes.push(new Option(RailPreferencesWagonExtraTypeConstant.QUIETAREA, $localize`:@@rail.options.wagonextratype.quietarea:Quiet area`));
    this._railWagonExtraTypes.push(new Option(RailPreferencesWagonExtraTypeConstant.PHONEAREA, $localize`:@@rail.options.wagonextratype.phonearea:Phone area`));
  }

  private initRailSeatPreference(): void {
    this._railSeatPreferences = [];
    this._railSeatPreferences.push(new Option('', '-'));
    this._railSeatPreferences.push(new Option(RailPreferencesSeatConstant.WINDOW, $localize`:@@rail.options.seatpref.window:Window`));
    this._railSeatPreferences.push(new Option(RailPreferencesSeatConstant.AISLE, $localize`:@@rail.options.seatpref.aisle:Aisle`));
  }

  private initColorTypes(): void {
    this._colorTypes = [];
    // see server-side UserInterfaceColorType
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.NAVBAR_BACKGROUND, $localize`:@@color.type.navbar_background:Navbar background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.NAVBAR_TEXT, $localize`:@@color.type.navbar_text:Navbar text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.NAVBAR_TEXT_ACTIVE, $localize`:@@color.type.navbar_text_active:Navbar text :active`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_PRIMARY_BACKGROUND, $localize`:@@color.type.button_primary_background:Button primary background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_PRIMARY_BACKGROUND_HOVER, $localize`:@@color.type.button_primary_background_hover:Button primary background :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_PRIMARY_TEXT, $localize`:@@color.type.button_primary_text:Button primary text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_PRIMARY_TEXT_HOVER, $localize`:@@color.type.button_primary_text_hover:Button primary text :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_SECONDARY_BACKGROUND, $localize`:@@color.type.button_secondary_background:Button secondary background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_SECONDARY_BACKGROUND_HOVER, $localize`:@@color.type.button_secondary_background_hover:Button secondary background :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_SECONDARY_TEXT, $localize`:@@color.type.button_secondary_text:Button secondary text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_SECONDARY_TEXT_HOVER, $localize`:@@color.type.button_secondary_text_hover:Button secondary text :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_BACKGROUND, $localize`:@@color.type.button_outlinesecondary_background:Button outlinesecondary background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_BACKGROUND_HOVER, $localize`:@@color.type.button_outlinesecondary_background_hover:Button outlinesecondary background :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_TEXT, $localize`:@@color.type.button_outlinesecondary_text:Button outlinesecondary text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_TEXT_HOVER, $localize`:@@color.type.button_outlinesecondary_text_hover:Button outlinesecondary text :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_DANGER_BACKGROUND, $localize`:@@color.type.button_danger_background:Button danger background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_DANGER_BACKGROUND_HOVER, $localize`:@@color.type.button_danger_background_hover:Button danger background :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_DANGER_TEXT, $localize`:@@color.type.button_danger_text:Button danger text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BUTTON_DANGER_TEXT_HOVER, $localize`:@@color.type.button_danger_text_hover:Button danger text :hover`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ERROR_FIELD, $localize`:@@color.type.error_field:Error field`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.BACKGROUND, $localize`:@@color.type.background:Background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.CARD, $localize`:@@color.type.card:Card`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.CARD_TEXT, $localize`:@@color.type.card_text:Card text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.PUBLISHING_ERROR, $localize`:@@color.type.publishing_error:Publishing Error`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.PUBLISHING_WARNING, $localize`:@@color.type.publishing_warning:Publishing Warning`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.PUBLISHING_OK, $localize`:@@color.type.publishing_ok:Publishing OK`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ACCORDION_EXPANDED_TEXT, $localize`:@@color.type.accordion_expanded_text:Accordion text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ACCORDION_EXPANDED_BACKGROUND, $localize`:@@color.type.accordion_expanded_background:Accordion background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ALERT_SUCCESS_BACKGROUND, $localize`:@@color.type.alert_success_background:Alert success background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ALERT_SUCCESS_TEXT, $localize`:@@color.type.alert_success_text:Alert success text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ALERT_WARNING_BACKGROUND, $localize`:@@color.type.alert_warning_background:Alert warning background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ALERT_WARNING_TEXT, $localize`:@@color.type.alert_warning_text:Alert warning text`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ALERT_ERROR_BACKGROUND, $localize`:@@color.type.alert_error_background:Alert error background`));
    this._colorTypes.push(new Option(UserInterfaceColorTypeConstant.ALERT_ERROR_TEXT, $localize`:@@color.type.alert_error_text:Alert error text`));
  }

  private incrementVersion(): void {
    this._version = new Date();
  }
}
