import { BaseCircleCustomization } from '../base-circle-customization.class';
import { Option } from '@models/option';

export class VrTravelCustomization extends BaseCircleCustomization {

  override customizeAirlineListForCompanies(optionList: Option[]) {
    this.removeOption('WA', optionList);
    this.removeOption('SK', optionList);
  }

  override customizeAirlineListForTravellers(optionList: Option[]) {
    this.customizeAirlineListForCompanies(optionList);
  }

}
