import { BaseCircleCustomization } from "../base-circle-customization.class";
import { Option } from '@shared/models/option';
import { CreditCardTypeConstant } from '@shared/models/types.enum';

export class VckCustomization extends BaseCircleCustomization {

  override customizeCreditCardTypes(optionList: Option[]): void {
    this.removeOption(CreditCardTypeConstant.DC, optionList);
  }

}
