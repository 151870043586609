import { FieldAndStyleConfiguration } from '@shared/pci/tokenization-provider.class';

export class TokenizerUtils {

  static createPlainNumberInputField(changeListener: (event:any) => void): HTMLInputElement {
    const inputField = document.createElement('input');
    inputField.setAttribute('type', 'text');
    inputField.addEventListener('blur', () => {
      changeListener({
        event: {
          type: 'blur'
        }
      });
    });
    return inputField;
  }

  static addAndFocusPlainNumberInput(fields: FieldAndStyleConfiguration, inputField: HTMLInputElement) {
    if (fields.cardNumber) {
      document.getElementById(fields.cardNumber)?.append(inputField);
      inputField.focus();
    }
  }
}
