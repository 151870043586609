import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { Option } from '@shared/models/option';
import { ProfileTypeConstant, ProfileTypeEnum } from '@shared/models/types.enum';
import { BaseCircleCustomization, ProfileCollectionNames } from '../base-circle-customization.class';
import { CircleValidators } from '../circle-validators.class';

export class CtmCustomization extends BaseCircleCustomization {

  private travellerForm?: FormGroup;

  override customizeAirlineListForTravellers(optionList: Option[]): void {
    this.removeOption('WA', optionList);
    this.removeOption('SKE', optionList);
    this.sortOptionsByLabel(optionList);
  }

  override customizeAirlineListForCompanies(optionList: Option[]): void {
    this.customizeAirlineListForTravellers(optionList);
  }

  override customizeHotelList(optionList: Option[]): void {
    this.removeOption('**', optionList);
    this.renameOption('SW', 'STARWOOD (all)', optionList);
  }

  override customizeTravellerFormGroup(_traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    this.travellerForm = formGroup;

    super.addValidators(formGroup.get('data.generalData.businessPhone'), CircleValidators.flexPhone);
    super.addValidators(formGroup.get('data.generalData.privatePhone'), CircleValidators.flexPhone);
    super.addValidators(formGroup.get('data.generalData.mobilePhone'), CircleValidators.flexPhone);

    (formGroup.get('data.papers.passports') as FormArray)?.controls.forEach(grp => {
      super.addValidators(grp.get('expiration'), CircleValidators.dateInFuture);
    });
    (formGroup.get('data.railInformation.railCards') as FormArray)?.controls.forEach(grp => {
      super.addValidators(grp.get('expiration'), CircleValidators.dateInFuture);
    });
    (formGroup.get('data.creditCardInfo.creditCards') as FormArray)?.controls.forEach(grp => {
      const publishAsFopControl = grp.get('publishAsFop');
      if (publishAsFopControl) {
        const publishAsFop$ = publishAsFopControl.valueChanges.subscribe(this.publishAsFopChangeHandler(publishAsFopControl));
        this.registerSubscription(publishAsFop$);
      }
    });
  }

  override addedCollectionItem(profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: FormGroup): void {
    if (profileType === ProfileTypeConstant.TRAVELLER) {
      if (['passports', 'visas', 'idCards', 'railCards'].includes(collectionName)) {
        formGroup.get('expiration')?.addValidators(CircleValidators.dateInFuture);
      }
      if (collectionName === 'creditCards') {
        const publishAsFopControl = formGroup.get('publishAsFop');
        if (publishAsFopControl) {
          const publishAsFop$ = publishAsFopControl.valueChanges.subscribe(this.publishAsFopChangeHandler(publishAsFopControl));
          this.registerSubscription(publishAsFop$);
        }
      }
    }
  }

  override removedCollectionItem(profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: AbstractControl<any, any>): void {
    if (profileType === ProfileTypeConstant.TRAVELLER) {
      if (['passports', 'visas', 'idCards', 'railCards'].includes(collectionName)) {
        formGroup.get('expiration')?.clearValidators();
      }
    }
  }

  override customizeCompanyFormGroup(company: CompanyDetailResponse | undefined, formGroup: FormGroup): void {
    if (!company?.uuid) {
      formGroup.get('data.travellerSettings.selfApprovalAllowed')?.setValue(false);
      formGroup.get('data.travellerSettings.approverSelectableByTraveller')?.setValue(false);
    }
  }

  private publishAsFopChangeHandler(control?: AbstractControl<any, any>): (value: any) => void {
    return (publishAsFopValue) => {
      if (publishAsFopValue) {
        (this.travellerForm?.get('data.creditCardInfo.creditCards') as FormArray)?.controls.forEach(grp => {
          const publishAsFopControl = grp.get('publishAsFop');
          if (publishAsFopControl && publishAsFopControl !== control) {
            publishAsFopControl.setValue(false, { emitEvent: false });
          }
        });
      }
    };
  }

}
