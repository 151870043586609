import { FormGroup } from '@angular/forms';
import { EmailDeliveryConstant } from '@shared/models/types.enum';
import { BaseCircleCustomization } from '../base-circle-customization.class';
import { Option } from '@shared/models/option';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { CircleValidators } from '../circle-validators.class';
import { AngularUtils } from '@shared/angular-utils.class';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';

export class MunckhofCustomization extends BaseCircleCustomization {

  override customizeEmailDeliveries(optionList: Option[]): void {
    this.removeOption(EmailDeliveryConstant.EMAILPLUS, optionList);
  }

  override customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    if (!traveller?.uuid) {
      AngularUtils.runOnNextTick(() => {
        formGroup.get('data.generalData.receiveDocs')?.setValue(false);
      });
    }
  }

  override customizeCompanyFormGroup(company: CompanyDetailResponse | undefined, formGroup: FormGroup): void {
    if (!company?.uuid) {
      AngularUtils.runOnNextTick(() => {
        formGroup.get('data.contact.receiveDocs')?.setValue(false);
      });
    }
    super.addValidators(formGroup.get('externalNr'), CircleValidators.eightDigits);
  }

}
