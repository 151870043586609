import { BaseCircleCustomization } from '../base-circle-customization.class';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { CircleValidators } from '../circle-validators.class';
import { RailCardTypeReferenceDefinition } from '@shared/models/coded-reference-definition';
import { Option, TypedOption } from '@shared/models/option';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum, RoleConstant, SexTypeConstant } from '@shared/models/types.enum';
import { AccessService } from '@services/access.service';
import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { ObjectUtils } from '@shared/object-utils.class';

export class BigTravelCustomization extends BaseCircleCustomization {

  constructor(private accessService: AccessService) {
    super();

    this.setPreferredCountryCodesOnPhone(['SE'], true);
  }

  /**
   * Traveller cannot edit their own name, unless during self-registration.
   */
  override getTransientStandardfieldDefinition(controlPath: string[], _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if (this.accessService.hasAnyRole(RoleConstant.TRAVELLER)) {
      const allowNameEdit = this.accessService.hasAnyRole('ROLE_SELFREG_USER');
      // note that 'name' might match other control than the intended, but they are all read-only
      const markReadonly = (ObjectUtils.equalsAny(controlName, 'firstname', 'middlename', 'name') && !allowNameEdit) ||
        ObjectUtils.equalsAny(controlName, 'username', 'email');
      if (markReadonly) {
        return {
          collectionName: '',
          fieldName: controlName,
          profileType: ProfileTypeConstant.TRAVELLER,
          affectedUserGroup: AffectedUserGroupConstant.COMPANY_ADMIN_AND_TRAVELLER,
          fieldDisplayType: FieldDisplayTypeConstant.READONLY,
          label: ''
        };
      }
    }
    return;
  }

  override customizeSexOptions(optionList: Option[]): void {
    super.removeOption(SexTypeConstant.UNDEF, optionList);
  }

  override customizeRailCardTypes(railCardList: TypedOption<RailCardTypeReferenceDefinition>[]): void {
    this.removeOptionsWithCodePrefix(railCardList, 'SJ_');
  }

  override customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    if (!traveller?.uuid) {
      formGroup.get('data.generalData.email')?.valueChanges.subscribe(val => formGroup.get('username')?.setValue(val));
    }

    super.addValidators(formGroup.get('username'), Validators.email);

    this.customizePhone(formGroup.get('data.generalData.businessPhone'));
    this.customizePhone(formGroup.get('data.generalData.privatePhone'));
    this.customizePhone(formGroup.get('data.generalData.mobilePhone'));
  }

  private removeOptionsWithCodePrefix(options: TypedOption<RailCardTypeReferenceDefinition>[] | undefined, prefix: string): void {
    if (options) {
      let itemIndex = options.findIndex(opt => opt.key && opt.key.code.startsWith(prefix));
      while (itemIndex >= 0) {
        options.splice(itemIndex, 1);
        itemIndex = options.findIndex(opt => opt.key && opt.key.code.startsWith(prefix));
      }
    }
  }

  private customizePhone(control: AbstractControl | null): void {
    super.addValidators(control, CircleValidators.internationalMandatoryPhone);
  }

}
