import { AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SectionState } from './section-store-feature/section-state';
import { asyncScheduler, debounceTime, distinctUntilChanged, map, Observable, scheduled, startWith, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { setSectionPopulated } from './section-store-feature/section-actions';
import { selectAgencyContextForCompany, selectCompanyContextForTraveller } from '../feature-profiles/store-feature/profiles-selectors';
import { StandardfieldDefinition } from './models/standardfield-definition';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum, RoleConstant } from './models/types.enum';
import { LegacyBindingTranslator } from './legacy-binding-translator.class';
import { GenericValueWithSetup } from './models/generic-value';
import { selectPrincipal } from '../store-root/selectors';
import { User } from './models/user.class';

export type PopulatedSectionConfigType = 'include' | 'exclude';

export interface PopulatedSectionWatchPathConfig {
  type: PopulatedSectionConfigType;

  matches(path: string, valueStack: any[]): boolean;
}

export class PopulatedSectionWatchSimplePathConfig implements PopulatedSectionWatchPathConfig {

  constructor(public type: PopulatedSectionConfigType, private paths: string[]) {
  }

  matches(path: string): boolean {
    if (this.type === 'include') {
      return this.paths.some(inc => inc === path || path.startsWith(inc + '.') || inc.startsWith(path + '.'))
    }

    return this.paths.includes(path);
  }
}

export class PopulatedSectionWatchGenericFieldsConfig implements PopulatedSectionWatchPathConfig {

  private readonly stackOffsetParentObject = 2;

  type: PopulatedSectionConfigType = 'include';
  private fieldUuids;

  constructor(fields: GenericValueWithSetup[]) {
    this.fieldUuids = fields.map(f => f.setup.uuid);
  }

  matches(path: string, valueStack: any[]): boolean {
    if (path === 'value') {
      return this.fieldUuids.includes(valueStack[valueStack.length - this.stackOffsetParentObject].field.uuid);
    }

    return false;
  }
}

export class PopulatedSectionWatchStdFldDefConfig implements PopulatedSectionWatchPathConfig {

  type: PopulatedSectionConfigType = 'exclude';
  private hiddenPaths: string[];

  constructor(hiddenFieldDefs: StandardfieldDefinition[], private principalRole: string) {
    this.hiddenPaths = hiddenFieldDefs
      .filter(def => this.isApplicableForPrincipal(def))
      .map(def => this.toFormPath(def));
  }

  matches(path: string): boolean {
    return this.hiddenPaths.some(probe => this.matchesPath(path, probe));
  }

  private matchesPath(candidate: string, probe: string): boolean {
    if (probe.includes('.')) {
      // some outline run on e.g. memberships.flight.customerRequest while standard field customization
      // will only be on flight.customerRequest. So, for nested paths, be lenient.
      return candidate.endsWith(probe);
    }
    return candidate === probe;
  }

  private isApplicableForPrincipal(def: StandardfieldDefinition): boolean {
    switch (this.principalRole) {
      case RoleConstant.ADMIN:
        return false;
      case RoleConstant.AGENCYMANAGER:
        return def.affectedUserGroup === AffectedUserGroupConstant.ENTIRE_AGENCY;
      case RoleConstant.COMPANYMANAGER:
        return [AffectedUserGroupConstant.ENTIRE_AGENCY, AffectedUserGroupConstant.COMPANY_ADMIN_AND_TRAVELLER, AffectedUserGroupConstant.COMPANY_ADMIN_ONLY]
          .includes(def.affectedUserGroup);
      case RoleConstant.TRAVELLER:
        return def.affectedUserGroup !== AffectedUserGroupConstant.COMPANY_ADMIN_ONLY;
      default:
        return true;
    }
  }

  private toFormPath(fldDef: StandardfieldDefinition): string {
    if (fldDef.collectionName) {
      const collectionKey = LegacyBindingTranslator.getTranslatedParentName(fldDef.collectionName, fldDef.fieldName);
      const fieldKey = LegacyBindingTranslator.getTranslatedBindingName(fldDef.fieldName, fldDef.collectionName);
      return `${collectionKey}.${fieldKey}`;
    }
    return LegacyBindingTranslator.getTranslatedBindingName(fldDef.fieldName);
  }
}

export class PopulatedSectionWatcher {

  static createForForm(form: AbstractControl, name: string, store: Store<SectionState>,
    configs?: PopulatedSectionWatchPathConfig[], acceptCheckedAsPopulated = false): PopulatedSectionWatcher {

    const watcher = new PopulatedSectionWatcher();
    watcher.form = form;
    watcher.name = name;
    watcher.store = store;
    watcher.configs = configs;
    watcher.acceptCheckedAsPopulated = acceptCheckedAsPopulated;
    watcher.initForForm();
    return watcher;
  }

  static createForValue(value: any, profileType: ProfileTypeEnum, name: string, store: Store<SectionState>,
    configs?: PopulatedSectionWatchPathConfig[], acceptCheckedAsPopulated = false): PopulatedSectionWatcher {

    const watcher = new PopulatedSectionWatcher();
    watcher.initialValue = value;
    watcher.name = name;
    watcher.profileType = profileType;
    watcher.store = store;
    watcher.configs = configs;
    watcher.acceptCheckedAsPopulated = acceptCheckedAsPopulated;
    watcher.initForValue();
    return watcher;
  }

  private principalRole: string;
  private stdFldDefConfig?: PopulatedSectionWatchStdFldDefConfig;
  private form?: AbstractControl;
  private initialValue?: any;
  private name: string;
  private profileType?: ProfileTypeEnum;
  private store: Store<SectionState>;
  private configs?: PopulatedSectionWatchPathConfig[];
  private acceptCheckedAsPopulated = false;

  private get pathConfigs(): PopulatedSectionWatchPathConfig[] {
    const result: PopulatedSectionWatchPathConfig[] = Object.assign([], this.configs);
    if (this.stdFldDefConfig) {
      result.push(this.stdFldDefConfig);
    }
    return result;
  }

  /**
   * Get a subscription which dispatches 'setSectionPopulated' based on form value changes.
   * Note that changing the principal and/or standard field definitions will only have an
   * effect on the next value change.
   * @returns
   */
  activate(): Subscription {
    return this.watchFromValueChanges()
      .pipe(
        debounceTime(environment.typeaheadDebounce),
        map(updatedValue => this.checkPopulatedFields(updatedValue)),
        distinctUntilChanged()
      ).subscribe(hasData => {
        this.store.dispatch(setSectionPopulated({ name: this.name, populated: hasData }));
      });
  }

  private initForForm(): void {
    this.store.select(selectPrincipal)
      .subscribe(principal => {
        this.principalRole = User.getRole(principal?.authorities);
        this.profileType = this.determineEntityFromForm(this.form);
        this.initStandardfieldCustomizations();
      });
  }

  private initForValue(): void {
    this.store.select(selectPrincipal)
      .subscribe(principal => {
        this.principalRole = User.getRole(principal?.authorities);
        this.initStandardfieldCustomizations();
      });
  }

  private determineEntityFromForm(ctrl?: AbstractControl): ProfileTypeEnum {
    let formParent = ctrl;
    while (formParent?.parent) {
      formParent = formParent.parent;
    }
    if (formParent?.get('externalNr')) {
      return ProfileTypeConstant.CORPORATE;
    }
    if (formParent?.get('middlename')) {
      return ProfileTypeConstant.TRAVELLER;
    }
    return ProfileTypeConstant.AGENCY;
  }

  private initStandardfieldCustomizations(): void {
    if (this.profileType === ProfileTypeConstant.TRAVELLER) {
      this.store.select(selectCompanyContextForTraveller)
        .pipe(map(ctx => ctx?.genericSetup?.standardFieldDefinitions))
        .subscribe(fldDefs => this.initPopulatedSectionWatchStdFldDefConfig(fldDefs));
    } else if (this.profileType === ProfileTypeConstant.CORPORATE) {
      this.store.select(selectAgencyContextForCompany)
        .pipe(map(ctx => ctx?.genericSetup?.standardFieldDefinitions))
        .subscribe(fldDefs => this.initPopulatedSectionWatchStdFldDefConfig(fldDefs));
    } else {
      this.initPopulatedSectionWatchStdFldDefConfig();
    }
  }

  private initPopulatedSectionWatchStdFldDefConfig(fieldDefs?: StandardfieldDefinition[]): void {
    this.stdFldDefConfig = undefined;
    if (fieldDefs?.length) {
      const hiddenFieldDefs = fieldDefs.filter(def => def.fieldDisplayType === FieldDisplayTypeConstant.HIDDEN);
      if (hiddenFieldDefs.length) {
        this.stdFldDefConfig = new PopulatedSectionWatchStdFldDefConfig(hiddenFieldDefs, this.principalRole);
      }
    }
  }

  private watchFromValueChanges(): Observable<any> {
    if (this.form) {
      let valueChanges$ = this.form.valueChanges;

      // Note: instanceof check does not always work to recognize FormGroup
      if (typeof (this.form as any).getRawValue === 'function') {
        valueChanges$ = valueChanges$.pipe(map(() => (this.form as any).getRawValue()));
      }

      return valueChanges$
        .pipe(startWith(this.form.value));
    }
    return scheduled([this.initialValue], asyncScheduler);
  }

  private checkPopulatedFields(value: any): boolean {
    return this.checkPopulatedFieldsRecursively([value], false);
  }

  private checkPopulatedFieldsRecursively(valueStack: any[], isArrayMember: boolean, path?: string): boolean {
    if (path && this.isAbortPopulatedFieldCheck(path, valueStack)) {
      return false;
    }

    const value = valueStack[valueStack.length - 1];
    if (Array.isArray(value)) {
      return value.some(arrayValue => {
        valueStack.push(arrayValue);
        try {
          return this.checkPopulatedFieldsRecursively(valueStack, true, path)
        } finally {
          valueStack.pop();
        }
      });
    } else if (value instanceof Object) {
      if (isArrayMember && value.hasOwnProperty('_operation') && value['_operation'] === 'remove') {
        return false;
      }

      return Object.keys(value).some(key => {
        let subPath = key;
        if (path) {
          subPath = `${path}.${subPath}`;
        }

        valueStack.push(value[key]);
        try {
          return this.checkPopulatedFieldsRecursively(valueStack, false, subPath);
        } finally {
          valueStack.pop();
        }
      });
    } else if (typeof value === 'boolean') {
      // Should be a checkbox
      return value && this.acceptCheckedAsPopulated;
    }

    return value && String(value).trim().length > 0 && String(value).toLocaleLowerCase() !== 'none' && String(value).toLocaleLowerCase() !== 'undefined';
  }

  private isAbortPopulatedFieldCheck(path: string, valueStack: any[]): boolean {
    let hasIncludeFilter = false;
    let includeFilterMatch = false;
    const abortCheck = this.pathConfigs.some(cfg => {
      if (cfg.type == 'include') {
        hasIncludeFilter = true;
        includeFilterMatch = includeFilterMatch || cfg.matches(path, valueStack);
      } else { // type === 'exclude'
        return cfg.matches(path, valueStack);
      }

      return false;
    });

    return abortCheck || (hasIncludeFilter && !includeFilterMatch);
  }
}
