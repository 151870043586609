import { Component, OnInit } from '@angular/core';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';

@Component({
  template: `
  <div class="uf-abb-kontierung" [formGroup]="valueWithSetupFormGroup">
    <div>
      <select class="form-select" [value]="selectValue" (change)="updateSelectValue($event)">
        <option *ngIf="showUnspecified" value="">Unspecified</option>
        <option value="CC">Cost Center</option>
        <option value="SO">Sales Order</option>
        <option value="WBS">WBS</option>
        <option value="IOR">Internal Order</option>
      </select>
    </div>
    <div class="input-group">
      <span class="input-group-text">{{selectValue}}</span>
      <input type="text" class="form-control" [value]="inputValue" (change)="updateInputValue($event)">
    </div>
    <input type="hidden" [attr.id]="controlId" formControlName="value">
  </div>
`,
styles: [`
  :host {
    width: 100%;
  }
  div.uf-abb-kontierung {
    width: 100%;
  }
`]
})
export class AbbKontierungComponent extends BaseGenericValueWithSetupComponent implements OnInit {

  inputValue: string;
  selectValue: string;
  showUnspecified = true;

  constructor() {
    super();
    this.inputValue = '';
    this.selectValue = '';
  }

  ngOnInit(): void {
    this.parse(this.valueWithSetup.value);
  }

  updateInputValue(evt: Event): void {
    this.inputValue = (evt.target as HTMLInputElement).value;
    this.updateCompoundValue();
  }

  updateSelectValue(evt: any): void {
    this.selectValue = (evt.target as HTMLSelectElement).value;
    this.updateCompoundValue();
  }

  private parse(compoundValue?: string) {
    if (compoundValue) {
      const spacePos = compoundValue.indexOf(' ');
      if (spacePos >= 0) {
        this.selectValue = compoundValue.substring(0, spacePos);
        this.inputValue = compoundValue.substring(spacePos + 1);
        this.showUnspecified = false;
      } else {
        this.inputValue = compoundValue;
      }
    }
  }

  private updateCompoundValue(): void {
    const stringValue = `${this.selectValue ? this.selectValue : ''} ${this.inputValue ? this.inputValue : ''}`.trim();
    const formValue = this.valueWithSetupFormGroup.get('value');
    formValue?.setValue(stringValue);
    formValue?.markAsDirty();
    this.valueWithSetupFormGroup.get('field')?.markAsDirty();

  }

}
