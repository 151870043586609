import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http";
import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {ObjectUtils} from "@shared/object-utils.class";
import {Observable, tap} from "rxjs";

@Injectable()
export class PayloadInterceptor implements HttpInterceptor {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest: HttpRequest<any>;
    if (request.body && !(request.body instanceof HttpParams) && request.responseType === 'json') {
      const cloneAdvice = {
        body: ObjectUtils.deepClone(request.body)
      };
      ObjectUtils.replaceDatesWithStrings(cloneAdvice.body);
      this.flatten(cloneAdvice.body);
      modifiedRequest = request.clone(cloneAdvice);
    } else {
      modifiedRequest = request;
    }

    return next.handle(modifiedRequest)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            ObjectUtils.replaceStringsWithDates(event.body);
          }
        })
      );
  }

  flatten(jsonObject: any): void {
    if (!jsonObject || !(jsonObject instanceof Object)) {
      return;
    }
    if (jsonObject instanceof Array) {
      for (const item of jsonObject) {
        this.flatten(item);
      }
    } else if (jsonObject instanceof Object) {
      for (const key of Object.keys(jsonObject)) {
        const value = jsonObject[key];
        if (value instanceof Array) {
          for (const item of value) {
            this.flatten(item);
          }
        } else if (this.isObjectToFlatten(value)) {
          jsonObject[key] = value.flatten;
        } else if (value instanceof Object) {
          this.flatten(value);
        }
      }
    }
  }

  private isObjectToFlatten(obj: any) {
    return obj && obj.hasOwnProperty('flatten');
  }
}
