import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { ProfileTypeConstant, AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeEnum } from '@shared/models/types.enum';
import { BaseCircleCustomization } from '../base-circle-customization.class';
import { Option } from '@shared/models/option';

export class BfpCustomization extends BaseCircleCustomization {

  override customizeAirlineListForTravellers(optionList: Option[]): void {
    this.renameOption('BA', 'British Airways', optionList);
    this.sortOptionsByLabel(optionList);
  }

  override customizeAirlineListForCompanies(optionList: Option[]): void {
    this.customizeAirlineListForTravellers(optionList);
  }

  override getTransientStandardfieldDefinition(controlPath: string[], _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if (('name' === controlName) || (('firstname' === controlName))) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.TRAVELLER,
        affectedUserGroup: AffectedUserGroupConstant.TRAVELLER,
        fieldDisplayType: FieldDisplayTypeConstant.READONLY,
        label: ''
      };
    }
    if ('language' === controlName) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.TRAVELLER,
        affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
        fieldDisplayType: FieldDisplayTypeConstant.HIDDEN,
        label: ''
      };
    }
    return;
  }

}
