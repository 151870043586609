import { Directive, ElementRef, forwardRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

import intlTelInput, { SomeOptions } from 'intl-tel-input';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LogService } from '@services/log-service.interface';

@Directive({
  selector: '[appTelInput]',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TelInputDirective), multi: true}]
})
export class TelInputDirective implements OnInit, OnChanges, ControlValueAccessor {

  static setPreferredCountryCodes(countryCodes: string[], setFirstAsInitial = false): void {
    TelInputDirective.preferredCountries = countryCodes;
    TelInputDirective.initialCountry = setFirstAsInitial && countryCodes.length > 0 ? countryCodes[0] : '';
  }

  private static readonly DEFAULT_PARAMS: SomeOptions = {
    autoPlaceholder: 'polite',
    formatOnDisplay: false,
    formatAsYouType: false,
    separateDialCode: false,
    nationalMode: false,
    allowDropdown: true,
    initialCountry: ''
    // NOTE: utilsScript has been bundled into our main JS file by angular.json
  }

  @Input()
  telPlaceholderNumberType: 'FIXED_LINE' | 'MOBILE' = 'FIXED_LINE';

  private static preferredCountries: string[] = [];
  private static initialCountry = '';
  private static i18nCountries?: any;
  private lastValue?: string;
  private exchangedDoubleZeroForPlus = false;
  private ngTelInput: any;
  private onChange: (fullNumber: string) => void;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private log: LogService) {
    this.customizeCountryDataOnce();
  }

  ngOnInit(): void {
    const el = this.elementRef.nativeElement;
    const options: SomeOptions = {
      ...TelInputDirective.DEFAULT_PARAMS,
      placeholderNumberType: this.telPlaceholderNumberType,
      countryOrder: TelInputDirective.preferredCountries,
      initialCountry: TelInputDirective.initialCountry,
      i18n: TelInputDirective.i18nCountries
    };
    this.ngTelInput = intlTelInput(el, options);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['telPlaceholderNumberType'] && !changes['telPlaceholderNumberType'].firstChange && changes['telPlaceholderNumberType'].currentValue) {
      this.ngTelInput.setPlaceholderNumberType(changes['telPlaceholderNumberType'].currentValue);
    }
  }

  writeValue(value: string): void {
    this.exchangedDoubleZeroForPlus = false;
    this.lastValue = this.replaceDoubleZeroWithPlus(value || '').number;
    this.setPhoneNumber(this.lastValue, true);
  }

  registerOnChange(onChange: (fullNumber: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(): void {
    // NO-OP
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', `${isDisabled}`);
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
    }
  }

  @HostListener('countrychange')
  onCountryChange(): void {
    this.onPhoneNumberOrCountryChange();
  }

  @HostListener('change')
  @HostListener('blur')
  onInputChange(): void {
    this.onPhoneNumberOrCountryChange(true);
  }

  private onPhoneNumberOrCountryChange(resetCountryIfEmpty = false): void {
    let phoneNumber = this.elementRef.nativeElement.value.trim();
    const processedNumber = this.replaceDoubleZeroWithPlus(phoneNumber);
    if (processedNumber.exchanged) {
      phoneNumber = processedNumber.number;
    }
    if (phoneNumber.length > 0 && !(/^(00|\+)/).test(phoneNumber)) {
      // We've probably just entered a phone number after selecting a country - add the dial code if necessary
      const dialCode = this.ngTelInput.getSelectedCountryData().dialCode;
      if (dialCode) {
        phoneNumber = `+${dialCode}${phoneNumber}`;
      }
    }

    if (phoneNumber.length === 0) {
      this.exchangedDoubleZeroForPlus = false;
    }

    this.propagateChange(phoneNumber, resetCountryIfEmpty);
  }

  private replaceDoubleZeroWithPlus(phoneNumber: string): { number: string, exchanged: boolean } {
    if (/^0{2}(\d{2}|1).*/.test(phoneNumber)) {
      this.exchangedDoubleZeroForPlus = true;
      return {
        number: `+${phoneNumber.substring(2)}`,
        exchanged: true,
      };
    }

    return { number: phoneNumber, exchanged: false };
  }

  private propagateChange(value: string, resetCountryIfEmpty: boolean) {
    if (value !== this.lastValue) {
      this.lastValue = value;

      this.setPhoneNumber(value, resetCountryIfEmpty);

      let propagatedValue = value;
      if (this.exchangedDoubleZeroForPlus && propagatedValue.length) {
        propagatedValue = `00${propagatedValue.substring(1)}`;
      }

      this.onChange?.(propagatedValue);
    }
  }

  private setPhoneNumber(value: string, resetCountryIfEmpty: boolean) {
    this.ngTelInput.setNumber(value);
    if (value.length === 0 && resetCountryIfEmpty) {
      this.ngTelInput.setCountry(TelInputDirective.initialCountry);
    }
  }

  private customizeCountryDataOnce(): void {
    if (!TelInputDirective.i18nCountries) {
      this.log.info('Initializing country data with 2-letter codes and blank first option', 'TelInputDirective#customizeCountryDataOnce');
      TelInputDirective.i18nCountries = {};

      const countryData = intlTelInput.getCountryData();
      const emptyCountryCode = 'globe'; // Render a globe instead of the australian flag
      countryData.unshift({
        name: '',
        iso2: emptyCountryCode,
        dialCode: '',
        priority: 0,
        areaCodes: null,
        nodeById: {}
      });
      countryData.forEach(country => TelInputDirective.i18nCountries[country.iso2] = country.iso2 === emptyCountryCode ? '' : country.iso2.toUpperCase());
      countryData.sort(function (a,b) { return a.name.localeCompare(b.name); });
    }
  }
}
