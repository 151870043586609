import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { environment } from '@environments/environment';
import { CircleBackendService } from '@services/circle-backend.service';
import { OperatorFunction, Observable, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs';

@Component({
  selector: 'app-brz-ldapname-typeahead',
  templateUrl: './brz-ldapname-typeahead.component.html',
  styleUrls: ['./brz-ldapname-typeahead.component.scss']
})
export class BrzLdapnameTypeaheadComponent {

  @Input() parentDn?: string;
  @Input()
  set control(control: AbstractControl | null) {
    this._control = control as UntypedFormControl;
  }
  get control(): UntypedFormControl {
    return this._control;
  }
  private _control: UntypedFormControl;

  constructor(private circleBackendService: CircleBackendService) { }

  lookupLdapOrgEntity: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(environment.typeaheadDebounce),
      distinctUntilChanged(),
      switchMap(term => this.circleBackendService.searchBrzLdap({q: term, p: this.parentDn})
        .pipe(map(resp => resp || [])))
    );

  ldapOrgEntityResultFormatter = (x: any) => this.formatOrgUnit(x);
  ldapOrgEntityInputFormatter = (x: any) => this.formatOrgUnit(x);

  validateEntryOnBlur(evt: Event): void {
    if ((<HTMLInputElement>evt.target)?.value && !this.isObjectValue()) {
      this.circleBackendService.searchBrzLdap({q: (<HTMLInputElement>evt.target).value, p: this.parentDn})
        .subscribe(blurSearchResult => {
          if (blurSearchResult.length) {
            this.control.setValue(blurSearchResult[0]);
          } else {
            this.control.setValue(null);
          }
        });
    }
  }

  private isObjectValue(): boolean {
    return typeof this.control.value === 'object';
  }

  private formatOrgUnit(o: any): string {
    if (o && o.hasOwnProperty('name')) {
      return `${o.name} (${o.id})`;
    }
    return o ? `${o}` : '';
  }

}
