import { createReducer, on } from '@ngrx/store';
import { setSectionHidden, setSectionPopulated, setVirtualSection } from './section-actions';
import { initialSectionState } from './section-state';

export const sectionStateReducer = createReducer(
  initialSectionState,
  on(setSectionPopulated, (state, action) => ({
    ...state,
    populatedProfileSections: updateSectionList(state.populatedProfileSections, action.name, action.populated)
  })),
  on(setSectionHidden, (state, action) => ({
    ...state,
    hiddenProfileSections: updateSectionList(state.hiddenProfileSections, action.name, action.hidden)
  })),
  on(setVirtualSection, (state, action) => ({
    ...state,
    virtualProfileSections: updateSectionList(state.virtualProfileSections, action.name, action.present),
  }))
);

function updateSectionList(prevPopulatedSections: string[], name: string, populated: boolean): string[] {
  const wasPreviouslyPopulated = prevPopulatedSections.indexOf(name) >= 0;
  if (populated) {
    if (!wasPreviouslyPopulated) {
      return [name].concat(prevPopulatedSections);
    }
  } else if (wasPreviouslyPopulated) {
    return prevPopulatedSections.filter(s => s !== name);
  }

  return prevPopulatedSections;
}
