import { ValidationErrors } from '@angular/forms';
import {createAction, props} from "@ngrx/store";
import {ApplicationError} from "@services/global-error-handler.service";
import { UserInterfaceStyleDto } from "@shared/models/user-interface-style-dto";
import {User} from "@shared/models/user.class";

export const setPrincipal = createAction(
  '[root] set principal',
  props<{ principal: User | undefined }>()
);

export const setUserInterfaceStyle = createAction(
  '[root] set user interface style',
  props<{ style: UserInterfaceStyleDto | undefined }>()
);

export const setError = createAction(
  '[root] set error',
  props<{ error: ApplicationError | undefined }>()
);

/**
 * A general-purpose indicator that a form has validation errors. Angular should already have marked
 * the individual controls, this action merely triggers the alert.
 */
export const setValidationError = createAction(
  '[root] set validation error',
  props<{ errors: ValidationErrors | null }>()
);
