import { BaseCircleCustomization } from '../base-circle-customization.class';
import { FormGroup } from '@angular/forms';
import { StandardfieldDefinition } from '@models/standardfield-definition';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum } from '@models/types.enum';
import { ObjectUtils } from '@shared/object-utils.class';
import { Store } from '@ngrx/store';
import { FacesState } from '../../store-root/faces-state';
import { selectCompanyContextForTraveller } from '../../feature-profiles/store-feature/profiles-selectors';
import { CircleBackendService } from '@services/circle-backend.service';
import { CompanyContextForTraveller } from '@models/company-context-for-traveller';
import { BehaviorSubject, distinctUntilChanged, filter, skip, withLatestFrom } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { setCompanyContextForTraveller } from '../../feature-profiles/store-feature/profiles-actions';
import { TravellerDetailResponse } from '@models/traveller-detail-response';

export class UniglobeCustomization extends BaseCircleCustomization {

  private currentCompanyContext?: CompanyContextForTraveller;
  private travellerNamesReadOnly = new BehaviorSubject(false);
  private workingWithExistingTraveller = new BehaviorSubject(true);

  constructor(private store: Store<FacesState>,
              private circleBackendService: CircleBackendService) {
    super();
    this.hookupReadOnlyTravellerNameHandling();
  }

  override customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, _formGroup: FormGroup) {
    this.workingWithExistingTraveller.next((traveller?.uuid?.length ?? 0) > 0);
  }

  override getTransientStandardfieldDefinition(controlPath: string[], profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if (profileType === ProfileTypeConstant.TRAVELLER && ObjectUtils.equalsAny(controlName, 'firstname', 'name', 'middlename')) {
      /*
       * Note that our own processing in this class makes sure to push a refreshed company context whenever the readOnly flag changes
       * As a result we'll be able to provide the correct setting here
       */
      const applyOnCurrentTraveller = this.workingWithExistingTraveller.value && this.travellerNamesReadOnly.value;
      if (applyOnCurrentTraveller) {
        return {
          collectionName: '',
          fieldName: controlName,
          profileType: ProfileTypeConstant.TRAVELLER,
          affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
          fieldDisplayType: FieldDisplayTypeConstant.READONLY,
          label: ''
        }
      }
    }

    if (profileType === ProfileTypeConstant.CORPORATE && controlName === 'externalNr') {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.CORPORATE,
        affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
        fieldDisplayType: FieldDisplayTypeConstant.HIDDEN,
        label: ''
      };
    }

    return;
  }

  private hookupReadOnlyTravellerNameHandling(): void {
    const updateSubscription = this.travellerNamesReadOnly
      .pipe(
        withLatestFrom(this.workingWithExistingTraveller),
        distinctUntilChanged((prev, current) => prev.every(val => current.includes(val)) && current.every(val => prev.includes(val))),
        skip(1)
      ).subscribe(() => this.dispatchCompanyContextUpdate());
    this.registerSubscription(updateSubscription);

    const companyContextForTraveller$ = this.store.select(selectCompanyContextForTraveller)
      .pipe(
        filter(ctx => ctx?.uuid !== this.currentCompanyContext?.uuid && (ctx?.uuid?.length ?? 0) > 0),
        tap(ctx => this.currentCompanyContext = ctx),
        switchMap(ctx => this.circleBackendService.getUniglobeValidationRules(ctx?.uuid ?? ''))
      ).subscribe(rules => this.travellerNamesReadOnly.next(rules.isReadOnlyName));
    this.registerSubscription(companyContextForTraveller$);
  }

  private dispatchCompanyContextUpdate() {
    // Fire a changed company context to have our field definitions get reloaded
    const clone = ObjectUtils.deepClone(this.currentCompanyContext);
    this.store.dispatch(setCompanyContextForTraveller({ context: clone }));
  }

}
