import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 * Note that translation between JSON payloads and Date happens in the HTTP interceptor
 * 'payload.interceptor.ts'
 */
@Injectable()
export class CustomNgbDateAdapter extends NgbDateAdapter<Date> {

  constructor() {
    super();
  }

  fromModel(value: Date | null): NgbDateStruct | null {
    if (value instanceof Date) {
      return {
        day: value.getDate(),
        month: value.getMonth() + 1,
        year: value.getFullYear()
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): Date | null {
    if (date) {
      return new Date(date.year, date.month - 1, date.day);
    }
    return null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    return value ? this.parseToNgbDate(value, this.locale) : null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? this.formatNgbDate(date, this.locale) : '';
  }

  /**
   * Format the internal NgbDateStruct to a user-input string
   */
  private formatNgbDate(date: NgbDateStruct, locale: string): string {
    const dateFormat = this.getDateFormat(locale);
    return formatDate(new Date(date.year, date.month - 1, date.day), dateFormat, locale);
  }

  /**
   * Parse user input to a - best-effort - NgbDateStruct
   */
  private parseToNgbDate(value: string, locale: string): NgbDateStruct {
    const dateFormat = this.getDateFormat(locale);
    const dateParts = value.split(/\D/);
    const dayFirst = dateFormat.toLowerCase().startsWith('d');
    return {
      day: parseInt(dateParts[dayFirst ? 0 : 1], 10),
      month: parseInt(dateParts[dayFirst ? 1 : 0], 10),
      year: parseInt(dateParts[2], 10)
    };
  }

  private getDateFormat(locale: string): string {
    // Faces traditionally forces the european/swiss format on everybody except for en_US
    if (locale === 'en-US') {
      return 'MM/dd/yyyy';
    }

    return 'dd.MM.yyyy';
  }

}



