import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
  <div class="modal-header">
      <h2 class="modal-title">Special Categories Agreement</h2>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger">
      <p>
        By filling in special categories of personal data, such as special diet preferences,
        you give your express consent to having the data processed by Resia AB until you delete the data,
        ask us to delete the data, revoke your consent or your employer ceases to be a customer with us.
      </p>
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-danger pull-right" (click)="modal.close()">Confirm</button>
    <button class="btn btn-danger pull-right" (click)="modal.dismiss()">Return</button>
  </div>
`
})
export class ResiaFoodPreferenceDialogComponent {
  constructor(public modal: NgbActiveModal) { }
}
