import { BaseCircleCustomization } from '../base-circle-customization.class';
import { GenericFieldSetup } from '@shared/models/generic-field-setup';
import { Type } from '@angular/core';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';
import { AbbKontierungComponent } from './abb-kontierung.component';

export class AbbCustomization extends BaseCircleCustomization {

  private abbKontierungComponent?: Type<BaseGenericValueWithSetupComponent>;

  constructor() {
    super();

    this.abbKontierungComponent = AbbKontierungComponent;
  }

  override getCustomizedGenericFieldSetup(genericFieldSetup?: GenericFieldSetup): GenericFieldSetup | undefined {
    if (genericFieldSetup?.name === 'CHHQ') {
      return {
        ...genericFieldSetup,
        ...{
          editable: false
        }
      };
    }
    return genericFieldSetup;
  }

  override getGenericValueWithSetupComponent(genericFieldSetup?: GenericFieldSetup): Type<BaseGenericValueWithSetupComponent> | undefined {
    if (genericFieldSetup?.name === 'Kontierung') {
      return this.abbKontierungComponent;
    }
    return super.getGenericValueWithSetupComponent(genericFieldSetup);
  }

}
