import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import {environment} from '@environments/environment';
import {Country} from '@shared/models/country';

@Injectable({
  providedIn: 'root'
})
export class SystemDataService {

  constructor(private http: HttpClient) {
  }

  searchCountry(term: string): Observable<Country[]> {
    const httpParams = new HttpParams()//
      .set('q', term);
    return this.http.get<Country[]>(`${environment.apiBaseUrl}/api/v1/reference-data/countries`, {params: httpParams});
  }

  getCountry(twoLetterCode: string): Observable<Country> {
    return this.http.get<Country>(`${environment.apiBaseUrl}/api/v1/reference-data/country/${twoLetterCode}`, {});
  }

}
