import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { setSectionPopulated, setVirtualSection } from '@shared/section-store-feature/section-actions';

@Injectable()
export class SectionEffects {

    constructor(private actions$: Actions) {
    }

    /**
     * Automatically set virtual sections to higlighted
     */
    highlightVirtualSection$ = createEffect(() => this.actions$.pipe(
        ofType(setVirtualSection),
        mergeMap(payload => of(setSectionPopulated({ name: payload.name, populated: payload.present })))
    ));
}
