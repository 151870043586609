export interface SectionState {
  populatedProfileSections: string[];
  hiddenProfileSections: string[];
  virtualProfileSections: string[];
}

export const initialSectionState: SectionState = {
  populatedProfileSections: [],
  hiddenProfileSections: [],
  virtualProfileSections: []
}
