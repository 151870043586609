import { Injectable } from '@angular/core';
import { PciComplianceTypeConstant, PciComplianceTypeEnum, ProfileTypeConstant, ProfileTypeEnum } from '@models/types.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { TokenizationConfiguration } from '@models/tokenization-configuration';
import { LogService } from '@services/log-service.interface';
import { mergeMap, Observable, Subscriber } from 'rxjs';
import { InternalStoreCreditCardResponse } from '@models/internal-store-credit-card-response';
import { TokenizationParameters, TokenizationProvider, TokenizationTransactionParameters } from '@shared/pci/tokenization-provider.class';
import { DatatransTokenizer } from '@shared/pci/datatrans-tokenizer.class';
import { PlaintextStoringTokenizer } from '@shared/pci/plaintext-tokenizer.class';
import { MidocoTokenizer } from '@shared/pci/midoco-tokenizer.class';
import { InitiateNoShowResponse } from '@models/initiate-no-show-response';

@Injectable({
  providedIn: 'root'
})
export class CreditCardTokenizationService {

  private tokenizerLibraries = new Map<PciComplianceTypeEnum, TokenizerLibrary>();
  private tokenizationHelpers = new Map<PciComplianceTypeEnum, (log: LogService, subscriber: Subscriber<TokenizationTransactionParameters>, merchantId: string, params: TokenizationParameters) => TokenizationProvider>([
    [PciComplianceTypeConstant.NONE, (log: LogService, subscriber: Subscriber<TokenizationTransactionParameters>, merchantId: string, params: TokenizationParameters) => new PlaintextStoringTokenizer(log, subscriber, merchantId, params)],
    [PciComplianceTypeConstant.MIDOCO, (log: LogService, subscriber: Subscriber<TokenizationTransactionParameters>, merchantId: string, params: TokenizationParameters) => new MidocoTokenizer(log, subscriber, merchantId, params)],
    [PciComplianceTypeConstant.DATATRANS, (log: LogService, subscriber: Subscriber<TokenizationTransactionParameters>, merchantId: string, params: TokenizationParameters) => new DatatransTokenizer(log, subscriber, merchantId, params)]
  ]);

  constructor(private http: HttpClient, private log: LogService) {
    this.http.get<TokenizationConfiguration>(`${environment.apiBaseUrl}/api/v1/reference-data/tokenization-config`, {})
      .subscribe(cfg => {
        Object.keys(cfg.libraries)
          .map(t => t as PciComplianceTypeEnum)
          .forEach(complianceType => this.tokenizerLibraries.set(complianceType, new TokenizerLibrary(cfg.libraries[complianceType])));
      });
  }

  initNoShow(profileType: ProfileTypeEnum, uuid: string, cardUuid: string, includeCvv: boolean, tfaCode: string): Observable<InitiateNoShowResponse> {
    let profileUrlType = profileType.toLowerCase();
    if (profileType === ProfileTypeConstant.CORPORATE) {
      profileUrlType = 'company';
    }

    const formData = new FormData();
    formData.append('includeCvv', String(includeCvv));
    formData.append('tfaCode', tfaCode);

    return this.http.post<InitiateNoShowResponse>(`${environment.apiBaseUrl}/api/v1/profiles/${profileUrlType}/${uuid}/creditcard/${cardUuid}/noshow`, formData);
  }

  /**
   * Init tokenization on click of a number and/or cvv element
   * @param type
   * @param merchantId
   * @param params
   * @return
   */
  initTokenization(type: PciComplianceTypeEnum, merchantId: string, params: TokenizationParameters): Observable<TokenizationTransactionParameters> {
    const lib = this.tokenizerLibraries.get(type);
    const helper = this.tokenizationHelpers.get(type);

    if (lib && helper) {
      return this.loadTokenizerLibrary(lib)
        .pipe(mergeMap(() => new Observable<TokenizationTransactionParameters>(subscriber => {
          const helperInstance = helper(this.log, subscriber, merchantId, params);
          helperInstance.startCreditCardTokenization();

          return () => helperInstance.destroy();
        })));
    } else {
      throw new Error(`Could not find PCI tokenization helper for ${type}`)
    }
  }

  getTokenizationResult(transactionParameters: TokenizationTransactionParameters, profileType: ProfileTypeEnum, parentUuid: string, mergeTransactionId?: string): Observable<InternalStoreCreditCardResponse> {
    const body = transactionParameters.parameters ?? {};
    if (mergeTransactionId) {
      body['mergeTransactionId'] = mergeTransactionId;
    }

    return this.http.post<InternalStoreCreditCardResponse>(`${environment.apiBaseUrl}/api/v1/profiles/creditcard/store/internal/${profileType.toLowerCase()}/${parentUuid}`, body);
  }

  private loadTokenizerLibrary(lib: TokenizerLibrary): Observable<void> {
    return new Observable<void>(subscriber => {
      if (lib.loaded || lib.src === '') {
        subscriber.next();
        subscriber.complete();
      } else {
        lib.subscribers.push(subscriber);

        if (lib.loading) {
          this.log.debug(`Concurrent request for external JS: ${lib.src}`, 'CreditCardTokenizationService');
        } else {
          this.log.info(`Loading external JS for Secure CC entry: ${lib.src}`, 'CreditCardTokenizationService');
          lib.loading = true;
          this.injectExternalScriptReference(lib);
        }
      }
    });
  }

  private injectExternalScriptReference(lib: TokenizerLibrary): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = lib.src;
    script.onload = () => {
      lib.loaded = true;
      lib.subscribers.forEach(s => {
        s.next();
        s.complete();
      });
      lib.subscribers = [];
    };

    script.onerror = e => {
      lib.subscribers.forEach(s => s.error(e));
      lib.loading = false;
      lib.subscribers = [];
    };
    document.head.appendChild(script);
  }
}

class TokenizerLibrary {
  loaded = false;
  loading = false;
  subscribers: Subscriber<void>[] = [];

  constructor(public src: string) {
  }
}
