import { BaseCircleCustomization } from '../base-circle-customization.class';
import { FormGroup } from '@angular/forms';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { RailCardTypeReferenceDefinition } from '@shared/models/coded-reference-definition';
import { Option, TypedOption } from '@shared/models/option';
import { RailPreferencesWagonTypeConstant } from '@shared/models/types.enum';
import { AccessService } from '@services/access.service';
import { AngularUtils } from '@shared/angular-utils.class';
import { ResiaFoodPreferenceDialogComponent } from './resia-foodpreference-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export class ResiaCustomization extends BaseCircleCustomization {

  private loadedPreferredFood?: string;

  constructor(private accessService: AccessService, private modalService: NgbModal) {
    super();
  }

  override customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    this.loadedPreferredFood = traveller?.data?.preferences?.preferredFood;
    AngularUtils.runOnNextTick(() => {
      formGroup.get('data.generalData.receiveDocs')?.setValue(true);
    });
  }

  override onTravellerSave(formGroup: FormGroup, callback: () => void): void {
    const preferredFood = formGroup.get('data.preferences.preferredFood')?.value;
    if (!this.loadedPreferredFood && preferredFood && this.accessService.hasAnyRole("ROLE_TRAVELLER")) {
      const modal = this.modalService.open(ResiaFoodPreferenceDialogComponent, {
        backdrop: 'static'
      });
      modal.closed.subscribe(() => callback());
    } else {
      callback();
    }
  }

  override customizeRailWagonTypes(railWagonTypes: Option[]): void {
    this.removeOption(RailPreferencesWagonTypeConstant.COMPARTMENT, railWagonTypes);
    this.removeOption(RailPreferencesWagonTypeConstant.PREFERWIDEBODY, railWagonTypes);
    this.removeOption(RailPreferencesWagonTypeConstant.REQUIREWIDEBODY, railWagonTypes);
  }

  override customizeRailCardTypes(railCardList: TypedOption<RailCardTypeReferenceDefinition>[]): void {
    this.removeOptionsWithoutCodePrefix(railCardList, 'SE_');
  }

  private removeOptionsWithoutCodePrefix(options: TypedOption<RailCardTypeReferenceDefinition>[] | undefined, keepprefix: string): void {
    if (options) {
      let itemIndex = options.findIndex(opt => !opt.key || !opt.key.code.startsWith(keepprefix));
      while (itemIndex >= 0) {
        options.splice(itemIndex, 1);
        itemIndex = options.findIndex(opt => !opt.key || !opt.key.code.startsWith(keepprefix));
      }
    }
  }


}
