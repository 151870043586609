import { LogService } from '@services/log-service.interface';
import { Subscriber } from 'rxjs';
import {
  FieldAndStyleConfiguration,
  TokenizationParameters,
  TokenizationProvider,
  TokenizationTransactionParameters
} from '@shared/pci/tokenization-provider.class';

declare const MidocoProxy: any;

export class MidocoTokenizer extends TokenizationProvider {

  private readonly sf: any;

  constructor(log: LogService, subscriber: Subscriber<TokenizationTransactionParameters>, merchantId: string, params: TokenizationParameters) {
    super(log, subscriber, merchantId, params);
    this.sf = new MidocoProxy.SecureFields();

    this.sf.on('validate', (event: any) => this.onValidate(event));
    this.sf.on('error', (data: any) => {
      // Midoco will encapsulate the error message within a "message" property whereas datatrans only rarely calls "error" with a message directly
      if (data) {
        if (data.message) {
          this.onError(data.message);
        } else if (data.sensitiveId && data.sensitiveId.length) {
          // Midoco reports an "error" if only CVV is stored, but includes the CVV in the error event, redirect that...
          this.fireSuccessCallback(data);
        } else {
          this.onError(data.toString());
        }
      } else {
        this.onError('');
      }
    });

    this.sf.on('change', (event: any) => {
      if (event.event.type === 'blur' && event.hasErrors) {
        const fields: FieldAndStyleConfiguration = {};
        let hasFieldData = false;

        if (typeof event.cardNumber === 'object') {
          hasFieldData = true;
          fields.cardNumber = event.cardNumber;
        }

        if (typeof event.cvv === 'object' && !event.cvv.valid) {
          hasFieldData = true;
          fields.cvv = event.cvv;
        }

        if (hasFieldData) {
          // Midoco does not call the validate callback, but our framework needs that -> channel up
          this.onValidate({
            hasErrors: true,
            fields: fields
          });
        }
      }

      this.onChange(event);
    });

    this.sf.on('ready', () => this.onReady());
    this.sf.on('success', (event: any) => {
      this.fireSuccessCallback(event);
    });
  }

  protected override initTokenize(fields: FieldAndStyleConfiguration, styles: FieldAndStyleConfiguration, focus?: string) {
    this.sf.initialize(this.merchantId, fields, {styles, focus});
  }

  protected override submitTokenizerFrame() {
    this.sf.submit();
  }

  private fireSuccessCallback(event: any): void {
    let additionalParameters: { [key: string]: string; } | undefined = undefined;

    // Midoco reports the CVV "id" directly in the json callback, extend server response accordingly
    if (event.sensitiveId && event.sensitiveId.length) {
      additionalParameters = {
        aliasCvv: event.sensitiveId
      };
    }

    this.onSuccess(event.tokenRefId ? String(event.tokenRefId) : '', additionalParameters);
  }
}
