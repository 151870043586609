import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '@environments/environment';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { LogService } from './log-service.interface';
import { CsvGenerationStatus } from '@models/csv-generation-status';
import { CsvUploadProgress } from '@models/csv-upload-progress';

class SubscriptionDetail {

  activeSubscription: any;

  constructor(public destination: string, public callback: (message: any) => void, public isBodyJson: boolean) {
  }
}

enum WsConnectionResult {
  NEW_CONNECTION_ESTABLISHED,
  ALREADY_CONNECTED
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketClientService implements OnDestroy {

  private static RECONNECT_DELAY_MIN = 3_000;
  private static RECONNECT_DELAY_MAX = 15_000;

  private subscriptionDetailMap = new Map<string, SubscriptionDetail>();
  private stompClient?: any;

  constructor(private ngZone: NgZone, private log: LogService) {
  }

  ngOnDestroy(): void {
    this.subscriptionDetailMap.clear();
    this.stompClient?.disconnect();
  }

  subscribePublishingUpdates(uuid: string, callback: (message: any) => void): void {
    this.connectAndSubscribe(`publishing-${uuid}`, new SubscriptionDetail(`/topic/publishing/${uuid}`, callback, false));
  }

  unsubscribePublishingUpdates(uuid: string): void {
    this.unsubscribe(`publishing-${uuid}`);
  }

  subscribeCsvGeneration(id: string, callback: (message: CsvGenerationStatus) => void): void {
    this.connectAndSubscribe(`csv-gen-${id}`, new SubscriptionDetail(`/topic/csv/generation/${id}`, callback, true));
  }

  unsubscribeCsvGeneration(id: string): void {
    this.unsubscribe(`csv-gen-${id}`);
  }

  subscribeCsvUpload(id: string, callback: (message: CsvUploadProgress) => void): void {
    this.connectAndSubscribe(`csv-upl-${id}`, new SubscriptionDetail(`/topic/csv/upload/${id}`, callback, true));
  }

  unsubscribeCsvUpload(id: string): void {
    this.unsubscribe(`csv-upl-${id}`);
  }

  /**
   * Subscribe to a destination with a callback function for which we'll ensure to dispatch WITHIN the angular zone
   * so updates to UI-Elements will be detected by change detection
   *
   * Transparently starts websocket connection if needed
   */
  private connectAndSubscribe(id: string, subscription: SubscriptionDetail): void {
    this.subscriptionDetailMap.set(id, subscription);
    if (this.reConnectIfNecessary() === WsConnectionResult.ALREADY_CONNECTED) {
      // Connection was already established, subscribe immediately
      this.doSubscribe(id, subscription);
    }
  }

  private doSubscribe(topicId: string, subscription: SubscriptionDetail): void {
    subscription.activeSubscription = this.stompClient.subscribe(subscription.destination, (msg: any) => {
      const body = subscription.isBodyJson ? JSON.parse(msg.body) : msg.body;
      this.ngZone.runGuarded(() => subscription.callback(body));
    }, {id: topicId});
  }

  private unsubscribe(topicId: string): void {
    const subscriptionDetail = this.subscriptionDetailMap.get(topicId);
    this.subscriptionDetailMap.delete(topicId);

    if (subscriptionDetail?.activeSubscription?.unsubscribe) {
      subscriptionDetail.activeSubscription.unsubscribe();
    }

    if (this.subscriptionDetailMap.size === 0) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(this.disconnectIfIdle.bind(this), 30_000);
      });
    }
  }

  private disconnectIfIdle(): void {
    if (this.stompClient && this.subscriptionDetailMap.size === 0) {
      this.log.debug('Disconnecting WebSocket due to no more active subscriptions');
      this.disconnect();
    }
  }

  private disconnect(): void {
    this.stompClient.disconnect();
    this.stompClient = undefined;
  }

  private reConnectIfNecessary(): WsConnectionResult {
    if (!this.stompClient?.connected) {
      this.ngZone.runOutsideAngular(() => {
        this.initializeWebSocketConnection();
      });

      return WsConnectionResult.NEW_CONNECTION_ESTABLISHED;
    }

    return WsConnectionResult.ALREADY_CONNECTED;
  }

  private initializeWebSocketConnection(): void {
    // the URL must match endpoint configured in WebSocketConfig. Note that replacing 'https' will result in 'wss'
    const wsEndpoint = this.toAbsoluteUrl('ws');

    /*
     * For SockJS you need to set a factory that creates a new SockJS instance to be used for each (re)connect
     * Note that we're always adding SockJS, which will try to use WebSocket in the first place but fall back to other
     * means of transport if the websocket fails
     */
    this.stompClient = Stomp.over(function () {
      return new SockJS(wsEndpoint, null, {
        transports: ['websocket', 'xhr-streaming', 'xhr-polling']
      });
    });

    // https://github.com/stomp-js/stompjs/issues/335 Work around connections being severed due to Chrome deep sleep
    this.stompClient.heartbeatOutgoing = 60_000;
    this.stompClient.heartbeatIncoming = 60_000;

    const reconnectDelay = Math.floor(Math.random() * (WebSocketClientService.RECONNECT_DELAY_MAX - WebSocketClientService.RECONNECT_DELAY_MIN + 1) + WebSocketClientService.RECONNECT_DELAY_MIN);
    this.stompClient.reconnect_delay = reconnectDelay;

    this.log.info(`Initializing STOMP with automatic reconnect delay of ${reconnectDelay}ms`);
    // headers, connectCallback, errorCallback
    this.stompClient.connect({}, this.clientConnected.bind(this), this.clientError.bind(this));
  }

  private toAbsoluteUrl(path: string): string {
    // document.location.href allows us to build a URL even if apiBaseUrl is relative rather than an absolute URL
    return new URL(`${environment.apiBaseUrl}/${path}`, document.location.href).href;
  }

  private clientConnected(): void {
    this.log.debug(`Connected web socket client, connecting ${this.subscriptionDetailMap.size} existing subscriptions`);
    this.subscriptionDetailMap.forEach((sub, id) => this.doSubscribe(id, sub));
  }

  private clientError(): void {
    this.log.debug('Encountered web socket error - re-establishing connection if necessary');
    this.disconnect();
    this.reConnectIfNecessary();
  }

}
