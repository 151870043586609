<div class="modal-header">
  <h2 class="modal-title">LDAP HR-Feed Konfiguration</h2>
</div>
<div class="modal-body" *ngIf="form">
  <p>Reisende folgender Organisationen werden dieser Firma zugeteilt:</p>
  <form [formGroup]="form">
    <ng-container formArrayName="oeConfigItems">
      <div class="row row-striped">
        <div class="col col-sm-7 col-header">Name (DN)</div>
        <div class="col col-sm-3 col-header">Inkl. Untergeordnete</div>
        <div class="col col-sm-2 col-header"></div>
      </div>
      <div *ngFor="let oeGrp of oeFormArray.controls; let i = index" class="row row-striped" [formGroupName]="i">
        <div class="col col-sm-7 text-break">
          <div>{{getDisplayValue(oeGrp, 'dn')}}</div>
          <div class="ldap-subsidiary-exclusion" *ngIf="isIncludingSubsidiaries(oeGrp)">
            <div>
              <input type="checkbox" formControlName="hasExcludedDns" class="form-check-input" />
              Spezifische untergeordnete Ausschliessen
            </div>
            <div *ngIf="isExcludingDns(oeGrp)" formArrayName="excludedSubsidiaries" class="background-reset">
              <div class="row row-striped">
                <div class="col col-sm-9 col-header">Name (DN)</div>
                <div class="col col-sm-3 col-header"></div>
              </div>
              <div *ngFor="let excludeDnsControl of getExcludeDnsFormArray(oeGrp).controls; let exIdx = index" class="row row-striped" [formGroupName]="i">
                <div class="col col-sm-9">{{getDisplayValue(excludeDnsControl)}}</div>
                <div class="col col-sm-3">
                  <button type="button" class="btn btn-delete btn-icon" (click)="deleteExcludeDn(oeGrp, exIdx)">
                    <i class="bi bi-trash3" i18n-title="@@general.remove" title="Remove"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col col-sm-9">
                  <app-brz-ldapname-typeahead [control]="oeGrp.get('excludedDnLookup')"
                    [parentDn]="oeGrp.get('dn')?.value"></app-brz-ldapname-typeahead>
                </div>
                <div class="col col-sm-3">
                  <button type="button" class="btn btn-sm btn-primary" (click)="addExcludedDn(oeGrp)">+ <span i18n="@@general.addnew">Add</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-3">
          <input type="checkbox" formControlName="includeSubsidiaries" class="form-check-input" />
        </div>
        <div class="col col-sm-2">
          <button type="button" class="btn btn-delete btn-icon" (click)="deleteOeItem(i)">
            <i class="bi bi-trash3" i18n-title="@@general.remove" title="Remove"></i>
          </button>
        </div>
      </div>
    </ng-container>
    <div class="row" formGroupName="newEntry">
      <div class="col col-sm-7">
        <app-brz-ldapname-typeahead [control]="ldapLookupControl"></app-brz-ldapname-typeahead>
      </div>
      <div class="col col-sm-3">
        <input type="checkbox" formControlName="includeSubsidiaries" class="form-check-input" />
      </div>
      <div class="col col-sm-2">
        <button type="button" class="btn btn-sm btn-primary" (click)="addOeItem()">+ <span i18n="@@general.addnew">Add</span></button>
      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary uf-brzldapdialog-cancel" (click)="submitNewFieldValue()"
    i18n="@@general.close">Close</button>
</div>
