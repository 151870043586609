import { FormGroup } from "@angular/forms";
import { BaseCircleCustomization } from "../base-circle-customization.class";
import { TravellerDetailResponse } from "@shared/models/traveller-detail-response";
import { CircleValidators } from "../circle-validators.class";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccessService } from "@services/access.service";
import { Component } from "@angular/core";

@Component({
  template: `
  <div class="modal-header">
      <h2 class="modal-title">Date of Birth Agreement</h2>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger">
      <p>Warning: We strongly recommend you enter a correct Date of Birth (DOB). By not entering a correct DOB, I confirm that;</p>
      <p>a. This profile being created is for a person aged 16 years or above, in compliance with article 8 of GDPR legislation</p>
      <p>b. A correct DOB is required to make bookings to certain countries ( e.g. USA and Canada ). I accept responsibility for the communication of the DOB to my Travel Consultant in the event it is required</p>
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-danger pull-right" (click)="modal.close()">Confirm</button>
    <button class="btn btn-danger pull-right" (click)="modal.dismiss()">Return</button>
  </div>
`
})
export class AtlasTravelDobDialogComponent {
  constructor(public modal: NgbActiveModal) { }
}

export class AtlasTravelCustomization extends BaseCircleCustomization {

  constructor(private accessService: AccessService, private modalService: NgbModal) {
    super();
  }

  override customizeTravellerFormGroup(_traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    super.addValidators(formGroup.get('data.generalData.birthdate'), CircleValidators.ctAdult);
  }

  override onTravellerSave(formGroup: FormGroup, callback: () => void): void {
    const birthDate = formGroup.get('data.generalData.birthdate')?.value;
    if (!birthDate && this.accessService.hasAnyRole("ROLE_COMPANY_MANAGER", "ROLE_TRAVELLER")) {
      const modal = this.modalService.open(AtlasTravelDobDialogComponent, {
        backdrop: 'static'
      });
      modal.closed.subscribe(() => callback());
    } else {
      callback();
    }
  }

}
