import { Directive, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { GenericValueWithSetup } from "@shared/models/generic-value";

@Directive()
export abstract class BaseGenericValueWithSetupComponent {

  @Input() valueWithSetup: GenericValueWithSetup;
  @Input() valueWithSetupFormGroup: UntypedFormGroup;

  get controlId(): string {
    return `uf-genericvalue-${this.valueWithSetup?.setup?.uuid}`;
  }

}
