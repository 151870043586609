import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '@guards/access.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'public',
    loadChildren: () => import('./feature-public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'profiles',
    loadChildren: () => import('./feature-profiles/profiles.module').then(m => m.ProfilesModule),
    canLoad: [AccessGuard], canActivate: [AccessGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./feature-admin/admin.module').then(m => m.AdminModule),
    canLoad: [AccessGuard], canActivate: [AccessGuard]
  },
  {
    path: 'agency',
    loadChildren: () => import('./feature-agency/agency.module').then(m => m.AgencyModule),
    canLoad: [AccessGuard], canActivate: [AccessGuard]
  },
  {
    path: 'profilecenter',
    loadChildren: () => import('./feature-profile-center/profile-center.module').then(m => m.ProfileCenterModule),
    canLoad: [AccessGuard], canActivate: [AccessGuard]
  },
  {
    path: 'info',
    loadChildren: () => import('./feature-info/info.module').then(m => m.InfoModule),
    canLoad: [AccessGuard], canActivate: [AccessGuard]
  },
  {path: '', redirectTo: '/profiles/traveller', pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
