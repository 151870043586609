import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { setError } from 'src/app/store-root/actions';
import { FacesState } from 'src/app/store-root/faces-state';
import { FieldValidationError } from '@shared/models/field-validation-error';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogConfirmActionComponent, DialogConfirmActionUseCase } from '@shared/dialog-confirm-action/dialog-confirm-action.component';
import { RequestDroppedError } from '../interceptors/auth.interceptor';

export enum ApplicationErrorLevel {
  None, Info, Warning, Error
}

export class ApplicationError implements Error {
  readonly name = 'ApplicationError';

  constructor(
    public errorLevel = ApplicationErrorLevel.Info,
    public message = '',
    public technicalDetails?: string,
    public validationErrors?: FieldValidationError[]
  ) { }
}

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandlerService extends ErrorHandler {

  private get store(): Store<FacesState> {
    return this.injector.get(Store);
  }

  private get modalService(): NgbModal {
    return this.injector.get(NgbModal);
  }

  // can't inject Store directly here, this would lead to a 'Circular dependency'
  constructor(private injector: Injector) {
    super();
  }

  /**
   * Handle errors thrown within the SPA.
   * @param error
   */
  override handleError(error: any): void {
    if (this.isIgnoreError(error)) {
      return;
    }

    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      // New UI version has been deployed, we're screwed
      const modalRef = this.modalService.open(DialogConfirmActionComponent);
      modalRef.componentInstance.useCase = DialogConfirmActionUseCase.PAGE_RELOAD;
      modalRef.result.then(() => window.location.reload());
      return;
    }

    const applicationError = this.getApplicationError(error);
    this.store.dispatch(setError({
      error: applicationError
    }));
    super.handleError(error);
  }

  private isIgnoreError(error: any): boolean {
    if (error instanceof RequestDroppedError) {
      return true;
    }

    if (error.stack && /midoco-proxy.js/.test(error.stack)) {
      // Unfortunately the midoco proxy code often tries to work with no longer existing iframes / windows and has no way of us catching these errors
      return true;
    }

    return false;
  }

  private getApplicationError(error: any): ApplicationError {
    const appError = new ApplicationError(ApplicationErrorLevel.Error, error.message);
    if (error instanceof HttpErrorResponse) {
      this.populateHttpErrorResponse(error, appError);
    } else if (!appError.message && error.name) {
      appError.message = '' + error.name;
    }
    if (!appError.message) {
      appError.message = (typeof error === 'string') ? error : JSON.stringify(error);
    }
    return appError;
  }

  private populateHttpErrorResponse(errorResponse: HttpErrorResponse, appError: ApplicationError): void {
    if (typeof errorResponse.error === 'object') {
      if (errorResponse.error.validationErrors && errorResponse.error.validationErrors.length) {
        appError.validationErrors = errorResponse.error.validationErrors;
        appError.message = [... new Set(appError.validationErrors?.map(err => err.message))].join(', ')
      } else {
        appError.message = errorResponse.error.message;
      }
    } else {
      appError.message = `Our server was not able to process that request, the reason is: ${appError.message ?? ''}`;
    }
  }

}
