import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { RoleSearchResponse } from '@shared/models/role-search-result';
import { Role } from '@shared/models/role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) {
  }

  search(params: {
    q: string;
    page?: number;
    pageSize?: number
  }): Observable<RoleSearchResponse> {
    let httpParams = new HttpParams()//
      .set('q', params.q)
      .set('pageSize', params.pageSize || 25);
    if (params.page !== undefined) {
      httpParams = httpParams.set('page', params.page);
    }
    return this.http.get<RoleSearchResponse>(`${environment.apiBaseUrl}/api/v1/roles`, {params: httpParams});
  }

  read(uuid: string): Observable<Role> {
    return this.http.get<Role>(`${environment.apiBaseUrl}/api/v1/role/${uuid}`);
  }

  save(uuid: string | undefined, request: Role): Observable<Role> {
    this.prepareRequestForApi(uuid, request);
    if (uuid) {
      return this.http.patch<Role>(`${environment.apiBaseUrl}/api/v1/role/${uuid}`, request);
    }
    return this.http.post<Role>(`${environment.apiBaseUrl}/api/v1/role`, request);
  }

  delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/role/${uuid}`);
  }

  private prepareRequestForApi(uuid: string | undefined, request: Role): void {
    if (uuid) {
      // Delete read-only properties
      delete request.agency;
      delete request.circle;
    }
  }

}
