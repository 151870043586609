import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectPrincipal } from 'src/app/store-root/selectors';
import { AccessService } from './access.service';

/**
 * This class serves as an intermediate between the UserLoginService and AuthenticationInterceptor,
 * holding the current OAuth tokens from the ngrx store, but providing a mechansim to overwrite
 * those tokens in case of an authentication process outside of ngrx.
 */
@Injectable({
  providedIn: 'root'
})
export class OauthTokenService implements OnDestroy {

  get accessToken(): string | undefined {
    return this.internalAccessToken;
  }

  get accessTokenExpired(): boolean {
    return !!this.internalAccessTokenExpiration && (this.internalAccessTokenExpiration < new Date());
  }

  get refreshToken(): string | undefined {
    return this.internalRefreshToken;
  }

  get impersonated(): boolean {
    return this.internalImpersonated;
  }

  private internalAccessToken?: string;
  private internalAccessTokenExpiration?: Date;
  private internalRefreshToken?: string;
  private internalImpersonated = false;
  private principal$: Subscription;

  constructor(private store: Store<FacesState>) {
    this.principal$ = this.store.select(selectPrincipal)
      .subscribe(p => {
        this.internalAccessToken = p?.accessToken;
        this.internalRefreshToken = p?.refreshToken;
        this.internalAccessTokenExpiration = p?.accessTokenExpiration;
        this.internalImpersonated = AccessService.hasAnyRole(p, 'ROLE_PREVIOUS_ADMINISTRATOR');
      });
  }

  ngOnDestroy(): void {
    this.principal$.unsubscribe();
  }

  setTokens(accessToken: string, accessTokenExpiration: Date | undefined, refreshToken: string): void {
    this.internalAccessToken = accessToken;
    this.internalAccessTokenExpiration = accessTokenExpiration;
    this.internalRefreshToken = refreshToken;
  }

}
