import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserInterfaceStyleService } from "@services/user-interface-style.service";
import { Observable, filter, map, mergeMap } from "rxjs";
import { setPrincipal, setUserInterfaceStyle } from "./actions";

@Injectable()
export class RootEffect {

  constructor(private actions$: Actions,
    private userInterfaceStyleService: UserInterfaceStyleService) { }

  principalUserInterfaceStyle$: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(setPrincipal),
    filter(principalPayload => !!principalPayload.principal?.travelagencyUuid),
    mergeMap( ( principalPayload ) => this.userInterfaceStyleService.getAgencyStyle(principalPayload.principal?.travelagencyUuid || '')
      .pipe(map(style => setUserInterfaceStyle({ style: style})))
    )
  ));
}

