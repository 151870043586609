import { Injectable } from '@angular/core';
import { combineLatestWith, filter, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { User } from '@shared/models/user.class';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { ObjectUtils } from '@shared/object-utils.class';
import { FacesState } from 'src/app/store-root/faces-state';
import { Store } from '@ngrx/store';
import { setPrincipal } from 'src/app/store-root/actions';
import { selectPrincipal, selectUserInterfaceStyle } from 'src/app/store-root/selectors';
import { SimpleServiceResponse } from '@models/simple-service-response';
import { OauthTokenService } from './oauth-token.service';
import { URLUtils } from '@shared/url-utils.class';

interface StoredOauthState {
  state: string;
  returnUrl?: string;
}

interface LogoutFromServerResponse {
  previousUser?: User,
  redirectData?: { targetUrl?: string, samlResponse?: string }
}

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  static readonly NONCE_PARAM_NAME = 'authNonce';
  static readonly RETURN_URL_PARAM_NAME = 'returnUrl';

  private static OAUTH_STATE_KEY = 'faces.oauth.state';
  private static PRINCIPAL_KEY = 'faces.oauth.principal';
  private static LAST_USED_STYLE_KEY = 'faces.style';
  // this must match OnDemandProfileController.OAUTH_SCOPE_SPA
  private static OAUTH_SCOPE = 'faces:ui';
  private static LOGIN_PATH = '/public/login';
  private static TOKEN_TTL_FUZZINESS = 5;

  constructor(private router: Router,
    private http: HttpClient,
    private store: Store<FacesState>,
    private oauthTokenService: OauthTokenService) { }

  /**
   * Loading the principal (along with a store.dispatch()) can cause a
   * <pre>Circular dependency in DI</pre>
   * so only call this on init +after+ construction
   */
  initFromLocalStorage() {
    this.store.dispatch(setPrincipal({ principal: this.loadPersistedPrincipalOnApplicationStart() }));

    /*
     * Remember last used style, so we can direct to the correct login page on session expiry
     * Note that we're only storing the style if we have a principal to go along with it since otherwise we would clear it on logout!
     */
    this.store.select(selectUserInterfaceStyle).pipe(
      combineLatestWith(this.store.select(selectPrincipal)),
      filter(([_style, principal]) => principal !== undefined),
      map(([style, _principal]) => style?.uuid)
    ).subscribe(styleUuid => this.saveLastUsedStyle(styleUuid));
  }

  startAuthorizationFlow(returnUrl?: string): void {
    const storedState: StoredOauthState = {
      state: ObjectUtils.newGuid(),
      returnUrl: returnUrl
    };

    this.saveOAuthState(storedState);
    const redirectUrl = this.getOauthRedirectUrl(returnUrl);

    let httpParams = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', environment.spaClientId)
      .set('redirect_uri', redirectUrl)
      .set('state', storedState.state)
      .set('scope', UserLoginService.OAUTH_SCOPE);

    const style = localStorage.getItem(UserLoginService.LAST_USED_STYLE_KEY);
    if (style) {
      httpParams = httpParams.set('style', style);
    }

    window.location.href = URLUtils.buildAbsoluteUrl(`${environment.serverUiBaseUrl ?? environment.apiBaseUrl}/oauth/authorize?${httpParams.toString()}`).href;
    // note: the OAuth redirect will go into login.component
  }

  /**
   * Get an OAuth access token using the provided authorization code. Beware that this call needs
   * CORS to be set up properly!
   */
  getAccessToken(code: string, state: string, overrideReturnUrlFromServer?: string): Observable<User> {
    let storedState = this.getOAuthState();
    if (state === '') {
      // Server-Initiated login will send an empty state, reset storedState so the redirect url will match
      storedState = undefined;
    } else if (state !== storedState?.state) {
      throw new Error(`Illegal access (${state} / ${storedState?.state})`);
    }
    this.saveOAuthState();

    const redirectUrl = this.getOauthRedirectUrl(overrideReturnUrlFromServer ?? storedState?.returnUrl);
    const httpParams = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('redirect_uri', redirectUrl);
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + window.btoa(environment.spaClientId + ':' + environment.spaClientSecret)
    });
    return this.http.post<any>(`${environment.apiBaseUrl}/oauth/token`, httpParams, { headers: headers })
      .pipe(map((oauthResponse: any) => this.registerNewUser(oauthResponse)));
  }

  refreshAccessToken(): Observable<User> {
    return this.store.select(selectPrincipal)
      .pipe(
        take(1),
        mergeMap(principal => {
          if (principal?.originatingFrom) {
            // impersonated, do a deswitch + switch
            const switchTargetUserUuid = principal?.uuid;
            const adminUser = principal.originatingFrom;
            this.oauthTokenService.setTokens(adminUser.accessToken, adminUser.accessTokenExpiration, adminUser.refreshToken);
            return this.postRefreshToken(adminUser.refreshToken)
              .pipe(
                mergeMap(oauthResponse => {
                  const refreshedAdmin = ObjectUtils.deepClone(adminUser);
                  refreshedAdmin.accessToken = oauthResponse.access_token;
                  if (oauthResponse.expires_in) {
                    refreshedAdmin.accessTokenExpiration = new Date();
                    refreshedAdmin.accessTokenExpiration.setSeconds(refreshedAdmin.accessTokenExpiration.getSeconds() + oauthResponse.expires_in - UserLoginService.TOKEN_TTL_FUZZINESS);
                  } else {
                    refreshedAdmin.accessTokenExpiration = undefined;
                  }
                  refreshedAdmin.refreshToken = oauthResponse.refresh_token;
                  this.oauthTokenService.setTokens(refreshedAdmin.accessToken, refreshedAdmin.accessTokenExpiration, refreshedAdmin.refreshToken);
                  return this.getSwitchedUserToken(switchTargetUserUuid, refreshedAdmin)
                    .pipe(
                      tap(user => this.registerNewUser(user))
                    );
                })
              );
          }
          return this.postRefreshToken(principal?.refreshToken)
            .pipe(map((oauthResponse: any) => this.registerNewUser(oauthResponse)));
        })
      );
  }

  /**
   * Forget the current token / principal. If a User#originatingFrom is defined, that User
   * is restored. Otherwise, the current principal is set to undefined.
   */
  forgetToken(doServerSideLogout = true): void {
    this.store.select(selectPrincipal).pipe(
      // Select the current principal and find the originating user (if present)
      take(1),
      // Terminate backend sessions (if needed), then return originating user (if present)
      mergeMap((currentUser) => this.logoutFromServerIfNoPreviousUser(doServerSideLogout, currentUser))
    ).subscribe(logoutState => {
      this.savePrincipal(logoutState.previousUser);
      this.store.dispatch(setPrincipal({ principal: logoutState.previousUser }));

      if (logoutState.previousUser) {
        this.router.navigate(['/']);
      } else if (logoutState.redirectData) {
        this.router.navigate(['/public/redirect-logout'], {
          state: {
            'redirectData': logoutState.redirectData
          }
        });
      } else {
        this.router.navigate([UserLoginService.LOGIN_PATH]);
      }
    });
  }

  switchUser(uuid: string): void {
    this.store.select(selectPrincipal)
      .pipe(
        take(1),
        switchMap(originalUser => this.getSwitchedUserToken(uuid, originalUser))
      )
      .subscribe(user => this.registerNewUser(user));
  }

  sendCredentials(uuid: string, email?: string): Observable<SimpleServiceResponse> {
    let httpParams = new HttpParams()
      .set('uuid', uuid);
    if (email) {
      httpParams = httpParams.set('email', email);
    }
    return this.http.post<SimpleServiceResponse>(`${environment.apiBaseUrl}/api/v1/sendcredentials`, httpParams);
  }

  getSilentLoginNonce(): Observable<{ nonce: string; username: string }> {
    return this.http.post<{ nonce: string; username: string }>(`${environment.apiBaseUrl}/api/oauth/nonce`, {});
  }

  ping(): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${environment.apiBaseUrl}/api/v1/ping`, {});
  }

  private getOauthRedirectUrl(returnUrl?: string): string {
    const publicPathPosition = document.location.href.indexOf('/public');
    const baseUrl = document.location.href.substring(0, publicPathPosition);
    const redirectUrl = new URL(`${baseUrl}${UserLoginService.LOGIN_PATH}`);
    if (returnUrl) {
      redirectUrl.searchParams.set(UserLoginService.RETURN_URL_PARAM_NAME, returnUrl);
    }

    return redirectUrl.href;
  }

  private logoutFromServerIfNoPreviousUser(doServerSideLogout: boolean, currentUser?: User): Observable<LogoutFromServerResponse> {
    const returnValue: LogoutFromServerResponse = {
      previousUser: currentUser?.originatingFrom
    };

    if (!currentUser || currentUser.originatingFrom || !doServerSideLogout) {
      /*
       * One of the following scenarios is taking place:
       *  - no current principal for which we can logout
       *  - we've been impersonating
       *  - we've run into a refresh token related error and want to avoid an endless error -> logout due to error -> error -> logout.... loop
       */
      return of(returnValue);
    }

    return this.http.post<{ targetUrl?: string, samlResponse?: string }>(`${environment.apiBaseUrl}/api/oauth/logout`, {})
      .pipe(
        map(redirectData => {
          returnValue.redirectData = redirectData;
          return returnValue;
        }),
        catchError(() => of(returnValue))
      );
  }

  private postRefreshToken(refreshToken?: string): Observable<any> {
    const httpParams = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken ?? '');
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + window.btoa(environment.spaClientId + ':' + environment.spaClientSecret)
    });
    return this.http.post<any>(`${environment.apiBaseUrl}/oauth/token`, httpParams, { headers: headers });
  }

  private registerNewUser(oauthResponse: any): User {
    const user = ObjectUtils.deepClone(oauthResponse.principal) as User;
    user.accessToken = oauthResponse.access_token;
    //"expires_in": 899, // TTL in seconds of the access token
    if (oauthResponse.expires_in) {
      user.accessTokenExpiration = new Date();
      user.accessTokenExpiration.setSeconds(user.accessTokenExpiration.getSeconds() + oauthResponse.expires_in - UserLoginService.TOKEN_TTL_FUZZINESS) ;
    } else {
      user.accessTokenExpiration = undefined;
    }
    user.refreshToken = oauthResponse.refresh_token;
    user.refreshTokenExpirationTimestamp = Date.now() + (oauthResponse.refresh_token_expires_in - 90) * 1000;
    this.store.dispatch(setPrincipal({ principal: user }));
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    this.savePrincipal(user);
    return user;
  }

  private saveOAuthState(s?: StoredOauthState): void {
    if (s) {
      localStorage.setItem(UserLoginService.OAUTH_STATE_KEY, JSON.stringify(s));
    } else {
      localStorage.removeItem(UserLoginService.OAUTH_STATE_KEY);
    }
  }

  private getOAuthState(): StoredOauthState | undefined {
    const state = localStorage.getItem(UserLoginService.OAUTH_STATE_KEY);
    if (state) {
      return JSON.parse(state);
    }

    return undefined;
  }

  private savePrincipal(user: User | undefined): void {
    if (user) {
      localStorage.setItem(UserLoginService.PRINCIPAL_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(UserLoginService.PRINCIPAL_KEY);
    }
  }

  private loadPersistedPrincipalOnApplicationStart(): User | undefined {
    const userSerialized = localStorage.getItem(UserLoginService.PRINCIPAL_KEY);
    if (userSerialized) {
      const parsedUser = JSON.parse(userSerialized) as User;

      // Makes no sense to load a persisted user for which access has expired anyway
      if (parsedUser.originatingFrom || parsedUser.refreshTokenExpirationTimestamp > Date.now()) {
        if (parsedUser.accessTokenExpiration) {
          // date is JSON.stringified as string, so need to re-parse as date
          parsedUser.accessTokenExpiration = new Date(`${parsedUser.accessTokenExpiration}`);
        }
        return parsedUser;
      }
    }
    return undefined;
  }

  private getSwitchedUserToken(uuid: string, currentUser?: User): Observable<User> {
    const httpParams = new HttpParams()
      .set('uuid', uuid);
    return this.http.post<any>(`${environment.apiBaseUrl}/api/v1/switchcredentials`, httpParams)
      .pipe(map(user => {
        // user will have user > principal > authorities[ROLE_PREVIOUS_ADMINISTRATOR] > source
        // with the originating principal object, but - crucially - without the access token for
        // that user. We need not just the principal object, but the wrapping authentication object.
        user.principal.originatingFrom = currentUser;
        return user;
      }));
  }

  private saveLastUsedStyle(uuid?: string): void {
    if (uuid) {
      localStorage.setItem(UserLoginService.LAST_USED_STYLE_KEY, uuid);
    } else {
      localStorage.removeItem(UserLoginService.LAST_USED_STYLE_KEY);
    }
  }
}
