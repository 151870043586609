import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { UserInterfaceStyleDto, UserInterfaceStyleSearchResponse } from '@shared/models/user-interface-style-dto';

@Injectable({
  providedIn: 'root'
})
export class UserInterfaceStyleService {

  constructor(private http: HttpClient) { }

  search(params: {
    q: string;
    page?: number;
    pageSize?: number
  }): Observable<UserInterfaceStyleSearchResponse> {
    let httpParams = new HttpParams()//
      .set('q', params.q)
      .set('pageSize', params.pageSize || 25);
    if (params.page !== undefined) {
      httpParams = httpParams.set('page', params.page);
    }
    return this.http.get<UserInterfaceStyleSearchResponse>(`${environment.apiBaseUrl}/api/v1/styles`, {params: httpParams});
  }

  getStyles(circle: string): Observable<UserInterfaceStyleSearchResponse> {
    return this.http.get<UserInterfaceStyleSearchResponse>(`${environment.apiBaseUrl}/api/v1/styles/${circle}`);
  }

  getAgencyStyle(agencyUuid: string): Observable<UserInterfaceStyleDto | undefined> {
    return this.http.get<UserInterfaceStyleDto>(`${environment.apiBaseUrl}/api/v1/style/agency/${agencyUuid}`);
  }

  read(uuid: string): Observable<UserInterfaceStyleDto> {
    return this.http.get<UserInterfaceStyleDto>(`${environment.apiBaseUrl}/api/v1/style/${uuid}`);
  }

  save(uuid: string | undefined, request: UserInterfaceStyleDto, file?: File): Observable<UserInterfaceStyleDto> {
    const formData = new FormData();
    if (file) {
      formData.append('logoFile', file, file.name);
    }
    formData.append('dto', new Blob([JSON.stringify(request)], {
      type: 'application/json'
    }));
    if (uuid) {
      return this.http.patch<UserInterfaceStyleDto>(`${environment.apiBaseUrl}/api/v1/style/${uuid}`, formData);
    }
    return this.http.post<UserInterfaceStyleDto>(`${environment.apiBaseUrl}/api/v1/style`, formData);
  }

  delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/style/${uuid}`);
  }

}
