import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfilesState } from './profiles-state';

// note to devs: when adding selectors, make sure to select on 'state?' so the selector works if the 'profiles'
// module has not yet been loaded (and just returns undefined)
const getFeatureState = createFeatureSelector<ProfilesState>('profiles');
export const selectCurrentTravellerUuid = createSelector(getFeatureState, (state: ProfilesState) => state?.currentTravellerUuid);
export const selectCompanyUuidOfTraveller = createSelector(getFeatureState, (state: ProfilesState) => state?.companyUuidOfTraveller);
export const selectCurrentCompanyUuid = createSelector(getFeatureState, (state: ProfilesState) => state?.currentCompany?.uuid);
export const selectCompanyContextForTraveller = createSelector(getFeatureState, (state: ProfilesState) => state?.companyContextForTraveller);
export const selectAgencyContextForCompany = createSelector(getFeatureState, (state: ProfilesState) => state?.agencyContextForCompany);

export const selectRailIdentificationCardOptions = createSelector(getFeatureState, (state: ProfilesState) => state?.railIdentificationCardOptions);
export const selectProfileCreditcards = createSelector(getFeatureState, (state: ProfilesState) => state?.creditcards);
