import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CtmNoramUserConfigDialogComponent } from './ctm-noram-user-config-dialog/ctm-noram-user-config-dialog.component';
import { Store } from '@ngrx/store';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectCurrentCompanyUuid, selectProfileCreditcards } from 'src/app/feature-profiles/store-feature/profiles-selectors';
import { MaskedCreditCardDetails } from '@shared/models/masked-credit-card-details';
import { CircleBackendService } from '@services/circle-backend.service';
import { Subscription, filter, mergeMap, take } from 'rxjs';
import { UserGroup } from './ctm-noram-models';

@Component({
  template: `
  <div [formGroup]="valueWithSetupFormGroup" *ngIf="userGroups?.length">
    <button class="btn btn-xs btn-secondary" (click)="openConfigurationDialog()">Configure</button>
    <input type="hidden" class="form-control" [attr.id]="controlId" formControlName="value">
  </div>
  <div *ngIf="!userGroups?.length">n/a</div>
`
})
export class CtmNoramUserGroupConfigComponent extends BaseGenericValueWithSetupComponent implements OnInit, OnDestroy {

  private creditcards: MaskedCreditCardDetails[];
  private creditcards$: Subscription;
  userGroups: UserGroup[];

  constructor(private store: Store<FacesState>, private modalService: NgbModal,
    private circleBackendService: CircleBackendService) {
    super();
  }

  ngOnInit(): void {
    this.creditcards$ = this.store.select(selectProfileCreditcards)
      .subscribe(cards => {
        this.creditcards = cards?.filter(card => !!card.uuid) || [];
      });
    this.store.select(selectCurrentCompanyUuid)
      .pipe(
        take(1),
        filter(uuid => !!uuid),
        mergeMap(uuid => this.circleBackendService.getCtmNoramUserGroups(uuid || ''))
      )
      .subscribe(settings => this.userGroups = settings['userGroups']);
  }

  ngOnDestroy(): void {
    this.creditcards$.unsubscribe();
  }

  openConfigurationDialog(): void {
    const fieldValue = this.valueWithSetupFormGroup.get('value')?.value;
    const modalRef = this.modalService.open(CtmNoramUserConfigDialogComponent, { size: 'xl' });
    modalRef.componentInstance.initialItems = fieldValue ? JSON.parse(fieldValue) : [];
    modalRef.componentInstance.creditcards = this.creditcards;
    modalRef.componentInstance.allUserGroups = this.userGroups;
    modalRef.closed.subscribe((groupConfig) => this.updateInteralValue(groupConfig));
    modalRef.dismissed.subscribe((groupConfig) => this.updateInteralValue(groupConfig));
  }

  private updateInteralValue(ldapJson: any) {
    const stringValue = ldapJson ? JSON.stringify(ldapJson, ['gid', 'ccid', 'pp']) : '{}';
    const formValue = this.valueWithSetupFormGroup.get('value');
    formValue?.setValue(stringValue);
    formValue?.markAsDirty();
    this.valueWithSetupFormGroup.get('field')?.markAsDirty();
  }

}
