import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FacesState } from '../store-root/faces-state';
import { selectPrincipal } from '../store-root/selectors';
import { combineLatestWith, distinctUntilChanged, map, Subscription, timer } from 'rxjs';
import { UserLoginService } from '@services/user-login.service';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent implements OnDestroy {

  @Input()
  embedded = false;
  @Output()
  logoutClicked = new EventEmitter();
  secondsRemaining: number;

  get sessionTimeExtendable(): boolean {
    return this.secondsRemaining > 0;
  }

  private principal$: Subscription;

  constructor(public activeModal: NgbActiveModal, private userLoginService: UserLoginService, store: Store<FacesState>) {
    this.principal$ = store.select(selectPrincipal).pipe(
      combineLatestWith(timer(0, 1000)),
      map(([u]) => Math.floor(Math.max((u?.refreshTokenExpirationTimestamp || Date.now()) - Date.now(), 0) / 1000)),
      distinctUntilChanged()
    ).subscribe(s => this.secondsRemaining = s);
  }

  ngOnDestroy() {
    this.principal$.unsubscribe();
  }

  extendSession(): void {
    this.userLoginService.refreshAccessToken().subscribe({
      next: () => this.activeModal.close(),
      error: () => {
        // Can't really do anything, logout
        this.triggerLogout();
      }
    });
  }

  triggerLogout(): void {
    this.logoutClicked.emit();
    this.activeModal.close();
  }

}
