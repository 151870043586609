import { PublishTargetSystemEnum } from './types.enum';

export class Option {
  constructor(public key: string, public value: string) {
  }
}

export class TypedOption<Type> {
  constructor(public key: Type | undefined, public value: string) {
  }
}

export class NgOption {
  constructor(public key: any, public value: string) {
  }
}

export class OptionWithPublishTargetCompatibility extends Option {
  constructor(key: string, value: string, public compatiblePublishTargets: PublishTargetSystemEnum[]) {
    super(key, value);
  }
}

export class Outline {
  constructor(public key: string, public label: string, public secondaryKeys: string[] = []) {
  }
}

export class OptionalOutline extends Outline {
  constructor(key: string, label: string, public condition: () => boolean, secondaryKeys: string[] = []) {
    super(key, label, secondaryKeys);
  }
}
