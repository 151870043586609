import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, UntypedFormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaskedCreditCardDetails } from '@shared/models/masked-credit-card-details';
import { UserGroup, UserGroupConfigItem } from '../ctm-noram-models';

@Component({
  selector: 'app-ctm-noram-user-config-dialogg',
  templateUrl: './ctm-noram-user-config-dialog.component.html',
  styleUrls: ['./ctm-noram-user-config-dialog.component.scss']
})
export class CtmNoramUserConfigDialogComponent implements OnInit {

  @Input() initialItems: UserGroupConfigItem[];
  @Input() creditcards: MaskedCreditCardDetails[];
  @Input() allUserGroups: UserGroup[];
  form: FormGroup;
  itemsFormArray: FormArray;

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.itemsFormArray = this.fb.array([]);
    this.form = this.fb.group({
      items: this.itemsFormArray
    });
    this.allUserGroups
      .sort(this.compareOptionsByLabel)
      .map(item => this.createItemFormGroup(item))
      .forEach(frmGrp => this.itemsFormArray.push(frmGrp));
  }

  getGroupName(frmGrp: AbstractControl): string {
    const groupKey = frmGrp.get('gid')?.value;
    return this.allUserGroups.find(grp => grp.key === groupKey)?.label || groupKey;
  }

  getCreditcardLabel(cardGrp: AbstractControl): string {
    const cardUuid = cardGrp.get('uuid')?.value;
    const card = this.creditcards.find(card => card.uuid === cardUuid);
    return `${card?.type} / ${card?.maskedNumber}`;
  }

  getCcFormArray(frmGrp: AbstractControl): FormArray {
    return frmGrp.get('ccid') as FormArray;
  }

  submitNewFieldValue() {
    const newFieldValue = this.itemsFormArray.value;
    // ccid is an array of objects, need to reduce that to card uuids
    newFieldValue.forEach((grp: any) => {
      grp.ccid = grp.ccid
        .filter((ccGrp:any) => ccGrp.assigned === true)
        .map((ccGrp:any) => ccGrp.uuid);
    });
    this.activeModal.close(newFieldValue);
  }

  private createItemFormGroup(userGroup: UserGroup): FormGroup {
    const configItem = this.getUserGroupConfigItem(userGroup.key);
    return this.fb.group({
      gid: [userGroup.key],
      ccid: this.createCreditcardFormArray(configItem),
      pp: [configItem?.pp ?? false]
    });
  }

  private createCreditcardFormArray(configItem?: UserGroupConfigItem): FormArray {
    const result: UntypedFormArray = this.fb.array([]);
    this.creditcards
      .map(card => this.fb.group({
        uuid: [card.uuid],
        assigned: [configItem?.ccid?.includes(card.uuid || '')]
      }))
      .forEach(grp => result.push(grp));
    return result;
  }

  private getUserGroupConfigItem(groupKey: string): UserGroupConfigItem | undefined {
    return this.initialItems.find(item => item.gid === groupKey);
  }

  private compareOptionsByLabel(a: UserGroup, b: UserGroup): number {
    return (a?.label ?? '').localeCompare(b?.label ?? '');
  }

}
