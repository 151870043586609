import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GlobalErrorHandlerService } from '@services/global-error-handler.service';
import { StoreModule } from '@ngrx/store';
import { rootStateReducer } from './store-root/reducers';
import { environment } from '@environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { QuillConfig, QuillModule } from 'ngx-quill'
import { SessionTimeoutDialogComponent } from './session-timeout-dialog/session-timeout-dialog.component';
import { PrincipalLanguageService } from '@services/principal-language.service';
import { RootEffect } from './store-root/effects';
import { UserLoginService } from '@services/user-login.service';
import { CirclesModule } from './circles/circles.module';

const quillConfig: QuillConfig = {
  modules: {
    toolbar: [
      ['bold', 'italic'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['link']
    ]
  }
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    SessionTimeoutDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    CirclesModule,
    // register *after* feature modules and their routes!
    AppRoutingModule,
    StoreModule.forRoot({ root: rootStateReducer }),
    EffectsModule.forRoot([RootEffect]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    , connectInZone: true}),
    QuillModule.forRoot(quillConfig)
  ],
  providers: [
    // GlobalErrorHandlerService is provided in core, make sure we don't create a second instance here!
    { provide: ErrorHandler, useExisting: GlobalErrorHandlerService },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(userLoginService: UserLoginService, principalLanguageService: PrincipalLanguageService) {
    userLoginService.initFromLocalStorage();
    principalLanguageService.startWatchingPrincipalLocale();
  }
}
