<div class="modal-header">
  <h5 class="modal-title">
    <span i18n="@@security.session.inactive.title" *ngIf="!sessionTimeExtendable">Session inactive</span>
    <span i18n="@@security.session.expiring.title" *ngIf="sessionTimeExtendable">Session expiring</span>
  </h5>
</div>
<div class="modal-body">
  <p i18n="@@security.session.inactive.body" *ngIf="sessionTimeExtendable">
    Your session has been inactive for some time. As a security measure you will soon be logged out if there is no activity being registered.<br />
    Please click below if you would like to extend your session.</p>
  <p i18n="@@security.session.expired" *ngIf="!sessionTimeExtendable">Your session has been inactive for some time or has reached its maximum allowed lifespan. As a security measure you have automatically been logged out from the application.</p>
</div>
<div class="modal-footer">
  <ng-container *ngIf="sessionTimeExtendable">
    <div class="me-auto">
      <span i18n="@@security.session.timer">Time remaining:</span> {{secondsRemaining}}s
    </div>
    <button type="button" (click)="extendSession()" class="btn btn-info" i18n="@@security.session.timeout.extend">Extend session</button>
    <button type="button" (click)="triggerLogout()" class="btn btn-primary" i18n="@@main.logout">Logout</button>
  </ng-container>
  <ng-container *ngIf="!sessionTimeExtendable">
    <span *ngIf="embedded" i18n="@@security.session.expired.external.login">Please close this window and re-login to continue.</span>
    <ng-container *ngIf="!embedded">
        <button type="button" (click)="triggerLogout()" class="btn btn-primary" i18n="@@login.loginbutton">Login</button>
    </ng-container>
  </ng-container>

</div>
