<div class="modal-header">
  <h2 class="modal-title">User Group Configuration</h2>
</div>
<div class="modal-body" *ngIf="form">
  <p>Please specify additional options for the configured User Groups.</p>
  <p>In order to add or remove groups please use the 'Setup' tab and adjust the validation list, then return to this dialog.</p>
  <form [formGroup]="form">
    <ng-container formArrayName="items">
      <table id="CTMUserGroupConfigTable" class="table table-striped table-bordered table-condensed">
        <thead>
          <tr>
            <th>User Group</th>
            <th>Credit cards</th>
            <th>Prevent Private CC</th>
          </tr>
        </thead>
        <tr *ngFor="let item of itemsFormArray.controls; let i = index" [formGroupName]="i">
          <td>{{getGroupName(item)}}</td>
          <td formArrayName="ccid">
            <div *ngFor="let cardGrp of getCcFormArray(item).controls; let j = index" [formGroupName]="j">
              <input type="checkbox" formControlName="assigned" class="form-check-input" />
              {{getCreditcardLabel(cardGrp)}}
            </div>
          </td>
          <td>
            <input type="checkbox" formControlName="pp" class="form-check-input" />
          </td>
        </tr>

      </table>
    </ng-container>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary uf-ctmnoramusergroupconfig-cancel" (click)="submitNewFieldValue()"
    i18n="@@general.close">Close</button>
</div>
