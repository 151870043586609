import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgencyContextForCompany } from '@shared/models/agency-context-for-company';
import { CompanyContextForTraveller } from '@shared/models/company-context-for-traveller';
import { GenericSetupResponse } from '@shared/models/generic-setup-response';
import { UserInterfaceColorDto, UserInterfaceStyleDto } from '@shared/models/user-interface-style-dto';
import { BehaviorSubject, combineLatestWith, filter } from 'rxjs';
import { selectAgencyContextForCompany, selectCompanyContextForTraveller } from 'src/app/feature-profiles/store-feature/profiles-selectors';
import { FacesState } from 'src/app/store-root/faces-state';
import { selectUserInterfaceStyle } from 'src/app/store-root/selectors';
import { LogService } from './log-service.interface';
import { UserInterfaceColorTypeConstant, UserInterfaceColorTypeEnum } from '@shared/models/types.enum';
import { GenericFieldSetup } from '@shared/models/generic-field-setup';

type StyleDeclarationWithStringIndex = Partial<CSSStyleDeclaration> & { [propName: string]: string };

class CssRule {
  constructor(public selector: string, public property: string) { }

  /**
   * This method compares CSS selector for functional equality.
   * @param otherSelector C
   * @returns
   */
  isSameSelector(otherSelector: string): boolean {
    const a = new Set(this.selector.split(/, ?/));
    const b = new Set(otherSelector.split(/, ?/));
    return a.size === b.size &&
      [...a].every(s => b.has(s));
  }
}

class DynamicCssRule extends CssRule {
  constructor(selector: string, property: string, public previousValue?: string) {
    super(selector, property);
  }
}

class CssStyleBuilder {

  currentStyleContext = '';

  private get headElement(): HTMLHeadElement {
    return document.head;
  }

  private _cssStyleSheet: CSSStyleSheet;
  private get cssStyleSheet(): CSSStyleSheet {
    if (!this._cssStyleSheet) {
      this._cssStyleSheet = this.createCssStyleSheet();
    }
    return this._cssStyleSheet;
  }

  private dynamicStyles: DynamicCssRule[] = [];
  private fontImportRuleIndex = -1;

  addImport(url: string): void {
    this.fontImportRuleIndex = this.cssStyleSheet.insertRule(`@import url(${url})`);
  }

  setStyle(selectorText: string, styleName: string, value: string | CSSStyleDeclaration): void {
    const rule = this.getStyleRule(selectorText);
    let previousValue: string;
    if (value instanceof CSSStyleDeclaration) {
      previousValue = rule.style.cssText;
      rule.style.cssText = value.cssText;
    } else {
      previousValue = (rule.style as StyleDeclarationWithStringIndex)[styleName];
      (rule.style as StyleDeclarationWithStringIndex)[styleName] = value;
    }
    this.dynamicStyles.push(new DynamicCssRule(selectorText, styleName, previousValue));
  }

  setStyles(selectorText: string, styles: { [styleName: string]: string } | CSSStyleDeclaration) {
    if (styles instanceof CSSStyleDeclaration) {
      this.setStyle(selectorText, '', styles);
    } else {
      Object.keys(styles).forEach(styleName => this.setStyle(selectorText, styleName, styles[styleName]));
    }
  }

  resetAllStyles(): void {
    if (this.fontImportRuleIndex >= 0) {
      this.cssStyleSheet.deleteRule(this.fontImportRuleIndex);
    }
    this.dynamicStyles.forEach(dynamicCssRule => this.resetStyle(dynamicCssRule));
    this.dynamicStyles = [];
  }

  private createCssStyleSheet(): CSSStyleSheet {
    const styleSheetElement = document.createElement("style");
    styleSheetElement.id = 'uf-dynamic-styles';
    this.headElement.appendChild(styleSheetElement);
    return styleSheetElement.sheet as CSSStyleSheet;
  }

  private getStyleRule(selectorText: string): CSSStyleRule {
    const rules: CSSRuleList = this.cssStyleSheet.cssRules;
    let rule: CSSStyleRule = Array.from(rules)
      .find(r => r instanceof CSSStyleRule && r.selectorText.toLowerCase() == selectorText.toLowerCase()) as CSSStyleRule;
    if (!rule) {
      const ruleIndex = this.cssStyleSheet.insertRule(`${selectorText} {}`, rules.length);
      rule = rules[ruleIndex] as CSSStyleRule;
    }
    return rule;
  }

  private resetStyle(dynamicCssRule: DynamicCssRule): void {
    const styleName = dynamicCssRule.property;
    const ruleIndex = Array.from(this.cssStyleSheet.cssRules)
      .findIndex(r => r instanceof CSSStyleRule && dynamicCssRule.isSameSelector(r.selectorText));
    if (ruleIndex >= 0) {
      if (styleName) {
        const rule = this.cssStyleSheet.cssRules[ruleIndex] as CSSStyleRule;
        (rule.style as StyleDeclarationWithStringIndex)[styleName] = dynamicCssRule.previousValue || '';
      } else {
        // delete entire selector .. is this correct??
        this.cssStyleSheet.deleteRule(ruleIndex);
      }
    } // else: probably a bug, because we should find a rule. But let's not fail here.
  }
}

@Injectable({
  providedIn: 'root'
})
export class MediaPreferenceService {

  private static COLOR_MODE_KEY = 'faces.colormode';

  public static mapGenericFieldNameToCssSelector(fld?: GenericFieldSetup): string {
    if (fld?.name) {
      const nameLettersOnly = fld.name.replace(/\W/g, '');
      return `uf-generic-value-row-${nameLettersOnly}`;
    }
    return 'uf-generic-value-row';
  }

  /**
   * Get the current color mode, either 'light' or 'dark'
   */
  get currentColorMode(): string {
    return this._currentColorMode.value;
  }

  private readonly darkStyle: HTMLLinkElement | null;
  private readonly lightStyle: HTMLLinkElement | null;
  private _currentColorMode = new BehaviorSubject('light');
  private readonly agencyStyleBuilder = new CssStyleBuilder();
  private readonly companyStyleBuilder = new CssStyleBuilder();
  private lookAndFeelStyleBuilder = new CssStyleBuilder();
  private previewStyleBuilder = new CssStyleBuilder();

  constructor(private store: Store<FacesState>,
    private log: LogService) {
    this.darkStyle = document.querySelector('link[rel="stylesheet"][href^="dark"]');
    this.lightStyle = document.querySelector('link[rel="stylesheet"][href^="light"]');
    this.applyColorMode();

    window.matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => this.applyColorMode());

    this.store.select(selectUserInterfaceStyle)
      .pipe(combineLatestWith(this._currentColorMode))
      .subscribe(([style, _colorMode]) => {
        this.log.debug('Detected change in user interface style or color mode');
        this.lookAndFeelStyleBuilder.resetAllStyles();
        if (style) {
          this.log.debug(`Applying user interface style ${style.name}`);
          this.setUserInterfaceStyles(style, this.lookAndFeelStyleBuilder);
        }
      });
  }

  /**
   * Start listening to profile-related changes in order to update styles. Be sure to
   * only call this method once the profile feature-module has been loaded.
   */
  enableProfileBasedStyles(): void {
    // should match the selector used in TravellerMainFormComponent to update generic fields
    this.store.select(selectCompanyContextForTraveller)
      .pipe(filter(ctx => this.getCompanyStyleContext(ctx) !== this.companyStyleBuilder.currentStyleContext))
      .subscribe(context => this.updateStyleForSelectedCompany(context));
    // should match the selector used in CompanyMainFormComponent to update generic fields
    this.store.select(selectAgencyContextForCompany)
      .pipe(filter(ctx => this.getAgencyStyleContext(ctx) !== this.agencyStyleBuilder.currentStyleContext))
      .subscribe(context => this.updateStyleForSelectedAgency(context));
  }

  setOverrideColorMode(mode?: string): void {
    if (mode) {
      localStorage.setItem(MediaPreferenceService.COLOR_MODE_KEY, mode);
    } else {
      localStorage.removeItem(MediaPreferenceService.COLOR_MODE_KEY);
    }
    this.applyColorMode();
  }

  applyUserInterfaceStyle(style: UserInterfaceStyleDto): void {
    this.setUserInterfaceStyles(style, this.previewStyleBuilder)
  }

  resetUserInterfaceStyle(): void {
    this.previewStyleBuilder.resetAllStyles();
  }

  private getOverrideColorMode(): string {
    const mode = localStorage.getItem(MediaPreferenceService.COLOR_MODE_KEY);
    return mode || '';
  }

  private applyColorMode(): void {
    this._currentColorMode.next(this.detectColorMode());
    if (this.currentColorMode === 'dark') {
      this.darkStyle?.removeAttribute('disabled');
      this.lightStyle?.setAttribute('disabled', 'true');
    } else {
      this.darkStyle?.setAttribute('disabled', 'true');
      this.lightStyle?.removeAttribute('disabled');
    }
  }

  private detectColorMode(): string {
    let result = 'light'; // RFC defines 'light' as default
    const mode = this.getOverrideColorMode();
    if (mode) {
      result = mode;
    } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      result = 'dark';
    }
    return result;
  }

  private updateStyleForSelectedCompany(companyContext?: CompanyContextForTraveller): void {
    this.companyStyleBuilder.resetAllStyles();
    if (companyContext?.genericSetup) {
      this.updateGenericFieldStyle(companyContext.genericSetup, this.companyStyleBuilder);
    }
    this.companyStyleBuilder.currentStyleContext = this.getCompanyStyleContext(companyContext);
  }

  private updateStyleForSelectedAgency(agencyContext?: AgencyContextForCompany): void {
    this.agencyStyleBuilder.resetAllStyles();
    if (agencyContext?.genericSetup) {
      this.updateGenericFieldStyle(agencyContext.genericSetup, this.agencyStyleBuilder);
    }
    this.agencyStyleBuilder.currentStyleContext = this.getAgencyStyleContext(agencyContext);
  }

  private updateGenericFieldStyle(genericSetupResponse: GenericSetupResponse, cssStyleBuilder: CssStyleBuilder): void {
    genericSetupResponse.groups?.forEach(grp => {
      grp.fields
        ?.filter(fld => fld.fieldOrder !== undefined && fld.fieldOrder > 0)
        .forEach(fld => {
          const rowClass = MediaPreferenceService.mapGenericFieldNameToCssSelector(fld);
          cssStyleBuilder.setStyle(`.${rowClass}`, 'order', `${fld.fieldOrder}`);
        });
    });

  }

  private getAgencyStyleContext(context?: AgencyContextForCompany): string {
    return `agency-${context?.agencyUuid}`;
  }

  private getCompanyStyleContext(context?: CompanyContextForTraveller): string {
    return `company-${context?.uuid}`;
  }

  private setUserInterfaceStyles(style: UserInterfaceStyleDto, cssBuilder: CssStyleBuilder): void {
    if (style.fontImport) {
      cssBuilder.addImport(style.fontImport);
    }
    if (style.fontFamily) {
      cssBuilder.setStyle('html, body', 'font-family', `${style.fontFamily}`);
    }
    style.colors.forEach(clr => {
      this.applyColorRule(clr, cssBuilder);
    });

  }

  private applyColorRule(colorRule: UserInterfaceColorDto, cssBuilder: CssStyleBuilder): void {
    const value = this._currentColorMode.value === 'dark' ? colorRule.darkModeValue : colorRule.lightModeValue;
    if (value) {
      this.toCssClasses(colorRule.type).forEach(cssRule => {
        cssBuilder.setStyle(cssRule.selector, cssRule.property, value);
      });
    }
  }

  private toCssClasses(colorType: UserInterfaceColorTypeEnum): CssRule[] {
    const btnBackgroundClasses: string[] = ['.#btn#','.#btn#:focus','.#btn#:active','.#btn#:first-child:active','.#btn#:disabled'];
    switch (colorType) {
      case UserInterfaceColorTypeConstant.NAVBAR_BACKGROUND:
        return [new CssRule('.app-container .nav-banner,.app-container .nav-banner .dropdown-menu', 'background-color')];
      case UserInterfaceColorTypeConstant.NAVBAR_TEXT:
        return [new CssRule('.navbar .navbar-nav .nav-link,.nav-tabs .nav-item,.nav-tabs .nav-link', 'color')];
      case UserInterfaceColorTypeConstant.NAVBAR_TEXT_ACTIVE:
        return [new CssRule('.navbar .navbar-nav .nav-link.nav-active,.nav-tabs .nav-item.active,.nav-tabs .nav-link.active', 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_PRIMARY_BACKGROUND:
        return [new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-primary')).join(','), 'background-color'),
        new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-primary')).join(','), 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_PRIMARY_BACKGROUND_HOVER:
        return [new CssRule('.btn-primary:hover', 'background-color'), new CssRule('.btn-primary:hover', 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_PRIMARY_TEXT:
        return [new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-primary')).join(','), 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_PRIMARY_TEXT_HOVER:
        return [new CssRule('.btn-primary:hover', 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_SECONDARY_BACKGROUND:
        return [new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-secondary')).join(','), 'background-color'),
        new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-secondary')).join(','), 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_SECONDARY_BACKGROUND_HOVER:
        return [new CssRule('.btn-secondary:hover', 'background-color'), new CssRule('.btn-secondary:hover', 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_SECONDARY_TEXT:
        return [new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-secondary')).join(','), 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_SECONDARY_TEXT_HOVER:
        return [new CssRule('.btn-secondary:hover', 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_BACKGROUND:
        return [new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-outline-secondary')).join(','), 'background-color'),
        new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-outline-secondary')).join(','), 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_BACKGROUND_HOVER:
        return [new CssRule('.btn-outline-secondary:hover', 'background-color'), new CssRule('.btn-outline-secondary:hover', 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_TEXT:
        return [new CssRule(btnBackgroundClasses.map(s => s.replace('#btn#', 'btn-outline-secondary')).join(','), 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_OUTLINESECONDARY_TEXT_HOVER:
        return [new CssRule('.btn-outline-secondary:hover', 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_DANGER_BACKGROUND:
        return [new CssRule('.btn-danger,.btn-danger:focus', 'background-color'), new CssRule('.btn-danger,.btn-danger:focus', 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_DANGER_BACKGROUND_HOVER:
        return [new CssRule('.btn-danger:hover', 'background-color'), new CssRule('.btn-danger:hover', 'border-color')];
      case UserInterfaceColorTypeConstant.BUTTON_DANGER_TEXT:
        return [new CssRule('.btn-danger,.btn-danger:focus', 'color')];
      case UserInterfaceColorTypeConstant.BUTTON_DANGER_TEXT_HOVER:
        return [new CssRule('.btn-danger:hover', 'color')];
      case UserInterfaceColorTypeConstant.ERROR_FIELD:
        return [new CssRule('input.ng-invalid, textarea.ng-invalid, select.ng-invalid, app-datepicker.ng-invalid > div > input', 'background-color')];
      case UserInterfaceColorTypeConstant.BACKGROUND:
        return [new CssRule('body', 'background-color')];
      case UserInterfaceColorTypeConstant.CARD:
        return [new CssRule('.card', 'background-color')];
      case UserInterfaceColorTypeConstant.CARD_TEXT:
        return [new CssRule('.card,.card h3,.card .h3,.card h4,.card .h4,.btn-icon i.bi,' +
        '.card .col-form-label,.card .navigation-outline.active,' +
        '.card .form-control,.card .form-select', 'color')];
      case UserInterfaceColorTypeConstant.ACCORDION_EXPANDED_TEXT:
        return [new CssRule('.accordion-item,.accordion-item .accordion-header .accordion-button:not(.collapsed)', 'color')];
      case UserInterfaceColorTypeConstant.ACCORDION_EXPANDED_BACKGROUND:
        return [new CssRule('.accordion-item,.accordion-item .accordion-header .accordion-button:not(.collapsed)', 'background-color')];
      case UserInterfaceColorTypeConstant.ALERT_SUCCESS_BACKGROUND:
        return [new CssRule('.alert-success', 'background-color'), new CssRule('.alert-success', 'border-color')];
      case UserInterfaceColorTypeConstant.ALERT_SUCCESS_TEXT:
        return [new CssRule('.alert-success', 'color')];
      case UserInterfaceColorTypeConstant.ALERT_WARNING_BACKGROUND:
        return [new CssRule('.alert-warning', 'background-color'), new CssRule('.alert-warning', 'border-color')];
      case UserInterfaceColorTypeConstant.ALERT_WARNING_TEXT:
        return [new CssRule('.alert-warning', 'color')];
      case UserInterfaceColorTypeConstant.ALERT_ERROR_BACKGROUND:
        return [new CssRule('.alert-danger', 'background-color'), new CssRule('.alert-danger', 'border-color')];
      case UserInterfaceColorTypeConstant.ALERT_ERROR_TEXT:
        return [new CssRule('.alert-danger', 'color')];
      case UserInterfaceColorTypeConstant.PUBLISHING_ERROR:
        return [new CssRule('.publishing-error', 'color')];
      case UserInterfaceColorTypeConstant.PUBLISHING_WARNING:
        return [new CssRule('.publishing-warning', 'color')];
      case UserInterfaceColorTypeConstant.PUBLISHING_OK:
        return [new CssRule('.publishing-ok', 'color')];
    }
    return [];
  }

}
