import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {User} from "@shared/models/user.class";
import {FacesState} from "src/app/store-root/faces-state";
import {selectPrincipal} from "src/app/store-root/selectors";

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  static readonly BIRTHDATE_OPTIONAL_ROLES: string[] = ['ROLE_ADMIN', 'ROLE_AGENCY_MANAGER', 'SUBROLE_ALLCOMPANY_ADMIN'];
  // note to developers: try to consolidate ROLE-collections in this class, for re-use across the application

  private currentUser: User | undefined;

  /**
   * Authorities are store as
   * { "authority": "CIRCLE_BASIC" }
   * objects. This method maps that to a simple string array, then checks against 'roleNames'
   */
  static hasAnyRole(user: User | undefined, ...roleNames: string[]): boolean {
    const authorities = (user?.authorities || [])
      .map(auth => auth.authority);
    return authorities.some(r => roleNames.includes(r));
  }

  static hasAllRoles(user: User | undefined, ...roleNames: string[]): boolean {
    const authorities = (user?.authorities || [])
      .map(auth => auth.authority);
    return roleNames.every(r => authorities.includes(r));
  }

  constructor(private store: Store<FacesState>) {
    this.store.select(selectPrincipal).subscribe(user => this.currentUser = user);
  }

  hasAnyRole(...roleNames: string[]): boolean {
    return AccessService.hasAnyRole(this.currentUser, ...roleNames);
  }

  hasAllRoles(...roleNames: string[]): boolean {
    return AccessService.hasAllRoles(this.currentUser, ...roleNames);
  }

  isReadOnly(): boolean {
    return AccessService.hasAnyRole(this.currentUser, 'ACCESS_ALL_READONLY');
  }
}
