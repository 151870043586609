import { FormArray, FormGroup } from '@angular/forms';
import { TravellerDetailResponse } from '@shared/models/traveller-detail-response';
import { Option } from '@shared/models/option';
import {
  AffectedUserGroupConstant,
  FieldDisplayTypeConstant,
  ProfileTypeConstant,
  ProfileTypeEnum,
  ReferencedCreditCardDropdown,
  RoleConstant,
  TravellerFopOptionConstant,
  TravellerFopOptionEnum
} from '@shared/models/types.enum';
import { BaseCircleCustomization, ProfileCollectionNames } from '../base-circle-customization.class';
import { AccessService } from '@services/access.service';
import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { MaskedCreditCardDetails } from '@shared/models/masked-credit-card-details';
import { Type } from '@angular/core';
import { GenericFieldSetup } from '@shared/models/generic-field-setup';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';
import { CtmNoramUserGroupConfigComponent } from './ctm-noram-user-group-config.component';
import { Store } from '@ngrx/store';
import { FacesState } from 'src/app/store-root/faces-state';
import { CtmNoramTravellerUserGroupInterceptor } from './ctm-noram-traveller-user-group.interceptor';
import { CircleBackendService } from '@services/circle-backend.service';
import { ObjectUtils } from '@shared/object-utils.class';

export class CtmNoramCustomization extends BaseCircleCustomization {

  private userGroupConfigComponent?: Type<BaseGenericValueWithSetupComponent>;
  private travellerUserGroupInterceptor: CtmNoramTravellerUserGroupInterceptor;

  constructor(private store: Store<FacesState>, private circleBackendService: CircleBackendService, private accessService: AccessService) {
    super();
    this.userGroupConfigComponent = CtmNoramUserGroupConfigComponent;

    this.setPreferredCountryCodesOnPhone(['US']);
  }

  override customizeAirlineListForTravellers(optionList: Option[]): void {
    this.renameOption('', 'Select a carrier', optionList);
    this.renameOption('DL', 'Delta', optionList);
    this.renameOption('F9', 'Frontier', optionList);
    this.renameOption('B6', 'JetBlue', optionList);
    this.renameOption('WN', 'Southwest', optionList);
    this.renameOption('UA', 'United', optionList);
    this.sortOptionsByValueList(optionList, '', 'AC', 'AS', 'AA', 'DL', 'F9', 'B6', 'WN', 'UA');
  }

  override customizeAirlineListForCompanies(optionList: Option[]): void {
    this.customizeAirlineListForTravellers(optionList);
  }

  override customizeAirlinePublishtypes(optionList: Option[]): void {
    this.removeOption('SPECIAL_KEYWORD', optionList);
  }

  override customizeHotelList(optionList: Option[]): void {
    this.renameOption('', 'Select a hotel chain', optionList);
    this.renameOption('RT', 'Accor Hotels (RT)', optionList);
    this.renameOption('CW', 'Carlson Brands (all) (CW)', optionList);
    this.renameOption('EC', 'Choice Brands (EC)', optionList);
    this.renameOption('EH', 'Hilton (all) (EH)', optionList);
    this.renameOption('6C', 'Intercontinental Hotels Group (6C)', optionList);
    this.renameOption('EM', 'Marriott (all) (EM)', optionList);
    this.renameOption('TR', 'Registry Collection (TR)', optionList);
    this.renameOption('WR', 'Wyndham Reward (WR)', optionList);
    this.renameOption('BW', 'Best Western (BW)', optionList);
    this.renameOption('CI', 'Comfort Inns (CI)', optionList);
    this.renameOption('CY', 'Courtyard (CY)', optionList);
    this.renameOption('CP', 'Crowne Plaza (CP)', optionList);
    this.renameOption('DT', 'Doubletree (DT)', optionList);
    this.renameOption('ES', 'Embassy Suites (ES)', optionList);
    this.renameOption('FN', 'Fairfeld Inns (FN)', optionList);
    this.renameOption('FA', 'Fairmont Hotels (FA)', optionList);
    this.renameOption('HX', 'Hampton Inns (HX)', optionList);
    this.renameOption('HH', 'Hilton (HH)', optionList);
    this.renameOption('HI', 'Holiday Inn (HI)', optionList);
    this.renameOption('LQ', 'La Quinta Inns (LQ)', optionList);
    this.renameOption('MC', 'Marriott (MC)', optionList);
    this.renameOption('OM', 'Omni Hotels (OM)', optionList);
    this.renameOption('RD', 'Radisson (RD)', optionList);
    this.renameOption('RC', 'Residence Inns (RC)', optionList);
    this.renameOption('SI', 'Sheraton (SI)', optionList);
    this.renameOption('WH', 'W Hotels (WH)', optionList);
    this.renameOption('WI', 'Westin (WI)', optionList);
    this.renameOption('WX', 'Wyndham Hotels (WY)', optionList);
    this.sortOptionsByValueList(optionList, '', 'RT', 'CW', 'EC', 'EH', '6C', 'EM', 'TR', 'WR', 'BW', 'CI', 'CY', 'CP', 'DT', 'ES', 'FN', 'FA', 'HX', 'HH', 'HI', 'LQ', 'MC', 'OM', 'RD', 'RC', 'SI', 'WH', 'WI', 'WX');
  }

  override customizeRentalCarList(optionList: Option[]): void {
    this.renameOption('', 'Select a rental company', optionList);
    this.renameOption('ZI', 'Avis', optionList);
    this.renameOption('ZR', 'Dollar Rent A Car', optionList);
    this.sortOptionsByValueList(optionList, '', 'AL', 'ZI', 'ZD', 'ZR', 'ET', 'ZE', 'ZL');
  }

  /**
   * Traveller cannot edit their own name, unless during self-registration.
   */
  override getTransientStandardfieldDefinition(controlPath: string[], _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if (('firstname' === controlName) || ('name' === controlName) || ('email' === controlName)) {
      // note that 'name' might match other control than the intended, but they are all read-only
      const markNamefieldsReadonly = this.accessService.hasAnyRole(RoleConstant.TRAVELLER) && !this.accessService.hasAnyRole('ROLE_SELFREG_USER');
      if (markNamefieldsReadonly) {
        return {
          collectionName: '',
          fieldName: controlName,
          profileType: ProfileTypeConstant.TRAVELLER,
          affectedUserGroup: AffectedUserGroupConstant.COMPANY_ADMIN_AND_TRAVELLER,
          fieldDisplayType: FieldDisplayTypeConstant.READONLY,
          label: ''
        };
      }
    }
    return;
  }

  override customizeTravellerFormGroup(_traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    this.travellerUserGroupInterceptor?.destroy();
    const userGroupControl = this.getGenericFormControl(formGroup.get('data.genericFieldValues') as FormArray, 'UserGroup');
    if (userGroupControl) {
      this.travellerUserGroupInterceptor = new CtmNoramTravellerUserGroupInterceptor(this.store, this.circleBackendService, userGroupControl);
    }
  }

  override addedCollectionItem(profileType: ProfileTypeEnum, collectionName: ProfileCollectionNames, formGroup: FormGroup): void {
    if ((profileType === ProfileTypeConstant.TRAVELLER) && (collectionName === 'creditCards')) {
      const creditcardType$ = formGroup.get('type')?.valueChanges
        .subscribe(() => {
          // this also sets publishAsFop=true on GUARANTEEONLY, but on that setting publishAsFop should be irrelevant
          formGroup.get('publishAsFop')?.setValue(true);
        });
    super.registerSubscription(creditcardType$);
    }
  }

  override customizeCreditCardNoCardOption(type: ReferencedCreditCardDropdown, creditCards: MaskedCreditCardDetails[], travellerFopOption?: TravellerFopOptionEnum): boolean {
    if (ObjectUtils.equalsAny(type, ReferencedCreditCardDropdown.WEB_CARD, ReferencedCreditCardDropdown.HOTEL_GUARANTEE, ReferencedCreditCardDropdown.CAR_GUARANTEE)) {
      return (travellerFopOption === TravellerFopOptionConstant.FPANDGUARANTEE || travellerFopOption === TravellerFopOptionConstant.GUARANTEEONLY) &&
        creditCards.length === 0;
    }

    // Show no card option (if available)
    return true;
  }

  override getGenericValueWithSetupComponent(genericFieldSetup?: GenericFieldSetup): Type<BaseGenericValueWithSetupComponent> | undefined {
    if (genericFieldSetup?.name === 'UserGroupConfig') {
      return this.userGroupConfigComponent;
    }
    return super.getGenericValueWithSetupComponent(genericFieldSetup);
  }

  override destroy(): void {
    super.destroy();
    this.travellerUserGroupInterceptor?.destroy();
  }

}
