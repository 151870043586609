import { FormGroup } from '@angular/forms';
import { BaseCircleCustomization } from "../base-circle-customization.class";
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { TravellerFopOptionConstant } from '@shared/models/types.enum';

export class UniglobeHollandCustomization extends BaseCircleCustomization {

  override customizeCompanyFormGroup(company: CompanyDetailResponse | undefined, formGroup: FormGroup): void {
    if (!company?.uuid) {
      formGroup.get('data.creditCardInfo.travellerCardOptions.fopOption')?.setValue(TravellerFopOptionConstant.NONE);
    }
  }

}
