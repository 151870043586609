import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export class CircleValidators {

  /**
   * Enforces digits-only, spaces allowed, international prefix '+' allowed.
   * Legacy UI validator method 'flexPhone'.
   */
  static flexPhone = Validators.pattern('^\\+?[\\d ]*$');
  /**
   * Enforces digits-only, spaces allowed, international prefix '+' or '00' allowed.
   * Legacy UI validator method 'phone'.
   */
  static internationalPhone = Validators.pattern('^(\\+|00)[\\d ]*$');
  /**
   * Require an international '+' prefix, and disallow spaces.
   * Legacy UI validator method 'phoneMandatoryPlusPrefixedCountryCode'.
   */
  static internationalMandatoryPhone = Validators.pattern('^\\+\\d+$');
  /**
   * Enforces digits-only, spaces allowed, international prefix '+' or '00' allowed.
   * Legacy UI validator method 'phone'.
   */
  static phoneMandatoryCountryCode = Validators.pattern('(^00+\\d{5,})|(^\\++\\d{4,})');

  static eightDigits = Validators.pattern('^\\d{8}$');

  static dateInFuture(control: AbstractControl): ValidationErrors | null {
    const controlValue = control.value as Date;
    const validValue = !controlValue || controlValue > new Date();
    return validValue ? null : { dateInFuture: { value: control.value } };
  };

  static ctAdult(control: AbstractControl): ValidationErrors | null {
    const controlValue = control.value as Date;
    if (controlValue) {
      const ageDate = new Date(Date.now() - controlValue.getTime());
      const age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age >= 18 ? null : { ctAdult: { value: control.value } };
    }
    return null;
  }

}
