import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

export interface BrzOrganisationalUnit {
  id: string;
  name: string;
}

export interface BtaAddress {
  city: string;
  zip: string;
  country: string;
  street: string;
}

export interface UserGroupConfiguration {
  groupId: string;
  creditCardIds: string[];
  prohibitPrivateCard: boolean;
}

export interface UserGroupConfigurations {
  userGroupConfiguration: UserGroupConfiguration[];
}

@Injectable({
  providedIn: 'root'
})
export class CircleBackendService {

  constructor(private http: HttpClient) {
  }

  getBrzOrganisationEntity(distinguishedNames: string[]): Observable<BrzOrganisationalUnit[]> {
    const httpParams = new HttpParams()//
      .set('q', distinguishedNames.join(";"));
    return this.http.get<BrzOrganisationalUnit[]>(`${environment.apiBaseUrl}/api/v1/c/BRZ/getOe.json`, { params: httpParams });
  }

  searchBrzLdap(params: {
    q: string;
    p?: string;
  }): Observable<BrzOrganisationalUnit[]> {
    let httpParams = new HttpParams().set('q', params.q);
    if (params.p) {
      httpParams = httpParams.set('p', params.p);
    }
    return this.http.get<BrzOrganisationalUnit[]>(`${environment.apiBaseUrl}/api/v1/c/BRZ/searchOe.json`, { params: httpParams });
  }

  getBtaFirstCompanyAddress(companyUuid: string): Observable<BtaAddress> {
    const httpParams = new HttpParams()//
      .set('c', companyUuid);
    return this.http.get<BtaAddress>(`${environment.apiBaseUrl}/api/v1/c/BTAFIRST/company_change.json`, { params: httpParams });
  }

  getCtmNoramUserGroups(companyUuid: string): Observable<{[key:string]: any}> {
    const httpParams = new HttpParams()//
      .set('c', companyUuid);
    return this.http.get<{[key:string]: any}>(`${environment.apiBaseUrl}/api/v1/c/CTM_NORAM/usergroups.json`, { params: httpParams });
  }

  getCtmNoramValidationRules(companyUuid: string): Observable<UserGroupConfigurations> {
    const httpParams = new HttpParams()//
      .set('c', companyUuid);
    return this.http.get<UserGroupConfigurations>(`${environment.apiBaseUrl}/api/v1/c/CTM_NORAM/validation_rules.json`, { params: httpParams });
  }

  getUniglobeValidationRules(companyUuid: string): Observable<{ isReadOnlyName: boolean }> {
    const httpParams = new HttpParams()
      .set('c', companyUuid);
    return this.http.get<{ isReadOnlyName: boolean }>(`${environment.apiBaseUrl}/api/v1/c/UNIGLOBE/validation_rules.json`, { params: httpParams });
  }

}
