import { LogService } from '@services/log-service.interface';
import { Subscriber } from 'rxjs';
import {
  FieldAndStyleConfiguration,
  TokenizationParameters,
  TokenizationProvider,
  TokenizationTransactionParameters
} from '@shared/pci/tokenization-provider.class';
import { CreditCardTypeConstant } from '@models/types.enum';
import { TokenizerUtils } from '@shared/pci/tokenizer-utils.class';

declare const SecureFields: any;

export class DatatransTokenizer extends TokenizationProvider {

  private readonly sf: any;
  private readonly plainAirPlusInput: boolean;
  private readonly plainNumberInput: HTMLInputElement;

  constructor(log: LogService, subscriber: Subscriber<TokenizationTransactionParameters>, merchantId: string, params: TokenizationParameters) {
    super(log, subscriber, merchantId, params);
    this.plainAirPlusInput = params.expectedCardType === CreditCardTypeConstant.TP;

    if (this.plainAirPlusInput) {
      this.plainNumberInput = TokenizerUtils.createPlainNumberInputField(event => this.onChange(event));
    } else {
      this.sf = new SecureFields();
      this.sf.on('validate', (event: any) => this.onValidate(event));
      this.sf.on('error', (message: string) => this.onError(message));
      this.sf.on('change', (event: any) => this.onChange(event));
      this.sf.on('ready', () => this.onReady());
      this.sf.on('success', (event: any) => this.onSuccess(event.transactionId));
    }
  }

  protected override initTokenize(fields: FieldAndStyleConfiguration, styles: FieldAndStyleConfiguration, focus?: string) {
    if (this.plainAirPlusInput) {
      TokenizerUtils.addAndFocusPlainNumberInput(fields, this.plainNumberInput);
    } else {
      this.sf.initTokenize(this.merchantId, fields, {styles, focus});
    }
  }

  protected override submitTokenizerFrame() {
    if (this.plainAirPlusInput) {
      this.onSuccess(CreditCardTypeConstant.TP, {
        airPlusNumber: this.plainNumberInput.value
      });
    } else {
      this.sf.submit();
    }
  }

  protected override cleanUpTokenizerDom() {
    if (this.plainAirPlusInput) {
      this.plainNumberInput.remove();
    } else {
      super.cleanUpTokenizerDom();
    }
  }
}
