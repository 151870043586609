import { FormGroup } from "@angular/forms";
import { BaseCircleCustomization } from "../base-circle-customization.class";
import { StandardfieldDefinition } from "@shared/models/standardfield-definition";
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum } from "@shared/models/types.enum";

export class FbtCustomization extends BaseCircleCustomization {

  override getTransientStandardfieldDefinition(controlPath: string[], _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if ('shortname' === controlName) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.CORPORATE,
        affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
        fieldDisplayType: FieldDisplayTypeConstant.HIDDEN,
        label: ''
      };
    }
    return;
  }

  override onCompanySave(formGroup: FormGroup, callback: () => void): void {
    const nameControl = formGroup.get('name');
    if (nameControl?.dirty) {
      const shortnameControl = formGroup.get('shortname');
      shortnameControl?.setValue(nameControl.value);
      shortnameControl?.markAsDirty();
    }
    callback();
  }

}
