import { environment } from '@environments/environment';

export class StyleUtils {

  private static errorMessageHeight = 0;

  static getFontSize(e: Element): string {
    return window.getComputedStyle(e, null).getPropertyValue('font-size');
  }

  static adjustDynamicHeights(errorMessageHeight: number): void {
    StyleUtils.errorMessageHeight = errorMessageHeight;
    StyleUtils.reApplyDynamicHeights();
  }

  static reApplyDynamicHeights(): void {
    if (window.matchMedia('(max-width: 1300px)').matches) {
      StyleUtils.clearHeight();
    } else {
      StyleUtils.adjustDataContentHeight();
      StyleUtils.adjustScrollContainerHeight();
    }
  }

  private static adjustDataContentHeight(): void {
    const offset = environment.navbarHeight + StyleUtils.errorMessageHeight + 20;
    const dataContentHeight = `calc(100vh - ${offset}px)`;
    const dataContentElement =  document.querySelector<HTMLElement>('#data-content');
    if (dataContentElement) {
      dataContentElement.style.height = dataContentHeight;
    }
  }

  private static adjustScrollContainerHeight(): void {
    const offset = environment.navbarHeight + environment.genericSetupFixedHeight + StyleUtils.errorMessageHeight + 20;
    const scrollContainerHeight = `calc(100vh - ${offset}px)`;
    const containers = document.querySelectorAll<HTMLElement>('.scroll-container')
    for (let i = 0; i < containers.length; ++i) {
      containers[i].style.height = scrollContainerHeight;
    }
  }

  private static clearHeight(): void {
    const dataContentElement =  document.querySelector<HTMLElement>('#data-content');
    if (dataContentElement) {
      dataContentElement.style.height = '';
    }
    const containers = document.querySelectorAll<HTMLElement>('.scroll-container')
    for (let i = 0; i < containers.length; ++i) {
      containers[i].style.height = '';
    }
  }
}
