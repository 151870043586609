import { BaseCircleCustomization } from '../base-circle-customization.class';
import { TravellerDetailResponse } from '@models/traveller-detail-response';
import { FormGroup } from '@angular/forms';
import { CircleValidators } from '../circle-validators.class';

export class SchrodersCustomization extends BaseCircleCustomization {

  override customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, formGroup: FormGroup): void {
    super.addValidators(formGroup.get('data.generalData.mobilePhone'), CircleValidators.phoneMandatoryCountryCode);
  }

}
