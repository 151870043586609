import { BaseCircleCustomization } from "../base-circle-customization.class";
import { Type } from "@angular/core";
import { BaseGenericValueWithSetupComponent } from "../base-generic-value-with-setup-component";
import { GenericFieldSetup } from "@shared/models/generic-field-setup";
import { BrzLdapButtonComponent } from "./brz-ldap-button.component";

export class BrzCustomization extends BaseCircleCustomization {

  private ldapConfigButtonComponent?: Type<BaseGenericValueWithSetupComponent>;

  constructor() {
    super();

    this.ldapConfigButtonComponent = BrzLdapButtonComponent;
  }

  override getGenericValueWithSetupComponent(genericFieldSetup?: GenericFieldSetup): Type<BaseGenericValueWithSetupComponent> | undefined {
    if (genericFieldSetup?.name === 'ldapOeConfig') {
      return this.ldapConfigButtonComponent;
    }
    return super.getGenericValueWithSetupComponent(genericFieldSetup);
  }

}
