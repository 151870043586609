import { FormGroup } from '@angular/forms';
import { StandardfieldDefinition } from '@shared/models/standardfield-definition';
import { ProfileTypeConstant, AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeEnum } from '@shared/models/types.enum';
import { BaseCircleCustomization } from '../base-circle-customization.class';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { AngularUtils } from '@shared/angular-utils.class';

export class BtuCustomization extends BaseCircleCustomization {

  private optionalFields = ['email', 'street', 'zipCode', 'place', 'countryCode'];

  override getTransientStandardfieldDefinition(controlPath: string[], _profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if (this.optionalFields.includes(controlName)) {
      const profileType = 'email' === controlName ? ProfileTypeConstant.TRAVELLER : ProfileTypeConstant.CORPORATE;
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: profileType,
        affectedUserGroup: AffectedUserGroupConstant.ENTIRE_AGENCY,
        fieldDisplayType: FieldDisplayTypeConstant.OPTIONAL,
        label: ''
      };
    }
    return;
  }

  override customizeCompanyFormGroup(company: CompanyDetailResponse | undefined, formGroup: FormGroup): void {
    if (!company?.uuid) {
      AngularUtils.runOnNextTick(() => {
        formGroup.get('data.contact.countryCode')?.setValue('');
      });
    }
  }
}
