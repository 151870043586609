<div class="modal-body" [ngSwitch]="useCase">
  <span *ngSwitchCase="'DELETE'" i18n="@@general.delete.confirmation">This record will be deleted. This action cannot be undone.</span>
  <span *ngSwitchCase="'DISCONNECT'" i18n="@@company.gdstarget.disconnect.warning">This interface will be disconnected. This action cannot be undone.</span>
  <div *ngSwitchCase="'SHOW_CVV_DATATRANS'">
    <div class="mb-2" i18n="@@general.showcvc.datatrans">
      By continuing below, you will be redirected to our partner Datatrans where the CVC / CVV can be revealed.
    </div>
    <ng-container *ngTemplateOutlet="cvvDisclaimer"></ng-container>
  </div>
  <div *ngSwitchCase="'SHOW_CVV_MIDOCO'">
    <div class="mb-2" i18n="@@general.showcvc.midoco">
      After continuing below, you will receive an e-mail from our partner Midoco where the CVC / CVV can be revealed.
    </div>
    <ng-container *ngTemplateOutlet="cvvDisclaimer"></ng-container>
  </div>
  <ng-template #cvvDisclaimer>
    <div class="alert alert-danger" i18n="@@general.showcvc.disclaimer">
      The CVV will be immediately removed from the profile and can be re-entered manually if still required for a future transaction
    </div>
    <div i18n="@@general.showcvc.disclaimer.confirmation">Please confirm that you have read and understood this limitation</div>
  </ng-template>
  <span *ngSwitchCase="'RELOAD'" i18n="@@general.error.reloadneeded">You're using an outdated copy of the user interface. Please reload the page to update to the newest version.</span>
  <span *ngSwitchDefault i18n="@@general.action.confirmation">This action cannot be undone. Press OK to proceed.</span>
</div>
<div class="modal-footer">
  <button *ngIf="isDismissible" type="button" class="btn btn-outline-secondary uf-actiondialog-cancel"
          (click)="activeModal.dismiss()" i18n="@@general.cancel">Cancel
  </button>
  <button type="button" class="btn btn-danger uf-actiondialog-confirm" (click)="activeModal.close()" [ngSwitch]="useCase">
    <span *ngSwitchCase="'DELETE'" i18n="@@general.delete">Delete</span>
    <span *ngSwitchCase="'DISCONNECT'" i18n="@@company.gdstarget.disconnect">Disconnect</span>
    <ng-container *ngSwitchCase="'SHOW_CVV_DATATRANS'">
      <ng-container *ngTemplateOutlet="showCvv"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'SHOW_CVV_MIDOCO'">
      <ng-container *ngTemplateOutlet="showCvv"></ng-container>
    </ng-container>
    <ng-template #showCvv>
      <span i18n="@@general.showcvc.continue">Remove CVC/CVV &amp; Continue</span>
    </ng-template>
    <span *ngSwitchCase="'RELOAD'" i18n="@@general.reload">Reload</span>
    <span *ngSwitchDefault i18n="@@general.ok">OK</span>
  </button>
</div>
