import {createReducer, on} from "@ngrx/store";
import {ApplicationError, ApplicationErrorLevel} from "@services/global-error-handler.service";
import {setError, setPrincipal, setUserInterfaceStyle, setValidationError} from "./actions";
import {initialFacesState} from "./faces-state";

const generalValidationError = new ApplicationError(ApplicationErrorLevel.Error, $localize`:@@error.general_validation_violation:Bitte überprüfen Sie Ihre Eingaben. Bei den markierten Feldern fehlen entweder Angaben oder diese sind falsch formatiert.`, '');
const dobAdultValidationError = new ApplicationError(ApplicationErrorLevel.Error, $localize`:@@error.dobadult_validation_violation:DOB (date of birth) entered is under 18 years of age - Please enter details with an Adult DOB.`, '');

export const rootStateReducer = createReducer(
  initialFacesState,
  on(setPrincipal, (state, action) => ({...state, principal: action.principal})),
  on(setUserInterfaceStyle, (state, action) => ({...state, userInterfaceStyle: action.style})),
  on(setError, (state, action) => ({...state, lastError: action.error})),
  on(setValidationError, (state, action) => {
    if (action.errors && action.errors['ctAdult']) {
      return {...state, lastError: dobAdultValidationError};
    }
    return {...state, lastError: generalValidationError};
  })
);
