import {Injectable} from '@angular/core';

import {LogService} from './log-service.interface';

@Injectable()
export class LogServiceDevelopment extends LogService {
  private dateFormatOptions: Intl.DateTimeFormatOptions = {hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit'} as const;

  constructor() {
    super();
  }

  debug(message: string, source?: string): void {
    console.debug(this.toMessage(message, source));
  }

  info(message: string, source?: string): void {
    console.log(this.toMessage(message, source));
  }

  warn(message: string, source?: string): void {
    console.warn(this.toMessage(message, source));
  }

  private toMessage(message: string, source?: string): string {
    return new Date().toLocaleTimeString('en-US', this.dateFormatOptions) + ' ' + (source ? '(' + source + ') ' : '') + message;
  }
}
