import { Component } from '@angular/core';
import { BaseGenericValueWithSetupComponent } from '../base-generic-value-with-setup-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrzLdapDialogComponent } from './brz-ldap-dialog/brz-ldap-dialog.component';

@Component({
  template: `
  <div [formGroup]="valueWithSetupFormGroup">
    <button class="btn btn-xs btn-secondary" (click)="openLdapDialog()">LDAP Konfiguration</button>
    <input type="hidden" class="form-control" [attr.id]="controlId" formControlName="value">
  </div>
`
})
export class BrzLdapButtonComponent extends BaseGenericValueWithSetupComponent {

  constructor(private modalService: NgbModal) {
    super();
  }

  openLdapDialog(): void {
    const fieldValue = this.valueWithSetupFormGroup.get('value')?.value;
    const modalRef = this.modalService.open(BrzLdapDialogComponent, { size: 'xl' });
    modalRef.componentInstance.initialItems = fieldValue ? JSON.parse(fieldValue) : [];
    modalRef.closed.subscribe((ldapJson) => this.updateInteralValue(ldapJson));
    modalRef.dismissed.subscribe((ldapJson) => this.updateInteralValue(ldapJson));
  }

  private updateInteralValue(ldapJson: any) {
    const stringValue = ldapJson ? JSON.stringify(ldapJson, ['dn', 'includeSubsidiaries', 'excludedSubsidiaries']) : '[]';
    const formValue = this.valueWithSetupFormGroup.get('value');
    formValue?.setValue(stringValue);
    formValue?.markAsDirty();
    this.valueWithSetupFormGroup.get('field')?.markAsDirty();
  }

}
