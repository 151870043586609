import { Country } from './models/country';

export class ObjectUtils {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static deepClone<T>(source: T): T {
    if (source === null) {
      return source;
    }
    if (source instanceof Date) {
      return new Date(source.getTime()) as any;
    }
    if (source instanceof Array) {
      return source.map((item: any) => ObjectUtils.deepClone<any>(item)) as any;
    }
    if (typeof source === 'object' && Object.keys(source).length > 0) {
      const clonedObj = {...(source as { [key: string]: any })} as { [key: string]: any }
      Object.keys(clonedObj).forEach(prop => {
        clonedObj[prop] = ObjectUtils.deepClone<any>(clonedObj[prop])
      })

      return clonedObj as T
    }
    // all other types are assumed immutable (eg. string)
    return source;
  }

  static removeAllKeys(obj: any): void {
    Object.keys(obj)
      .filter(key => obj.hasOwnProperty(key))
      .forEach(key => delete obj[key]);
  }

  static clearAll(anArray: any[]): void {
    anArray.splice(0, anArray.length);
  }

  static formatAsUtc(dt: Date | undefined): string {
    return dt ? dt.toUTCString() : '';
  }

  static equalsAny<T>(value: T, ...other: T[]): boolean {
    return other === value || other.includes(value);
  }

  /**
   * This method will do an in-place replacement of all Date objects with strings in ISO-8601 format (UTC).
   */
  static replaceDatesWithStrings(source: any): void {
    if (!source || !(source instanceof Object)) {
      return;
    }

    if (source instanceof Array) {
      for (const item of source) {
        ObjectUtils.replaceDatesWithStrings(item);
      }
    } else if (source instanceof Object) {

      for (const key of Object.keys(source)) {
        const value = source[key];

        if (value instanceof Array) {
          for (const item of value) {
            ObjectUtils.replaceDatesWithStrings(item);
          }
        } else if (value instanceof Date) {
          source[key] = ObjectUtils.formatEuropeanDate(<Date>value);
        } else if (value instanceof Object) {
          ObjectUtils.replaceDatesWithStrings(value);
        }
      }
    }
  }

  /**
   * This method will do an in-place replacement of all 'date-looking' strings with actual Dates.
   */
  static replaceStringsWithDates(source: any): void {
    if (!source || !(source instanceof Object)) {
      return;
    }

    if (source instanceof Array) {
      for (const item of source) {
        ObjectUtils.replaceStringsWithDates(item);
      }
    } else {
      for (const key of Object.keys(source)) {
        const value = source[key];
        if (value instanceof Array) {
          for (const item of value) {
            ObjectUtils.replaceStringsWithDates(item);
          }
        }
        if (value instanceof Object) {
          ObjectUtils.replaceStringsWithDates(value);
        }
        if (typeof value === 'string') {
          const dateValue = ObjectUtils.toDate(value);
          if (dateValue) {
            source[key] = dateValue;
          }
        }
      }
    }
  }

  /**
   * Get a localized country name.
   * @param country
   * @param locale Should be the ng LOCALE_ID, e.g. en-US (note the difference to a Java locale of en_US)
   * @returns
   */
  static getCountryName(country: Country, principalLocale: string): string {
    return (country.names || [])
      .filter(n => ObjectUtils.isSameLocale(n.id.locale, principalLocale))
      .map(n => n.name)[0] || country.name;
  }

  static getTranslatedGender(s?: string): string {
    switch (s) {
      case 'M':
      case 'MR':
        return $localize`:@@general.code.mr:Mr.`;
      case 'F':
      case 'MRS':
        return $localize`:@@general.code.mrs:Mrs.`;
      case 'MS':
        return $localize`:@@general.code.ms:Ms.`;
    }
    return '';
  }

  /**
   * Use this method to compare Angular locales (en-US) with Java locales (en_US).
   * @param localeA an Angular or a Java locale
   * @param localeB an Angular or a Java locale
   */
  static isSameLocale(localeA: string, localeB: string): boolean {
    if (localeA && localeB) {
      return ObjectUtils.toJavaLocaleCode(localeA).toLowerCase() === ObjectUtils.toJavaLocaleCode(localeB).toLowerCase();
    }
    return false;
  }

  /**
   * Angular 'LOCALE_ID' is in the format en-US, whereas Java locales use underscore (en_US)
   */
  static toJavaLocaleCode(angularLocaleId: string): string {
    if (angularLocaleId) {
      return angularLocaleId.replace('-', '_');
    }
    return '';
  }

  static toBlocks(s?: string, blockSize = 4, joinChar = ' '): string {
    if (!s) {
      return '';
    }
    const blocks = new RegExp(`(.{${blockSize}})`, 'g');
    return s.replace(/\s/g, '')
      .replace(blocks, `$1${joinChar}`)
      .replace(new RegExp(`${joinChar}$`), '');
  }

  private static dateTimeRegex = /^(\d{2})\.(\d{2})\.(\d{4})T(\d{2}:\d{2}:\d{2}(\.\d{1,6})?([\+-][\d:]{5}|Z)?)$/;
  private static dateRegex = /^(\d{2})\.(\d{2})\.(\d{2,4})$/;

  private static toDate(value: string): Date | undefined {
    const matchDateTime = value.match(ObjectUtils.dateTimeRegex);
    if (matchDateTime) {
      return new Date(`${matchDateTime[3]}-${matchDateTime[2]}-${matchDateTime[1]}T${matchDateTime[4]}`);
    }
    const matchDate = value.match(ObjectUtils.dateRegex);
    if (matchDate) {
      // Explicitly parse year, month (note, JS months start at 0), day, without adjusting for browser time zone
      return new Date(parseInt(matchDate[3]), parseInt(matchDate[2]) - 1, parseInt(matchDate[1]));
    }
    return undefined;
  }

  // Format to dd.MM.yyyy
  private static formatEuropeanDate(dt: Date): string {
    return ObjectUtils.formatWithLeadingZero(dt.getDate()) + '.' +
      ObjectUtils.formatWithLeadingZero(dt.getMonth() + 1) + '.' +
      dt.getFullYear();
  }

  private static formatWithLeadingZero(nr: number): string {
    return nr < 10 ? '0' + nr : nr.toString(10);
  }
}
