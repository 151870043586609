import { BaseCircleCustomization } from '../base-circle-customization.class';
import { FormGroup } from '@angular/forms';
import { AffectedUserGroupConstant, FieldDisplayTypeConstant, ProfileTypeConstant, ProfileTypeEnum } from '@models/types.enum';
import { StandardfieldDefinition } from '@models/standardfield-definition';
import { TravellerDetailResponse } from '@models/traveller-detail-response';

export class GlobalstarCustomization extends BaseCircleCustomization {

  private petrofac = false;

  override customizeTravellerFormGroup(traveller: TravellerDetailResponse | undefined, _formGroup: FormGroup) {
    this.petrofac = traveller?.data?.company?.name?.toLowerCase().startsWith('petrofac') ?? false;
  }

  override getTransientStandardfieldDefinition(controlPath: string[], profileType: ProfileTypeEnum): StandardfieldDefinition | undefined {
    const controlName = controlPath[controlPath.length - 1];
    if (this.petrofac && controlName === 'email' && profileType === ProfileTypeConstant.TRAVELLER) {
      return {
        collectionName: '',
        fieldName: controlName,
        profileType: ProfileTypeConstant.TRAVELLER,
        affectedUserGroup: AffectedUserGroupConstant.TRAVELLER,
        fieldDisplayType: FieldDisplayTypeConstant.READONLY,
        label: ''
      };
    }

    return;
  }
}
