export class AngularUtils {

  static runOnNextTick(handler: () => void): void {
    setTimeout(handler, 0);
  }

  static replaceResourceLocatorInUrl(uuid?: string): void {
    if (window.location.pathname.endsWith('/new') && uuid) {
      const newState = window.location.href.replace('/new', `/${uuid}`);
      window.history.replaceState({}, '', newState);
    }
  }
}
